// react
import React, { useState, useContext, useEffect } from "react";
import { StyleProp, View, Pressable, ViewStyle } from "react-native";

// style
import styled, { useTheme } from "../styling/styled-components";

// components
import Text from "../components/Text";
import Dot from "../components/Dot";

// api
import { RentalSearchDocument, RentalPhase, RentalStatus } from "../API";
import { useListRentalStepsForRental } from "../graphql/hooks/useListRentalStepsForRental";

// context
import CampusContext from "../contexts/CampusContext";

// utils
import getPhaseTriggerDate from "../utils/rentals/getPhaseTriggerDate";
import { prettifyYearDate } from "../utils/helpers";
import { formatInTimeZone } from "date-fns-tz";

// images
import { CheckCircle, Chevron, RedDollar } from "../assets/vectors";
import { parseTimestamp } from "../utils/rentals/timestamp";

const STRINGS = {
    REFUNDED_BADGE: "Refunded",
    CANCELED_BADGE: "Canceled",
    DUE: (at: Date, campusTimezone: string) =>
        `Due: ${prettifyYearDate(at, campusTimezone)}`,
    FAILED: "FAILED",
    FORGIVEN: "FORGIVEN",
    FAILED_MESSAGE: (at: Date, campusTimezone: string) =>
        `Failed: ${prettifyYearDate(at, campusTimezone)}`,
    FAILED_MESSAGE_V2:
        "This failed rental is eligible for a fine. Check your campus account for final charges.",
    FORGIVEN_MESSAGE: (at: Date, campusTimezone: string) =>
        `Forgiven: ${prettifyYearDate(at, campusTimezone)}`,
    FORGIVEN_MESSAGE_V2: (at: Date, campusTimezone: string) =>
        `Forgiven: ${
            at ? prettifyYearDate(at, campusTimezone) : null
        } - This rental will not be fined`,
    FORGIVEN_MESSAGE_V3: (at: Date, campusTimezone: string) =>
        `Forgiven: ${
            at ? prettifyYearDate(at, campusTimezone) : null
        } - This rental will be refunded. Check your campus account for refund status.`,
    LATE: (at: Date, campusTimezone: string) =>
        `Late: ${prettifyYearDate(at, campusTimezone)}`,
    REFUNDED_AT: (at: Date, campusTimezone: string) =>
        `Refunded: ${prettifyYearDate(at, campusTimezone)}`,
    CANCELED_AT: (at: Date, campusTimezone: string) =>
        `Canceled: ${prettifyYearDate(at, campusTimezone)}`,
    WAS_DUE: (at: Date, campusTimezone: string) =>
        `Was Due: ${prettifyYearDate(at, campusTimezone)}`,
    CHECKED_OUT: (rental: RentalSearchDocument, campusTimezone: string) =>
        `Checked-out: ${
            rental?.createdAt
                ? prettifyYearDate(new Date(rental.createdAt), campusTimezone)
                : ""
        }`,
    RETURN_RECEIVED: (resolvingLoppClosedAt: Date, campusTimezone: string) =>
        `A return was received on ${
            resolvingLoppClosedAt
                ? formatInTimeZone(
                      resolvingLoppClosedAt,
                      campusTimezone,
                      "MMMM d"
                  )
                : ""
        } - thank you!`,
};

const Container = styled(Pressable)<{ isExpanded: boolean }>`
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
    padding-horizontal: ${({ theme }) => theme.spacing.small}px;
    border-top-width: 0.5px;
    border-bottom-width: 0.5px;
    border-top-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    border-bottom-color: ${({ theme, isExpanded }) =>
        isExpanded ? theme.colors.WAVE_STORM : theme.colors.MARINE_LAYER};
    background-color: ${({ theme, isExpanded }) =>
        isExpanded && theme.colors.SMOG};
`;

const HeaderWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderLeft = styled(View)`
    display: flex;
    gap: ${({ theme }) => theme.spacing.tiny}px;
`;

const TitleContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`;

const HeaderText = styled(Text)<{ isExpanded: boolean }>`
    color: ${({ theme, isExpanded }) =>
        isExpanded ? theme.colors.WAVE_STORM : theme.colors.DEEP_BLUE_SEA};
    padding-right: 3px;
`;

const HeaderDateText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const ChevronWrapper = styled(View)<{ isExpanded: boolean }>`
    transform: rotate(${({ isExpanded }) => (isExpanded ? 0 : -180)}deg;    
`;

const ExpandedWrapper = styled(View)``;

const DotWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
`;

const DotText = styled(Text)`
    padding-left: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme }) => theme.colors.ASH};
`;

const ReturnReceivedWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
`;

const CheckCircleWrapper = styled(View)`
    display: inline-block;
    top: 4px;
    margin-right: ${({ theme }) => theme.spacing.tiny}px;
`;

const ReturnReceivedText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const FailedStatusWrapper = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xsmall}px;
`;

const FailedStatusMessageWrapper = styled(FailedStatusWrapper)`
    margin: ${({ theme }) => theme.spacing.small}px 0px;
`;

const FailedStatus = styled(Text)`
    color: ${({ theme }) => theme.colors.RED_2};
`;

const ForgivenStatus = styled(Text)`
    color: ${({ theme }) => theme.colors.GREEN_2};
`;

const RedDollarIcon = styled.View`
    min-width: 16px;
    height: 100%;
`;

const GreenDotIcon = styled.View`
    min-width: 6px;
    height: 100%;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

type DotRowProps = {
    color: string;
    copy: string;
};

const DotRow = ({ color, copy }: DotRowProps): JSX.Element => {
    return (
        <DotWrapper>
            <GreenDotIcon>
                <Dot width={6} fill={color} />
            </GreenDotIcon>
            <DotText type="b2">{copy}</DotText>
        </DotWrapper>
    );
};

const renderRentalStatus = (rental: RentalSearchDocument): JSX.Element => {
    const { status, charge } = rental;

    if (
        (status === RentalStatus.Expired || status === RentalStatus.Failed) &&
        charge
    ) {
        return (
            <FailedStatusWrapper>
                <FailedStatus type="eyebrow">{STRINGS.FAILED}</FailedStatus>
                <RedDollarIcon>
                    <RedDollar />
                </RedDollarIcon>
            </FailedStatusWrapper>
        );
    }

    if (
        (status === RentalStatus.Expired || status === RentalStatus.Failed) &&
        !charge
    ) {
        return <FailedStatus type="eyebrow">{STRINGS.FAILED}</FailedStatus>;
    }

    // Default
    return <ForgivenStatus type="eyebrow">{STRINGS.FORGIVEN}</ForgivenStatus>;
};

const renderStatusMessageCopy = (
    rental: RentalSearchDocument,
    forgivenAt: Date | null,
    localTimezone: string
): JSX.Element | null => {
    const { status, charge } = rental;
    const { colors } = useTheme();
    const canceledAt =
        rental.status == RentalStatus.Canceled && rental.updatedAt;
    const refundedAt =
        rental.status == RentalStatus.CanceledWithRefund && rental.updatedAt;

    if (
        (status === RentalStatus.Expired || status === RentalStatus.Failed) &&
        charge
    ) {
        return (
            <FailedStatusMessageWrapper>
                <RedDollarIcon>
                    <RedDollar />
                </RedDollarIcon>
                <Text>{STRINGS.FAILED_MESSAGE_V2}</Text>
            </FailedStatusMessageWrapper>
        );
    }

    if (
        (status === RentalStatus.Expired || status === RentalStatus.Failed) &&
        !charge
    ) {
        return null;
    }

    if (
        (status === RentalStatus.Forgiven ||
            status === RentalStatus.Canceled ||
            status === RentalStatus.CanceledWithRefund) &&
        !charge
    ) {
        let displayedDate = forgivenAt;
        if (status === RentalStatus.Canceled && canceledAt) {
            displayedDate = new Date(canceledAt);
        }
        if (status === RentalStatus.CanceledWithRefund && refundedAt) {
            displayedDate = new Date(refundedAt);
        }
        return displayedDate ? (
            <DotRow
                color={colors.GREEN_2}
                copy={STRINGS.FORGIVEN_MESSAGE(displayedDate, localTimezone)}
            />
        ) : null;
    }

    if (status === RentalStatus.Forgiven && charge && forgivenAt) {
        return (
            <DotRow
                color={colors.GREEN_2}
                copy={STRINGS.FORGIVEN_MESSAGE_V2(forgivenAt, localTimezone)}
            />
        );
    }

    if (
        (status === RentalStatus.Canceled ||
            status === RentalStatus.CanceledWithRefund) &&
        charge
    ) {
        let displayedDate = forgivenAt || null;
        if (status === RentalStatus.Canceled && canceledAt) {
            displayedDate = new Date(canceledAt);
        }
        if (status === RentalStatus.CanceledWithRefund && refundedAt) {
            displayedDate = new Date(refundedAt);
        }
        return displayedDate ? (
            <DotRow
                color={colors.GREEN_2}
                copy={STRINGS.FORGIVEN_MESSAGE_V3(displayedDate, localTimezone)}
            />
        ) : null;
    }

    // Default
    return null;
};

type Props = {
    rental: RentalSearchDocument;
    initExpanded?: boolean;
    style?: StyleProp<ViewStyle>;
};

export default function FinedFailedRentalAccordian({
    style,
    rental,
    initExpanded,
}: Props) {
    const [isExpanded, setExpanded] = useState(initExpanded);
    const { colors } = useTheme();
    const { localTimezone } = useContext(CampusContext).campusConfig;

    const [listRentalStepsForRental, rentalSteps, rentalStepsInitiated] =
        useListRentalStepsForRental();

    useEffect(() => {
        listRentalStepsForRental(rental?.rentalId);
    }, []);

    const dueAt =
        rentalStepsInitiated &&
        rentalSteps !== null &&
        getPhaseTriggerDate(rentalSteps, RentalPhase.LatePending);
    const lateAt =
        rentalStepsInitiated &&
        rentalSteps !== null &&
        getPhaseTriggerDate(rentalSteps, RentalPhase.Late);
    const closedAt =
        rentalStepsInitiated && rentalSteps !== null
            ? getPhaseTriggerDate(rentalSteps, RentalPhase.FinePending) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.FailedPending) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.Expired) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.Fined) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.FinedAutomatic) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.FineError) ||
              getPhaseTriggerDate(rentalSteps, RentalPhase.FinedManual)
            : null;
    const forgivenAt =
        ((RentalStatus.Forgiven ||
            RentalStatus.Canceled ||
            RentalStatus.CanceledWithRefund) &&
            rental.forgivenessDetails &&
            parseTimestamp(rental.forgivenessDetails.timestamp)) ||
        null;

    return (
        <Container
            style={style}
            onPress={() => setExpanded(!isExpanded)}
            isExpanded={isExpanded}
        >
            <HeaderWrapper>
                <HeaderLeft>
                    <TitleContainer>
                        {rental.resolvingLoop &&
                        rental.resolvingLoop.closedAt ? (
                            <CheckCircleWrapper
                                accessible={true}
                                accessibilityLabel="Return received"
                            >
                                <CheckCircle />
                            </CheckCircleWrapper>
                        ) : null}
                        <HeaderText type="sh1" isExpanded={isExpanded}>
                            {rental?.rentedAsset?.assetType?.name}
                        </HeaderText>
                        <ChevronWrapper isExpanded={isExpanded}>
                            <Chevron />
                        </ChevronWrapper>
                    </TitleContainer>
                    {!isExpanded && dueAt && (
                        <HeaderDateText type="b2">
                            {STRINGS.CHECKED_OUT(rental, localTimezone)}
                        </HeaderDateText>
                    )}
                </HeaderLeft>
                <HeaderDateText type="b2">
                    {renderRentalStatus(rental)}
                </HeaderDateText>
            </HeaderWrapper>
            {isExpanded && (
                <ExpandedWrapper>
                    {rental && rental.createdAt && (
                        <DotRow
                            color={colors.WAVE_STORM}
                            copy={STRINGS.CHECKED_OUT(rental, localTimezone)}
                        />
                    )}
                    {dueAt && (
                        <DotRow
                            color={colors.ROCK_BOTTOM}
                            copy={STRINGS.DUE(dueAt, localTimezone)}
                        />
                    )}
                    {lateAt && (
                        <DotRow
                            color={colors.YELLOW_3}
                            copy={STRINGS.LATE(lateAt, localTimezone)}
                        />
                    )}
                    {closedAt && (
                        <DotRow
                            color={colors.RED_2}
                            copy={STRINGS.FAILED_MESSAGE(
                                closedAt,
                                localTimezone
                            )}
                        />
                    )}
                    {renderStatusMessageCopy(rental, forgivenAt, localTimezone)}
                    {rental.resolvingLoop && rental.resolvingLoop.closedAt ? (
                        <ReturnReceivedWrapper>
                            <CheckCircleWrapper
                                accessible={true}
                                accessibilityLabel="Return received"
                            >
                                <CheckCircle />
                            </CheckCircleWrapper>
                            <ReturnReceivedText type="b2">
                                {STRINGS.RETURN_RECEIVED(
                                    rental.resolvingLoop.closedAt,
                                    localTimezone
                                )}
                            </ReturnReceivedText>
                        </ReturnReceivedWrapper>
                    ) : null}
                </ExpandedWrapper>
            )}
        </Container>
    );
}

FinedFailedRentalAccordian.defaultProps = {
    initExpanded: false,
};
