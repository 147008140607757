// external imports
import React, {
    useCallback,
    useEffect,
    useState,
    useContext,
    forwardRef,
    useRef,
} from "react";
import { Image, Pressable, TextInput, View } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import PhoneInput, {
    isPossiblePhoneNumber,
    parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

// styling
import styled, { css } from "../styling/styled-components";
import { ExternalLink, ReusePassLogoFull } from "../assets/vectors";

// contexts
import CampusContext from "../contexts/CampusContext";
import GlobalContext from "../contexts/GlobalContext";

// components
import Button from "./Button";
import Card from "./Card";
import Text from "./Text";

// utils
import { graphErrorDetails, prettifyPhoneNumber, openURL } from "../utils/helpers";

const STRINGS = {
    CONTACT_US: "Contact us if you're still having issues",
    CREATE_TITLE:
        "Follow the steps below to create your free ReusePass account.",
    CTA: "Continue",
    ERROR: "Something went wrong, try again later",
    EXTERNAL_TITLE: "Welcome to ReusePass! Sign in or create a free account to complete your Grubhhub order.",
    EXTERNAL_BODY: "ReusePass is a borrow and return program for reusable containers. Follow the prompts to create and/or link your ReusePass account with Grubhub.",
    GRUBHUB_TITLE: "Order from a ReusePass location on Grubhub.",
    GRUBHUB_COMBO_BLURB_1: "Accept the program terms when prompted.",
    GRUBHUB_COMBO_HEADER_1: `Tap on the "ReusePass Opt-in" shop or any location offering reusable containers on Grubhub.`,
    GRUBHUB_COMBO_BLURB_2: `If you select the “ReusePass Opt-in” shop, submit an order for the $0.00 “Opt-in” menu item to create your ReusePass account.`,
    GRUBHUB_COMBO_HEADER_2: "Submit an order at that shop to join the program.",
    GRUBHUB_NO_COMBO_BLURB:
        "Accept the program terms when prompted and complete the order to finish opting in.",
    GRUBHUB_NO_COMBO_HEADER:
        "Select any campus location offering reusable containers on Grubhub.",
    INVALID_PHONE_NUMBER:
        "Sorry, that number is not valid. Please enter a US or Canadian number and try again.",
    JOIN_GRUBHUB: "Join on Grubhub",
    LEARN_MORE: "Learn more",
    NOT_FOUND: (phoneNumber: string) =>
        `We didn't find ${prettifyPhoneNumber(phoneNumber)} in our records.`,
    PHONE: "PHONE NUMBER",
    REUSEPASS_TITLE: "Sign-in to access your ReusePass QR.",
    REUSEPASS_BLURB:
        "Once you’ve opted-in and completed an order, use the phone number attached to your Grubhub account to sign in to ReusePass, access your ReusePass QR, and track your container rentals.",
    // TODO: make dynamic for other integration providers
    SIGN_IN: "Sign-in to ReusePass",
    STEP_1: "STEP 1",
    STEP_2: "STEP 2",
    TITLE: "What's your phone number?",
    TRY_AGAIN: "Try again",
    UNSUPPORTED_COUNTRY_ERROR:
        "Sorry, we don't support phone numbers from that country yet. Please enter a valid US or Canadian number to continue.",
    VALIDATION_ERROR: "Please enter a valid 10-digit phone number",
};

const VALID_PHONE_COUNTRIES = ["US", "CA", "PR"];

const Container = styled(View)`
    align-items: ${({ theme }) => (theme.isDesktop ? "center" : "flex-start")};
`;

const Prompt = styled(View)``;

const tryAgainLinkWrapper = styled(Pressable)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const CardButton = styled(Button)`
    width: ${({ theme }) => (theme.isMobile ? "100%" : "294px")};
    margin-top: ${({ theme }) => theme.spacing.large}px;
`;

const GrubhubButtonText = styled(Text)`
    color: ${({ theme }) => theme.colors.WHITE};
    text-align: center;
    margin-left: ${({ theme: { spacing } }) => spacing.small}px;
`;

const NextButton = styled(Button)`
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    width: ${({ theme }) => (theme.isMobile ? "100%" : "294px")};
    margin: ${({ theme: { isMobile, spacing } }) =>
            isMobile ? spacing.xlarge : spacing.yuge}px
        auto 0;
`;

const SignUpButton = styled(Button)`
    align-self: flex-start;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            margin: 18px auto 0px;
        `}
`;

const PhoneContainer = styled(View)`
    max-width: 315px;
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            margin: 0 auto;
        `}
    text-align: left;
`;

const PhoneInputWrapper = styled(View)`
    margin-top: ${({ theme }) => theme.spacing.huge}px;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.HURRICANE};
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            margin-top: ${({ theme }) => theme.spacing.xxlarge}px;
        `}
`;

const PhoneHintText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme, error }) =>
        error ? theme.colors.RED_2 : theme.colors.HURRICANE};
`;

const StyledPhoneInput = styled(TextInput)`
    outline-width: 0px;
    font-family: latoBold;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ErrorsWrapper = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
`;

const TryAgainLinkWrapper = styled(Pressable)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const StepCardContainer = styled(View)`
    ${({ theme: { isMobile } }) =>
        !isMobile &&
        css`
            flex-direction: row;
            justify-content: space-around;
        `}
`;

const StepCard = styled(Card)`
    flex-wrap: wrap;
    height: fit-content;
    padding-bottom: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-left: ${({ theme }) => theme.spacing.medium}px;
    padding-right: ${({ theme }) => theme.spacing.medium}px;
    ${({ theme: { isMobile } }) =>
        !isMobile &&
        css`
            width: 49%;
        `}
`;

const StepNameContainer = styled(View)`
    justify-content: flex-start;
    text-align: start;
    width: 100%;
`;

const StepTitle = styled(Text)`
    text-align: left;
    max-width: ${({ theme }) =>
        theme.isMobile ? "80%" : theme.maxBodyWidth + "px"};
`;

const StepTitleContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
    padding-top: ${({ theme }) => theme.spacing.tiny}px;
    width: 100%;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    max-width: ${({ theme }) => theme.maxBodyWidth}px;
`;

const RockText = styled(Text)`
    color: ${({ theme }) => theme.colors.ROCK_BOTTOM};
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const ExternalBodyWrapper = styled(View)`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const LearnMoreWrapper = styled(View)`
    margin-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const RedText = styled(Text)`
    max-width: 420px;
    color: ${({ theme }) => theme.colors.RED_2};
`;

const SummerStormText = styled(Text)`
    color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const CardText = styled(BlueText)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    text-align: start;
    width: 90%;
    max-width: ${({ theme }) =>
        theme.isMobile ? "80%" : theme.maxBodyWidth + "px"};
`;

const HorizontalLine = styled(View)`
    margin-bottom: ${({ theme }) => theme.spacing.xsmall}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    border-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    border-style: solid;
    border-top-width: 1px;
    width: 100%;
`;

type Props = {
    displayName?: string;
    startSignIn: (phoneNumber: string) => void;
    initPhoneNumber: string;
    startSignUp: (phoneNumber: string) => void;
};

export default function SignInStepOne({
    startSignIn,
    initPhoneNumber,
    startSignUp,
    style,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(initPhoneNumber || "");

    // Since the state starts without an error we set this to true by default
    const [hasNumberChangedSinceError, setHasNumberChangedSinceError] =
        useState(true);
    const [userDNE, setUserDNE] = useState(false);
    const [keyboardFocused, setKeyboardFocused] = useState(false);

    const campusContext = useContext(CampusContext);
    const { canSignUp, comboCampus, signUpLink, contactPhoneNumber } =
        campusContext.campusConfig;
    const globalContext = useContext(GlobalContext);
    const { thirdPartyExternalId } =
        globalContext.globalSelections;

    const navigation = useNavigation();
    const route = useRoute();
    const ref = useRef();

    // helper functions
    const applyPhoneErrorMessage = (errorMsg) => {
        setPhoneNumberError(errorMsg);
        setHasNumberChangedSinceError(false);
    };

    const resetState = () => {
        setLoading(false);
        setPhoneNumberError("");
        setHasNumberChangedSinceError(true);
        setUserDNE(false);
    };

    const isMounted = useRef(false); // Step 1: Create the ref

    useEffect(() => {
        isMounted.current = true; // Component is mounted

        return () => {
            isMounted.current = false; // Component will unmount
        };
    }, []);

    const submit = useCallback(async () => {
        setLoading(true);
        setPhoneNumberError("");

        try {
            const countryCode = parsePhoneNumber(phoneNumber).country;
            if (
                countryCode &&
                VALID_PHONE_COUNTRIES.indexOf(countryCode) == -1
            ) {
                applyPhoneErrorMessage(STRINGS.UNSUPPORTED_COUNTRY_ERROR);
            } else {
                await startSignIn(phoneNumber);
            }
        } catch (err) {
            if (err.name == "UserNotFoundException") {
                if (canSignUp) {
                    try {
                        await startSignUp(phoneNumber);
                    } catch (err) {
                        const [errorType, errorMessage] = graphErrorDetails(
                            err?.errors,
                            "InvalidParams"
                        );
                        if (errorType == "InvalidParams") {
                            applyPhoneErrorMessage(
                                STRINGS.INVALID_PHONE_NUMBER
                            );
                        } else {
                            console.error("Error initiating sign in", err);
                            applyPhoneErrorMessage(STRINGS.ERROR);
                        }
                    }
                } else {
                    setUserDNE(true);
                }
            } else {
                console.error("Error initiating sign in", err);
                applyPhoneErrorMessage(STRINGS.ERROR);
            }
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    }, [startSignIn, phoneNumber]);

    const updateStateOnPhoneNumberChange = (newNumber) => {
        if (!!phoneNumberError) {
            resetState();
        }
        setPhoneNumber(newNumber);
    };

    // effects
    useEffect(() => {
        /* If there was an error with the sign in or sign up process,
         * the user will be redirected to this first sign in step with
         * an error message. We want to display that error message and
         * then reset the error so we don't hold on to one that has
         * already been addressed.
         */
        if (route.params && route.params.errorMessage) {
            setPhoneNumberError(route.params.errorMessage);
            navigation.setParams({ errorMessage: "" });
        }
    }, []);

    const title = !!thirdPartyExternalId ? STRINGS.EXTERNAL_TITLE : userDNE ? STRINGS.CREATE_TITLE : STRINGS.TITLE;

    return (
        <Container style={style}>
            <Prompt>
                <BlueText type="h1">
                    {title}
                </BlueText>
            </Prompt>
            {!!thirdPartyExternalId && (
                <>
                    <ExternalBodyWrapper>
                        <BlueText type="b1">
                            {STRINGS.EXTERNAL_BODY}
                        </BlueText>
                    </ExternalBodyWrapper>
                    <LearnMoreWrapper>
                        <a href="https://www.reusepass.com/faqs">
                            <Text type="link">{STRINGS.LEARN_MORE}</Text>
                        </a>
                    </LearnMoreWrapper>
                </>
            )}
            {!userDNE && (
                <PhoneContainer>
                    <PhoneInputWrapper>
                        <PhoneInput
                            defaultCountry="US"
                            inputComponent={forwardRef((props, ref) => (
                                <StyledPhoneInput
                                    keyboardType="phone-pad"
                                    autoComplete="tel"
                                    value={props.value}
                                    autoFocus={true}
                                    onChangeText={props.onChangeText}
                                    textContentType="telephoneNumber"
                                    ref={ref}
                                    placeholder="(555)-000-0000"
                                    {...props}
                                />
                            ))}
                            value={phoneNumber}
                            addInternationalOption={false}
                            countries={VALID_PHONE_COUNTRIES}
                            onChange={(number) =>
                                updateStateOnPhoneNumberChange(number)
                            }
                        />
                    </PhoneInputWrapper>
                    <PhoneHintText type="eyebrow" error={!!phoneNumberError}>
                        {STRINGS.PHONE}
                    </PhoneHintText>
                </PhoneContainer>
            )}
            {!userDNE && !!phoneNumberError && (
                <ErrorsWrapper>
                    <RedText type="h3">{phoneNumberError}</RedText>
                </ErrorsWrapper>
            )}
            {!userDNE &&
                isPossiblePhoneNumber(phoneNumber || "") &&
                hasNumberChangedSinceError && (
                    <NextButton
                        label={STRINGS.CTA}
                        type="primary"
                        size="large"
                        onPress={submit}
                        isLoading={loading}
                    />
                )}
            {userDNE && (
                <>
                    <RockText type="b1">
                        {STRINGS.NOT_FOUND(phoneNumber)}
                    </RockText>
                    <TryAgainLinkWrapper onPress={() => resetState()}>
                        <Text type="link">{STRINGS.TRY_AGAIN}</Text>
                    </TryAgainLinkWrapper>
                    <StepCardContainer>
                        <StepCard>
                            <StepNameContainer>
                                <SummerStormText type="eyebrow">
                                    {STRINGS.STEP_1}
                                </SummerStormText>
                            </StepNameContainer>
                            <StepTitleContainer>
                                <StepTitle type="h2">
                                    {STRINGS.GRUBHUB_TITLE}
                                </StepTitle>
                                <Image
                                    style={{
                                        height: "70px",
                                        width: "40px",
                                    }}
                                    source={{
                                        uri: require("../assets/images/grubhub.png"),
                                    }}
                                />
                            </StepTitleContainer>
                            <GrubhubBlurb comboCampus={comboCampus} />
                            <CardButton
                                type="primary"
                                size="large"
                                onPress={() => {
                                    openURL(signUpLink);
                                }}
                                isLoading={false}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <ExternalLink />
                                    <GrubhubButtonText type="sh1">
                                        {STRINGS.JOIN_GRUBHUB}
                                    </GrubhubButtonText>
                                </div>
                            </CardButton>
                        </StepCard>
                        <StepCard>
                            <StepNameContainer>
                                <SummerStormText type="eyebrow">
                                    {STRINGS.STEP_2}
                                </SummerStormText>
                            </StepNameContainer>
                            <StepTitleContainer>
                                <StepTitle type="h2">
                                    {STRINGS.REUSEPASS_TITLE}
                                </StepTitle>
                                <View>
                                    <ReusePassLogoFull />
                                </View>
                            </StepTitleContainer>
                            <CardText type="b2">
                                {STRINGS.REUSEPASS_BLURB}
                            </CardText>
                            <CardButton
                                label={STRINGS.SIGN_IN}
                                type="secondary"
                                size="large"
                                onPress={resetState}
                                isLoading={false}
                            />
                        </StepCard>
                    </StepCardContainer>
                </>
            )}
        </Container>
    );
}

type BlurbProps = {
    comboCampus: boolean;
};

function GrubhubBlurb({ comboCampus }: BlurbProps) {
    return (
        <>
            {!comboCampus && (
                <>
                    <CardText type="h3">
                        {STRINGS.GRUBHUB_NO_COMBO_HEADER}
                    </CardText>
                    <CardText type="b2">
                        {STRINGS.GRUBHUB_NO_COMBO_BLURB}
                    </CardText>
                </>
            )}
            {comboCampus && (
                <>
                    <CardText type="h3">
                        {STRINGS.GRUBHUB_COMBO_HEADER_1}
                    </CardText>
                    <CardText type="b2">
                        {STRINGS.GRUBHUB_COMBO_BLURB_1}
                    </CardText>
                    <HorizontalLine />
                    <CardText type="h3">
                        {STRINGS.GRUBHUB_COMBO_HEADER_2}
                    </CardText>
                    <CardText type="b2">
                        {STRINGS.GRUBHUB_COMBO_BLURB_2}
                    </CardText>
                    <HorizontalLine />
                </>
            )}
        </>
    );
}

SignInStepOne.defaultProps = {
    displayName: "SignInStepOne",
};
