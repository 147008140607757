import React from "react";
import { TextInput, View, ViewStyle } from "react-native";
import styled, { css, useTheme } from "../styling/styled-components";

import Text from "./Text";

const Container = styled(View)``;

const Row = styled(View)`
    flex-direction: row;
`;

const Label = styled(Text)`
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            margin-top: ${({ theme }) => theme.spacing.small}px;
        `}
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            text-align: left;
            margin: 0 auto;
            width: 296px;
        `}
`;

const LoginInput = styled(TextInput)<{ type: string }>`
    outline-width: 0px;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.HURRICANE};
    margin-right: 3px;
    padding-left: 1px;
    width: ${({ theme }) => (theme.isDesktop ? "296px" : "100%")};
    font-family: latoBold;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    letter-spacing: ${({ theme, type }) =>
        type === "otp" ? theme.spacing.medium : 0}px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    ${({ theme }) =>
        theme.isDesktop &&
        `
        margin: 0 auto;
    `}
`;

const Separator = styled(View)`
    width: 15px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.HURRICANE};
    display: flex;
    align-self: center;
`;

const TYPE = { PHONE: "phone" };

type Props = {
    value: string;
    length: number;
    type?: string;
    color: boolean;
    label: string;
    labelColor: string;
    placeholder?: string;
    onChange: (value: string) => void;
    onBlur?: () => void;
    onSubmitEditing?: () => void;
    style: ViewStyle;
};

export default function SignInInput({
    value,
    onChange,
    onBlur,
    onSubmitEditing,
    length = 10,
    type,
    label,
    labelColor,
    placeholder,
    color,
    style,
}: Props) {
    const keyboardType = (): string => {
        if (type === "otp") {
            return "number-pad";
        } else if (type === "email") {
            return "email-address";
        }
        return "";
    };
    const autoComplete = (): string => {
        if (type === "otp") {
            return "sms-otp";
        } else if (type === "email") {
            return "email";
        }
        return "";
    };
    const textContentType = (): string => {
        if (type === "otp") {
            return "oneTimeCode";
        } else if (type === "email") {
            return "emailAddress";
        }
        return "";
    };
    const { colors, isDesktop } = useTheme();

    return (
        <Container style={style}>
            <Row>
                <LoginInput
                    keyboardType={keyboardType()}
                    style={color ? { color, borderBottomColor: color } : {}}
                    value={value}
                    type={type}
                    onBlur={onBlur}
                    onChangeText={(value) => {
                        if (type === "otp") {
                            if (
                                value
                                    .split("")
                                    .reduce(
                                        (acc, cur) =>
                                            acc && !isNaN(parseInt(cur, 10)),
                                        true
                                    )
                            ) {
                                onChange(value);
                            }
                        } else {
                            onChange(value);
                        }
                    }}
                    maxLength={length}
                    autoComplete={autoComplete()}
                    textContentType={textContentType()}
                    placeholder={placeholder}
                    onSubmitEditing={onSubmitEditing}
                    textAlign={isDesktop ? "center" : "left"}
                />
            </Row>
            {label && (
                <Label
                    type="eyebrow"
                    style={
                        color || labelColor
                            ? { color: labelColor || color }
                            : {}
                    }
                >
                    {label}
                </Label>
            )}
        </Container>
    );
}

SignInInput.defaultProps = {};
