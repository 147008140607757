// react
import React from "react";
import { Pressable } from "react-native";

// style
import styled from "../styling/styled-components";

const StyledCard = styled(Pressable)`
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.WHITE};
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    max-width: ${({ theme }) => theme.maxBodyWidth}px;
`;

type Props = {
    header: string;
    children: JSX.Element | string | string[];
    onPress: () => void;
};

export default function Card({ header, children, onPress, style }: Props) {

    return (
        <StyledCard onPress={onPress} style={style} key={header}>
            {children}
        </StyledCard>
    );
}

Card.defaultProps = {
    onPress: () => null,
};
