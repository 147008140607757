export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Scalars['String']>;
  googlePlacesId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type AppleWalletPass = {
  __typename?: 'AppleWalletPass';
  presignedS3URL: Scalars['String'];
};

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['String'];
  assetType?: Maybe<AssetType>;
  externalId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  value?: Maybe<Scalars['Int']>;
};

export type AssetType = {
  __typename?: 'AssetType';
  assetTypeId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type CampusConfig = {
  __typename?: 'CampusConfig';
  campusName: Scalars['String'];
  campusSlug: Scalars['String'];
  canCharge: Scalars['Boolean'];
  canSignUp: Scalars['Boolean'];
  comboCampus?: Maybe<Scalars['Boolean']>;
  contactPhoneNumber: Scalars['String'];
  dueBackDays?: Maybe<Scalars['Int']>;
  hidePass?: Maybe<Scalars['Boolean']>;
  lateCopy?: Maybe<Scalars['String']>;
  latePeriod?: Maybe<Scalars['String']>;
  localTimezone: Scalars['String'];
  messageFirstLoop?: Maybe<Scalars['String']>;
  messageLate?: Maybe<Scalars['String']>;
  messageOptIn?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pendingPeriod?: Maybe<Scalars['String']>;
  programDetailsURL: Scalars['String'];
  returnLocations?: Maybe<Array<Maybe<Location>>>;
  returnLocationsURL?: Maybe<Scalars['String']>;
  s3LogoKey: Scalars['String'];
  signUpChallenges?: Maybe<Array<Maybe<SignUpChallenge>>>;
  signUpLink?: Maybe<Scalars['String']>;
  signUpPrompt?: Maybe<Scalars['String']>;
  singleSignOnConfig?: Maybe<SingleSignOnConfig>;
  termsCopy?: Maybe<Scalars['String']>;
  termsLink?: Maybe<Scalars['String']>;
};

export type CampusConfigsPaginated = {
  __typename?: 'CampusConfigsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<CampusConfig>>>;
};

export enum ChallengeType {
  Campusid = 'CAMPUSID',
  Confirmphone = 'CONFIRMPHONE',
  Email = 'EMAIL',
  Sso = 'SSO',
  Terms = 'TERMS'
}

export enum ChargeStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS'
}

export type ChargeableInput = {
  chargeableId: Scalars['ID'];
  vendor: ChargeableVendor;
};

export enum ChargeableVendor {
  Transact = 'transact'
}

export type Communication = {
  __typename?: 'Communication';
  communicationId: Scalars['String'];
  communicationType: Scalars['String'];
  consumerId?: Maybe<Scalars['ID']>;
  parameters?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type CommunicationInput = {
  communicationId: Scalars['String'];
  communicationType: Scalars['String'];
  consumerId?: InputMaybe<Scalars['ID']>;
  parameters?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  consumerId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['ID']>;
  marketingOptIn?: Maybe<MarketingOptions>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  reuserId?: Maybe<Scalars['String']>;
};

export type ConsumerStats = {
  __typename?: 'ConsumerStats';
  consumerId: Scalars['ID'];
  openLoops: Scalars['Int'];
  totalClosedLoops: Scalars['Int'];
  totalOpenLoops: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type CreateCampusConsumerInput = {
  chargeables?: InputMaybe<Array<InputMaybe<ChargeableInput>>>;
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  marketingOptIn?: InputMaybe<MarketingOptionsInput>;
  metadata?: InputMaybe<Array<InputMaybe<KeyValueInput>>>;
  name?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
  reuserId?: InputMaybe<Scalars['String']>;
};

export type ExternalSignUpCheckInput = {
  referredExternalId: Scalars['String'];
  referredOrganizationId: Scalars['String'];
};

export type ForgivenessDetails = {
  __typename?: 'ForgivenessDetails';
  reason: ForgivenessReason;
  timestamp: Scalars['String'];
};

export enum ForgivenessReason {
  InstantForgiveness = 'INSTANT_FORGIVENESS',
  ManualForgiveness = 'MANUAL_FORGIVENESS',
  ReturnReceivedForgiveness = 'RETURN_RECEIVED_FORGIVENESS'
}

export type InitiateSignUpInput = {
  externalId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type InitiateUserCompletedSignUpChallengesInput = {
  confirmationCode: Scalars['String'];
  externalId?: InputMaybe<Scalars['ID']>;
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type KeyValueInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type LikeAssetTypeResolution = {
  __typename?: 'LikeAssetTypeResolution';
  likeAssetTypeIds?: Maybe<Array<Scalars['ID']>>;
  resolutionTiming: ResolutionTiming;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Address>;
  externalId?: Maybe<Scalars['ID']>;
  locationId: Scalars['ID'];
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
};

export type Loop = {
  __typename?: 'Loop';
  asset: Asset;
  closedAt?: Maybe<Scalars['String']>;
  consumer: Consumer;
  externalId?: Maybe<Scalars['ID']>;
  locations?: Maybe<LoopLocations>;
  loopId: Scalars['ID'];
  loopStatus: LoopStatus;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  openedAt?: Maybe<Scalars['String']>;
  resolutions?: Maybe<Array<Maybe<LoopResolution>>>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type LoopLocations = {
  __typename?: 'LoopLocations';
  closedAt?: Maybe<Location>;
  openedAt: Location;
};

export type LoopResolution = {
  __typename?: 'LoopResolution';
  loopId: Scalars['ID'];
  resolution: Resolution;
  resolutionStatus: ResolutionStatus;
  triggerResolutionAt: Scalars['String'];
};

export enum LoopStatus {
  Closed = 'closed',
  Open = 'open'
}

export type LoopsPaginated = {
  __typename?: 'LoopsPaginated';
  nextToken?: Maybe<Scalars['String']>;
  results?: Maybe<Array<Maybe<Loop>>>;
};

export type MarketingOptions = {
  __typename?: 'MarketingOptions';
  email?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['Boolean']>;
};

export type MarketingOptionsInput = {
  email?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminAppendUserCompletedSignUpChallenge?: Maybe<UserCompletedSignUpChallenges>;
  adminCreateConsumer?: Maybe<Consumer>;
  adminInitiateUserCompletedSignUpChallenges?: Maybe<UserCompletedSignUpChallenges>;
  externalSignUpCheck?: Maybe<Consumer>;
  initiateSignUp?: Maybe<NextSignUpChallenge>;
  respondToSignUpChallenge?: Maybe<NextSignUpChallenge>;
  sendCommunication?: Maybe<Communication>;
};


export type MutationAdminAppendUserCompletedSignUpChallengeArgs = {
  input?: InputMaybe<SignUpChallengeResponseInput>;
};


export type MutationAdminCreateConsumerArgs = {
  input: CreateCampusConsumerInput;
};


export type MutationAdminInitiateUserCompletedSignUpChallengesArgs = {
  input?: InputMaybe<InitiateUserCompletedSignUpChallengesInput>;
};


export type MutationExternalSignUpCheckArgs = {
  input?: InputMaybe<ExternalSignUpCheckInput>;
};


export type MutationInitiateSignUpArgs = {
  input?: InputMaybe<InitiateSignUpInput>;
};


export type MutationRespondToSignUpChallengeArgs = {
  input?: InputMaybe<SignUpChallengeResponseInput>;
};


export type MutationSendCommunicationArgs = {
  input?: InputMaybe<CommunicationInput>;
};

export type NextSignUpChallenge = {
  __typename?: 'NextSignUpChallenge';
  countCompletedSignUpChallenges?: Maybe<Scalars['Int']>;
  countTotalSignUpChallenges?: Maybe<Scalars['Int']>;
  nextChallenge?: Maybe<SignUpChallenge>;
  previousChallengeAccepted?: Maybe<Scalars['Boolean']>;
  previousChallengeErrorMessage?: Maybe<Scalars['String']>;
  reuserAuthToken?: Maybe<Scalars['String']>;
  signUpComplete: Scalars['Boolean'];
};

export type QrData = {
  __typename?: 'QRData';
  data: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  adminGetAppleWalletPass?: Maybe<AppleWalletPass>;
  adminGetConsumer?: Maybe<Consumer>;
  adminGetConsumerQRData?: Maybe<QrData>;
  adminGetLoop?: Maybe<Loop>;
  adminGetResolution?: Maybe<Resolution>;
  adminGetUserCompletedSignUpChallenges?: Maybe<UserCompletedSignUpChallenges>;
  consumerQRData?: Maybe<QrData>;
  getAppleWalletPass?: Maybe<AppleWalletPass>;
  getCampusConfig?: Maybe<CampusConfig>;
  getConsumerStats?: Maybe<ConsumerStats>;
  getLoop?: Maybe<Loop>;
  listCampusConfigs?: Maybe<CampusConfigsPaginated>;
  listRentalStepsForRental?: Maybe<Array<Maybe<RentalStep>>>;
  queryLoops?: Maybe<LoopsPaginated>;
  searchRentals?: Maybe<RentalSearchResults>;
};


export type QueryAdminGetAppleWalletPassArgs = {
  consumerId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type QueryAdminGetConsumerArgs = {
  consumerId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type QueryAdminGetConsumerQrDataArgs = {
  consumerId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type QueryAdminGetLoopArgs = {
  loopId: Scalars['ID'];
  organizationId: Scalars['ID'];
};


export type QueryAdminGetResolutionArgs = {
  organizationId: Scalars['ID'];
  resolutionId: Scalars['ID'];
};


export type QueryAdminGetUserCompletedSignUpChallengesArgs = {
  confirmationCode: Scalars['String'];
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
};


export type QueryGetCampusConfigArgs = {
  campusSlug?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryGetLoopArgs = {
  loopId: Scalars['ID'];
};


export type QueryListCampusConfigsArgs = {
  paginationToken?: InputMaybe<Scalars['String']>;
};


export type QueryListRentalStepsForRentalArgs = {
  rentalId: Scalars['ID'];
  rentalStepStatus?: InputMaybe<RentalStepStatus>;
};


export type QueryQueryLoopsArgs = {
  fromTimestamp?: InputMaybe<Scalars['String']>;
  loopStatus?: InputMaybe<LoopStatus>;
  paginationToken?: InputMaybe<Scalars['String']>;
  toTimestamp?: InputMaybe<Scalars['String']>;
};


export type QuerySearchRentalsArgs = {
  assetTypeIds?: InputMaybe<Array<Scalars['String']>>;
  fromTimestamp: Scalars['String'];
  paginateFrom?: InputMaybe<Scalars['Int']>;
  rentalPhases?: InputMaybe<Array<RentalPhase>>;
  rentalStatuses?: InputMaybe<Array<RentalStatus>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  toTimestamp: Scalars['String'];
};

export enum RentalPhase {
  AboutToExpire = 'ABOUT_TO_EXPIRE',
  AlmostDue = 'ALMOST_DUE',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedPending = 'FAILED_PENDING',
  Fined = 'FINED',
  FinedAutomatic = 'FINED_AUTOMATIC',
  FinedManual = 'FINED_MANUAL',
  FineError = 'FINE_ERROR',
  FinePending = 'FINE_PENDING',
  Initiated = 'INITIATED',
  Late = 'LATE',
  LatePending = 'LATE_PENDING',
  RefundRequested = 'REFUND_REQUESTED'
}

export type RentalSearchDocument = {
  __typename?: 'RentalSearchDocument';
  charge?: Maybe<RentalSearchDocumentCharge>;
  createdAt: Scalars['String'];
  dueAt?: Maybe<Scalars['String']>;
  forgivenessDetails?: Maybe<ForgivenessDetails>;
  lastPhase: RentalPhase;
  loopId: Scalars['ID'];
  rentalId: Scalars['ID'];
  rentedAsset?: Maybe<Asset>;
  resolvingLoop?: Maybe<RentalSearchDocumentLoop>;
  status: RentalStatus;
  updatedAt: Scalars['String'];
};

export type RentalSearchDocumentCharge = {
  __typename?: 'RentalSearchDocumentCharge';
  chargeStatus: ChargeStatus;
};

export type RentalSearchDocumentLoop = {
  __typename?: 'RentalSearchDocumentLoop';
  closedAt?: Maybe<Scalars['String']>;
  loopId: Scalars['ID'];
};

export type RentalSearchResults = {
  __typename?: 'RentalSearchResults';
  hitAggregations?: Maybe<Array<SearchHitTotals>>;
  rentals?: Maybe<Array<RentalSearchDocument>>;
  totalHits: Scalars['Int'];
};

export enum RentalStatus {
  Canceled = 'CANCELED',
  CanceledWithRefund = 'CANCELED_WITH_REFUND',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Forgiven = 'FORGIVEN',
  InProgress = 'IN_PROGRESS'
}

export type RentalStep = {
  __typename?: 'RentalStep';
  id: Scalars['ID'];
  rentalId: Scalars['ID'];
  rentalPhase: RentalPhase;
  rentalStepConfigType?: Maybe<Scalars['String']>;
  status: RentalStepStatus;
  statusUpdatedAt: Scalars['String'];
  triggerAt: Scalars['String'];
};

export enum RentalStepStatus {
  CheckFailed = 'CHECK_FAILED',
  NotificationSent = 'NOTIFICATION_SENT',
  Pending = 'PENDING',
  Skipped = 'SKIPPED'
}

export type Resolution = {
  __typename?: 'Resolution';
  description?: Maybe<Scalars['String']>;
  label?: Maybe<ResolutionLabel>;
  metadata?: Maybe<Array<Maybe<KeyValue>>>;
  name?: Maybe<Scalars['String']>;
  resolutionId: Scalars['ID'];
  type: ResolutionOptions;
  useAgain?: Maybe<Scalars['Boolean']>;
};

export enum ResolutionLabel {
  Charged = 'charged',
  Due = 'due',
  Notified = 'notified',
  Overdue = 'overdue'
}

export type ResolutionOptions = {
  __typename?: 'ResolutionOptions';
  likeAssetTypeResolution?: Maybe<LikeAssetTypeResolution>;
  sameAssetResolution?: Maybe<SameAssetResolution>;
  sameAssetTypeResolution?: Maybe<SameAssetTypeResolution>;
};

export enum ResolutionStatus {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded'
}

export type ResolutionTiming = {
  __typename?: 'ResolutionTiming';
  duration?: Maybe<Scalars['Int']>;
  roundToTime?: Maybe<Scalars['String']>;
  roundToTimezone?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type SameAssetResolution = {
  __typename?: 'SameAssetResolution';
  resolutionTiming: ResolutionTiming;
};

export type SameAssetTypeResolution = {
  __typename?: 'SameAssetTypeResolution';
  resolutionTiming: ResolutionTiming;
};

export type SearchHitAggregation = {
  docCount: Scalars['Int'];
  key: Scalars['String'];
};

export type SearchHitSubtotals = SearchHitAggregation & {
  __typename?: 'SearchHitSubtotals';
  docCount: Scalars['Int'];
  key: Scalars['String'];
};

export type SearchHitTotals = SearchHitAggregation & {
  __typename?: 'SearchHitTotals';
  docCount: Scalars['Int'];
  key: Scalars['String'];
  subtotals?: Maybe<Array<SearchHitSubtotals>>;
};

export type SignUpChallenge = {
  __typename?: 'SignUpChallenge';
  challengeId: Scalars['ID'];
  challengeType: ChallengeType;
  inputMaxLength?: Maybe<Scalars['Int']>;
  inputMinLength?: Maybe<Scalars['Int']>;
  inputName?: Maybe<Scalars['String']>;
  inputRegEx?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
};

export type SignUpChallengeInput = {
  challengeId: Scalars['ID'];
  challengeType: ChallengeType;
  inputMaxLength?: InputMaybe<Scalars['Int']>;
  inputMinLength?: InputMaybe<Scalars['Int']>;
  inputName?: InputMaybe<Scalars['String']>;
  inputRegEx?: InputMaybe<Scalars['String']>;
  isRequired?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
};

export type SignUpChallengeResponse = {
  __typename?: 'SignUpChallengeResponse';
  challenge: SignUpChallenge;
  challengeResponse: Scalars['String'];
  confirmationCode: Scalars['String'];
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type SignUpChallengeResponseInput = {
  challenge: SignUpChallengeInput;
  challengeResponse: Scalars['String'];
  confirmationCode: Scalars['String'];
  marketingOptIn?: InputMaybe<MarketingOptionsInput>;
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type SingleSignOnConfig = {
  __typename?: 'SingleSignOnConfig';
  mappings?: Maybe<Array<Maybe<SingleSignOnMapping>>>;
  url: Scalars['String'];
};

export type SingleSignOnMapping = {
  __typename?: 'SingleSignOnMapping';
  externalField: Scalars['String'];
  internalField: Scalars['String'];
};

export type UserCompletedSignUpChallenges = {
  __typename?: 'UserCompletedSignUpChallenges';
  completedChallenges?: Maybe<Array<Maybe<SignUpChallengeResponse>>>;
  confirmationCode: Scalars['String'];
  externalId?: Maybe<Scalars['ID']>;
  organizationId: Scalars['String'];
  phoneNumber: Scalars['String'];
};
