import React from "react";
import Svg, { Circle } from "react-native-svg";

type Props = {
    width: number;
    fill: string;
};

export default function Dot({ style, width, fill }: Props) {
    return (
        <Svg height={`${width}px`} width={`${width}px`} viewBox="0 0 100 100">
            <Circle cx="50" cy="50" r="45" fill={fill} />
        </Svg>
    );
}

Dot.defaultProps = {};
