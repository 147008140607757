// react
import React, { useContext, useEffect, useMemo } from "react";
import { View, FlatList, ActivityIndicator } from "react-native";

// style
import styled, { css } from "../styling/styled-components";

// context
import CampusContext from "../contexts/CampusContext";
import GlobalContext from "../contexts/GlobalContext";

// API
import { useSearchRentals } from "../graphql/hooks/useSearchRentals";

// components
import BackHeader from "../components/BackHeader";
import Error from "../components/Error";
import FinedFailedRentalAccordian from "../components/FinedFailedRentalAccordian";
import FinePlaceholder from "../components/FinePlaceholder";
import NoFinePlaceholder from "../components/NoFinePlaceholder";
import ScreenView from "../components/ScreenView";
import Text from "../components/Text";

// utils
import { addDays } from "date-fns";
import { queryForFailed } from "../utils/rentals/queryByState";

const STRINGS = {
    HEADER: "Failed rentals",
    BACK: "BACK",
    LAST_30: "LAST 30 DAYS",
    RENTAL: (finalCount: string) => `RENTALS (${finalCount})`,
    DATE_FINED_FAILED: (canCharge: boolean) =>
        canCharge ? "DATE FINED" : "DATE FAILED",
    STATUS: "STATUS",
    TOTAL_FINED_FAILED: (canCharge: boolean) =>
        canCharge ? "TOTAL FINES" : "TOTAL FAILED",
};

const Container = styled(ScreenView)``;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) =>
        theme.isMobile ? theme.spacing.xlarge : theme.spacing.huge}px;
`;

const NoChargesWrapper = styled(View)`
    align-self: center;
    margin-top: 40px;
`;

const ChargesWrapper = styled(NoChargesWrapper)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            max-width: 455px;
            height: 100%;
            align-self: flex-start;
        `}
    ${({ theme: { isDesktop } }) =>
        !isDesktop &&
        css`
            margin-left: -${({ theme }) => theme.spacing.small}px;
            margin-right: -${({ theme }) => theme.spacing.small}px;
        `}
`;

const ListHeader = styled(View)`
    margin-vertical: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${({ theme }) =>
        theme.isMobile ? theme.spacing.xlarge : theme.spacing.xxlarge}px;
    padding-horizontal: ${({ theme }) =>
        theme.isDesktop ? 12 : theme.globalMobilePadding}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const HeaderSubText = styled(BlueText)``;

const ExhaustText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const StyledFlatList = styled(FlatList)`
    margin-horizontal: ${({ theme }) =>
        theme.isDesktop ? 0 : -1 * theme.globalMobilePadding}px;
    min-width: ${({ theme }) => (theme.isDesktop ? 680 : null)};
`;

const StyledError = styled(Error)`
    margin-horizontal: ${({ theme }) =>
        theme.isDesktop ? 0 : -1 * theme.globalMobilePadding}px;
    min-width: ${({ theme }) => (theme.isDesktop ? 680 : null)};
`;

const Body = styled(View)`
    flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
    gap: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.huge : theme.spacing.medium}px;
`;

const ContentWrapper = styled(View)``;

export default function DueSoonScreen({ navigation }: props) {
    const { lateCopy, canCharge } = useContext(CampusContext).campusConfig;

    const { consumerAcceptedTermsAt } =
        useContext(GlobalContext).globalSelections;

    const [
        searchRentalsLastThirty,
        rentalsLastThirty,
        rentalsInitiatedLastThirty,
        rentalsLoadingLastThirty,
        rentalsErrorsLastThirty,
    ] = useSearchRentals();

    const [
        searchRentalsAllTime,
        rentalsAllTime,
        rentalsInitiatedAllTime,
        rentalsLoadingAllTime,
        rentalsErrorsAllTime,
    ] = useSearchRentals();

    const apiErrors = rentalsErrorsLastThirty || rentalsErrorsAllTime;

    useEffect(() => {
        const toTimestamp = new Date().toISOString();
        const thirtyDaysAgo = addDays(new Date(), -30).toISOString();

        queryForFailed(searchRentalsLastThirty, thirtyDaysAgo, toTimestamp, {
            size: 0,
        });
        queryForFailed(
            searchRentalsAllTime,
            consumerAcceptedTermsAt,
            toTimestamp
        );
    }, []);

    const sortedRentals = useMemo(() => {
        if (
            typeof rentalsAllTime === "object" &&
            rentalsAllTime !== null &&
            "rentals" in rentalsAllTime &&
            Array.isArray(rentalsAllTime.rentals)
        ) {
            const now = new Date();
            const twelveMonthsAgo = new Date();
            twelveMonthsAgo.setMonth(now.getMonth() - 12);

            return [...rentalsAllTime.rentals]
                .filter((rental) => {
                    const dueAtDate = new Date(rental.dueAt);
                    return dueAtDate >= twelveMonthsAgo && dueAtDate <= now;
                })
                .sort((a, b) => {
                    const dateA = new Date(a.createdAt);
                    const dateB = new Date(b.createdAt);
                    return dateB.getTime() - dateA.getTime();
                });
        }
        return [];
    }, [rentalsAllTime]);

    const renderFinedFailedAccordian = ({ item, index }) => {
        return (
            <FinedFailedRentalAccordian
                rental={item}
                initExpanded={index == 0}
            />
        );
    };

    return (
        <Container>
            <BackHeader
                label={STRINGS.BACK}
                onPress={() => navigation.navigate("OverviewScreen", {})}
            />
            <HeaderWrapper>
                <HeaderText type="h2">{STRINGS.HEADER}</HeaderText>
                <HeaderSubText type="b2">{lateCopy}</HeaderSubText>
            </HeaderWrapper>
            <Body>
                <ContentWrapper>
                    {apiErrors ? (
                        <StyledError />
                    ) : (
                        <StyledFlatList
                            data={sortedRentals}
                            ListHeaderComponent={
                                rentalsAllTime?.rentals && (
                                    <ListHeader>
                                        <ExhaustText type="eyebrow">
                                            {STRINGS.RENTAL(
                                                sortedRentals.length.toString()
                                            )}
                                        </ExhaustText>
                                        <ExhaustText type="eyebrow">
                                            {STRINGS.STATUS}
                                        </ExhaustText>
                                    </ListHeader>
                                )
                            }
                            ListEmptyComponent={
                                rentalsInitiatedAllTime ? (
                                    <NoChargesWrapper>
                                        <NoFinePlaceholder
                                            canCharge={canCharge}
                                        />
                                    </NoChargesWrapper>
                                ) : (
                                    <ActivityIndicator />
                                )
                            }
                            renderItem={renderFinedFailedAccordian}
                            showsVerticalScrollIndicator={false}
                            initialNumToRender={10}
                            keyExtractor={(item) => item?.rentalId}
                        />
                    )}
                </ContentWrapper>
                {rentalsAllTime?.rentals?.length > 0 ? (
                    <ChargesWrapper>
                        <FinePlaceholder />
                    </ChargesWrapper>
                ) : null}
            </Body>
        </Container>
    );
}
