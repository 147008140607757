import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import EmptyPlaceholder from "./EmptyPlaceholder";

const STRINGS = {
    TITLE: "Nothing past due",
    DESCRIPTION: "Keep up the great work!",
};

type Props = {
    displayName?: string;
    style?: StyleProp<ViewStyle>;
};

export default function PastDuePlaceholder({ style }: Props) {
    return (
        <EmptyPlaceholder
            description={STRINGS.DESCRIPTION}
            title={STRINGS.TITLE}
            style={style}
        />
    );
}
