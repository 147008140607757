import React from "react";
import { View } from "react-native";
import Error from "../components/Error";
import Text from "../components/Text";
import styled from "../styling/styled-components";

const Container = styled(View)`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.SMOG};
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.large}px;
`;

const JugWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const BlueText = styled(Text)`
    max-width: 360px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    async componentDidCatch(error, errorInfo) {
        console.error("Crash caught", error);
    }

    render() {
        if (this.state.hasError) {
            return <Error />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
