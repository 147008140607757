// react
import React, { useContext } from "react";

// context
import CampusContext from "../contexts/CampusContext";

// components
import { View, Pressable } from "react-native";
import Text from "../components/Text";

// style
import styled, { useTheme } from "../styling/styled-components";

// utils
import { openURL } from "../utils/helpers";

// constants
import {
    TERMS_URL,
    PRIVACY_URL,
    TOPANGA_SITE_URL,
    REUSEPASS_SITE_URL,
} from "../utils/constants";

// images
import { ReusePassLogo } from "../assets/vectors";

const STRINGS = {
    POWERED: "Powered by Topanga.io",
    REUSEPASS: "ReusePass",
    RIGHTS: "All rights reserved.",
    PRIVACY: "Privacy Policy",
    TERMS: "Terms & Conditions",
};

const Container = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.large}px;
    align-items: center;
    text-align: center;
    flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.FROTH};
    margin-horizontal: -${({ theme }) => (theme.isDesktop ? theme.globalDesktopPadding : theme.globalMobilePadding)}px;
    padding: ${({ theme }) =>
        theme.isDesktop
            ? theme.globalDesktopPadding
            : theme.globalMobilePadding}px;
`;

const Link = styled(Pressable)`
    align-items: center;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    font-family: DTGetaiGrotesk;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

const BodyWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    flex-direction: row;
    justify-content: space-around;
`;

const Divider = styled(View)`
    padding: ${({ theme }) => theme.spacing.small}px;
`;

const LinkWrapper = styled(Pressable)``;

const LogoWrapper = styled(Pressable)``;

const LogoAndHeader = styled(View)`
    flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
    align-items: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    text-align: ${({ theme }) => (theme.isDesktop ? "left" : "center")};
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
`;

type Props = {};

export default function Footer({ style }: Props) {
    const { isDesktop } = useTheme();

    const { termsLink } = useContext(CampusContext).campusConfig;

    return (
        <Container style={style}>
            <LogoAndHeader>
                <LogoWrapper onPress={() => openURL(REUSEPASS_SITE_URL)}>
                    <ReusePassLogo
                        height={isDesktop ? 100 : 60}
                        width={isDesktop ? 100 : 60}
                    />
                </LogoWrapper>
                <HeaderWrapper>
                    <HeaderText type="h1">{STRINGS.REUSEPASS}</HeaderText>
                    <LinkWrapper onPress={() => openURL(TOPANGA_SITE_URL)}>
                        <BlueText type="sh1">{STRINGS.POWERED}</BlueText>
                    </LinkWrapper>
                    <BlueText type="b2">{STRINGS.RIGHTS}</BlueText>
                </HeaderWrapper>
            </LogoAndHeader>

            <BodyWrapper>
                <LinkWrapper onPress={() => openURL(PRIVACY_URL)}>
                    <BlueText type="link">{STRINGS.PRIVACY}</BlueText>
                </LinkWrapper>
                <Divider />
                <LinkWrapper onPress={() => openURL(termsLink || TERMS_URL)}>
                    <BlueText type="link">{STRINGS.TERMS}</BlueText>
                </LinkWrapper>
            </BodyWrapper>
        </Container>
    );
}

Footer.defaultProps = {};
