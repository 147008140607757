import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { CheckCircle, GreenGlobe } from "../assets/vectors";
import styled from "../styling/styled-components";
import Text from "./Text";

const Container = styled(View)`
    display: flex;
    flex-direction: column;
    justfify-content: center;
    align-items: center;
    margin: ${({ theme }) => theme.spacing.xxlarge}px 0px;
    background-color: ${({ theme }) => theme.colors.FOAM};
`;

const AssetContainer = styled(View)`
    margin-top: -40px;
`;

const TextContent = styled(View)`
    display: flex;
    flex-direction: column;
    justfify-content: center;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.small}px;
    padding: ${({ theme }) => theme.spacing.large}px;
`;

const Title = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    margin-bottom: ${({ theme }) => theme.isDesktop ? theme.spacing.large : theme.spacing.medium}px;
    color: ${({ theme }) => theme.colors.GREEN_1};
`;

const DescriptionWrapper = styled(View)`
    display: inline-block;
`;

const Description = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    color: ${({ theme }) => theme.colors.ASH};
`;

const CheckCircleWrapper = styled(View)`
    display: inline-block;
    top: 4px;
`;

const STRINGS = {
    TITLE: "It’s never too late to help the planet!",
    DESCRIPTION_P1: "This ",
    DESCRIPTION_P2:
        " checkmark means we eventually received a container. Thank you for doing the right thing and reducing waste!",
};

type Props = {
    displayName?: string;
    style?: StyleProp<ViewStyle>;
};

export default function FinePlaceholder({ style }: Props) {
    return (
        <Container
            style={style}
            accessible={true}
            accessibilityLabel="Fine Placeholder"
        >
            <AssetContainer accessible={true} accessibilityRole="image">
                <GreenGlobe />
            </AssetContainer>
            <TextContent>
                <Title type="sh1">{STRINGS.TITLE}</Title>
                <DescriptionWrapper>
                    <Description type="b2">
                        {STRINGS.DESCRIPTION_P1}
                    </Description>
                    <CheckCircleWrapper
                        accessible={true}
                        accessibilityLabel="Return received"
                    >
                        <CheckCircle />
                    </CheckCircleWrapper>
                    <Description type="b2">
                        {STRINGS.DESCRIPTION_P2}
                    </Description>
                </DescriptionWrapper>
            </TextContent>
        </Container>
    );
}
