import React from "react";
import { Pressable, ActivityIndicator } from "react-native";
import styled, { useTheme } from "../styling/styled-components";
import Text from "./Text";

interface Props {
    blur?: boolean;
    isLoading: boolean;
    label: string;
    onPress: () => void;
    status: string;
    type: string;
    size: string;
    children: string | JSX.Element | JSX.Element[];
}

const PrimaryButton = styled(Pressable)`
    display: flex;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.BLUE};
    padding-horizontal: ${({ theme, size }) =>
        size == "large" ? theme.spacing.xlarge : theme.spacing.small}px;
    padding-vertical: ${({ theme, size }) =>
        size == "large" ? theme.spacing.small : theme.spacing.tiny}px;
    width: ${({ theme }) => theme.isMobile && "294px"};
`;

const SecondaryButton = styled(PrimaryButton)`
    background-color: null;
    border: 1px;
    border-color: ${({ theme }) => theme.colors.BLUE};
    width: null;
`;

const LabelText = styled(Text)`
    text-align: center;
`;

const PrimaryLabelText = styled(LabelText)`
    color: ${({ theme }) => theme.colors.WHITE};
`;

const SecondaryLabelText = styled(LabelText)`
    color: ${({ theme }) => theme.colors.BLUE};
`;

export default function Button({
    blur,
    isLoading,
    label,
    onPress,
    status,
    style,
    type,
    size,
    children,
}) {
    const { colors } = useTheme();

    let buttonType = type ? type : "primary";
    buttonType = isLoading ? "secondary" : buttonType;
    const ButtonComponent =
        buttonType === "secondary" ? SecondaryButton : PrimaryButton;
    const LabelTextComponent =
        buttonType === "secondary" ? SecondaryLabelText : PrimaryLabelText;

    if (isLoading) {
        return (
            <ButtonComponent status="disabled" style={[style]} size={size}>
                <ActivityIndicator color={colors.BLUE} />
            </ButtonComponent>
        );
    } else if (children) {
        return (
            <ButtonComponent
                onPress={onPress}
                status={status}
                style={[style]}
                size={size}
            >
                {children}
            </ButtonComponent>
        );
    } else {
        return (
            <ButtonComponent
                onPress={onPress}
                status={status}
                style={[style]}
                size={size}
            >
                <LabelTextComponent
                    type={size == "large" ? "sh1" : "h3"}
                    size={size}
                    status={status}
                >
                    {label}
                </LabelTextComponent>
            </ButtonComponent>
        );
    }
}

export function ButtonWithIcon({
    blur,
    isLoading,
    label,
    onPress,
    status,
    style,
    type,
    size,
    icon,
}) {
    const { colors, spacing } = useTheme();

    let buttonType = type ? type : "primary";
    buttonType = isLoading ? "secondary" : buttonType;
    const ButtonComponent =
        buttonType === "secondary" ? SecondaryButton : PrimaryButton;
    const LabelTextComponent =
        buttonType === "secondary" ? SecondaryLabelText : PrimaryLabelText;

    const labelMarginLeft = spacing.small + "px"

    if (isLoading) {
        return (
            <ButtonComponent status="disabled" style={[style]} size={size}>
                <ActivityIndicator color={colors.BLUE} />
            </ButtonComponent>
        );
    } else {
        return (
            <ButtonComponent
                onPress={onPress}
                status={status}
                style={[style]}
                size={size}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {icon}
                    <LabelTextComponent
                        type={size == "large" ? "sh1" : "h3"}
                        size={size}
                        status={status}
                        style={{textAlign: "center", marginLeft: labelMarginLeft }}
                    >
                        {label}
                    </LabelTextComponent>
                </div>
            </ButtonComponent>
        );
    }
}
