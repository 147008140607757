import * as styledComponents from "styled-components/native";
import { theme } from "./theme";

const {
    default: styled,
    css,
    ThemeProvider,
    useTheme,
} = styledComponents as styledComponents.ReactNativeThemedStyledComponentsModule<any>;

export { css, ThemeProvider, theme, useTheme };
export default styled;
