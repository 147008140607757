import React from "react";
import { View } from "react-native";
import styled, { css } from "../styling/styled-components";
import ScreenView from "../components/ScreenView";
import CampusHeader from "../components/CampusHeader";

const Container = styled(ScreenView)`
    background-color: ${({ theme }) => theme.colors.SMOG};
    color: ${({ theme }) => theme.colors.SMOG};
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            padding-left: ${({ theme }) => theme.spacing.medium}px;
            padding-right: ${({ theme }) => theme.spacing.medium}px;
        `}
`;

const Children = styled(View)``;

const ProgressBarContainer = styled(View)`
    flex-direction: row;
    height: 14px;
    background-color: white;
    margin-left: -${({ theme }) => (theme.isDesktop ? theme.globalDesktopPadding : theme.globalMobilePadding)}px;
    margin-top: -${({ theme }) => (theme.isDesktop ? theme.globalDesktopPadding : theme.globalMobilePadding)}px;
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            height: ${({ theme }) => theme.spacing.small}px;
        `}
    z-index: 99;
    position: sticky;
    top: 0;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
`;

const ProgressBar = styled(View)`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.LIME_SQUEEZE};
`;

const SignInViewContainer = styled(View)``;

type Props = {
    progress: number;
    showBranding: boolean;
    children: React.ReactNode;
};

export default function SignInView({
    progress,
    showBranding,
    children,
    style,
}: Props) {
    return (
        <SignInViewContainer>
            <ProgressBarContainer>
                <ProgressBar />
                <View
                    style={{ flex: (1 - progress) * (progress > 0.1 ? 2 : 3) }}
                />
            </ProgressBarContainer>
            <Container style={style}>
                <CampusHeader showBranding={showBranding} headerType="h2" />
                <Children>{children}</Children>
            </Container>
        </SignInViewContainer>
    );
}

SignInView.defaultProps = {
    displayName: "SignInView",
    phoneNumber: "",
};
