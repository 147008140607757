// external
import React, { useContext, useState, useEffect } from "react";

// type
import { SignUpChallenge } from "../../API";

// components
import Challenge from "../Challenge";

const STRINGS = {
    ID_TITLE: "Okay, enter your student ID.",
    ID_TEXT: (text: string) => text ? text.toUpperCase() : "STUDENT ID",
    ID_PLACEHOLDER: (min: SignUpChallengeInput["inputMinLength"]) =>
        `${Array.from(Array(min).keys()).join("")}`,
    ID_ERROR_TEXT: ( min: number, max: number) =>
        `Your student ID must be ${
            max === min ? max : `${min}-${max}`
        } characters long.`,
};

type Props = {
    submitChallenge: (code: string) => Promise<[boolean, boolean]>;
    challengeInfo: SignUpChallenge
};

export default function CampusIDChallenge({ submitChallenge, challengeInfo }: Props) {


    return (
        <Challenge
            submitChallenge={submitChallenge}
            challengeInfo={challengeInfo}
            title={STRINGS.ID_TITLE}
            label={STRINGS.ID_TEXT(challengeInfo?.inputName)}
            placeholder={STRINGS.ID_PLACEHOLDER(challengeInfo?.inputMinLength)}
            errorText={STRINGS.ID_ERROR_TEXT(
                          challengeInfo?.inputMinLength,
                          challengeInfo?.inputMaxLength
                      )}
        />
    );
}
