import React from "react";
import { View, ActivityIndicator } from "react-native";
import Text from "./Text";
import styled from "../styling/styled-components";

const Container = styled(View)`
    flex: 1;
    background-color: ${({ theme }) => theme.colors.SMOG};
    align-items: center;
    justify-content: center;
`;

type Props = {
    size?: string;
    headerText?: string;
    subHeaderText?: string;
};

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const HeaderText = styled(BlueText)`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
`;

const SubHeaderText = styled(BlueText)`
    padding-top: ${({ theme }) =>
        theme.isMobile ? theme.spacing.xsmall : theme.spacing.xsmall}px
    font-size: 18px;
`;
export default function SplashScreen({
    style,
    size,
    headerText,
    subHeaderText,
}: Props) {
    return (
        <Container>
            <ActivityIndicator size={size} style={style} />
            <HeaderText type="h1">{headerText}</HeaderText>
            <SubHeaderText type="b1">{subHeaderText}</SubHeaderText>
        </Container>
    );
}

SplashScreen.defaultProps = {
    size: "small",
};
