// external
import { Auth, API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import _ from "lodash";
import React, { useContext, useState, useEffect } from "react";
import { View } from "react-native";
import { StackActions } from "@react-navigation/native";
import { CognitoUser } from "amazon-cognito-identity-js";


// style
import styled from "../styling/styled-components";


// context
import CampusContext, { emptyCampusConfig } from "../contexts/CampusContext";
import GlobalContext from "../contexts/GlobalContext";


// API
import {
    CampusConfig,
    ChallengeType,
    NextSignUpChallenge,
    SignUpChallengeInput,
} from "../API";
import * as mutations from "../graphql/mutations";
import { useGetCampusConfig } from "../graphql/hooks/useGetCampusConfig";


// components
import AcceptTerms from "../components/AcceptTerms";
import CampusList from "../components/CampusList";
import CampusIDChallenge from "../components/auth/CampusIDChallenge";
import EmailChallenge from "../components/auth/EmailChallenge";
import LoadingOverlay from "../components/LoadingOverlay";
import SignInStepOne from "../components/SignInStepOne";
import SignInStepTwo from "../components/SignInStepTwo";
import SignInView from "../components/SignInView";
import SingleSignOn from "../components/SingleSignOn";
import SplashScreen from "../components/SplashScreen";


// utils
import CrashReport from "../utils/crashReport";

const StyledSignInView = styled(SignInView)``;

const SignInWrapper = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.huge}px;
    ${({ theme }) =>
        theme.isDesktop
            ? `
        margin: 0 auto;
        text-align: center;
    `
            : ""}
`;

const StyleCampusList = styled(CampusList)`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
    ${({ theme }) =>
        theme.isDesktop
            ? `
        padding-top: 60px;
        align-items: center;
        text-align: center;
    `
            : ""}
`;

interface AuthPersistenceInfo {
    phoneNumber: string;
    signUpInitiated: boolean;
    signUpData: NextSignUpChallenge;
    confirmationCode: string;
    errorEncountered: boolean;
}

export default function ThirdPartySignInScreen({ navigation, route }: props) {
    const {
      campusSlug,
      step = 1,
      signUpInfo = "{}",
      orgId = null,
      redirectUrl = "",
      externalId = "",
      name = ""
    } = route.params || {};


    const [authPersistenceInfo, setAuthPersistenceInfo] =
        useState<AuthPersistenceInfo>({
            phoneNumber: "",
            confirmationCode: "",
            signUpInitated: false,
            signUpData: {
                signUpComplete: false,
            },
            errorEncountered: false,
        });
    
    useEffect(() => {
        try {
            const bufferedSignUpData = JSON.parse(
                Buffer.from(signUpInfo, "base64").toString("ascii")
            );
            setAuthPersistenceInfo(bufferedSignUpData);
        } catch {}
    }, [signUpInfo]);

    // context
    const campusContext = useContext(CampusContext);
    const { organizationId, campusName, singleSignOnConfig } =
        campusContext.campusConfig;
    const globalContext = useContext(GlobalContext);


    // state
    // [TEC-1410] CongitoUser is not serializable so cannot be apart of authPersistenceInfo
    const [user, setUser] = useState<CognitoUser>();

    const calculateTotalSteps = () => {
        if (authPersistenceInfo?.signUpData?.countTotalSignUpChallenges) {
            return authPersistenceInfo?.signUpData.countTotalSignUpChallenges + 1;
        } else if (!campusSlug) {
            return 10;
        } else if (user) {
            return 3;
        } else {
            return 5;
        }
    };
    const totalSteps = calculateTotalSteps();

    const [
        getCampusConfig,
        _campusConfig,
        campusConfigLoading,
        campusConfigErrors,
    ] = useGetCampusConfig();

    useEffect(() => {
        if (
            (campusSlug && !campusContext.campusConfig.campusSlug) ||
            campusSlug !== campusContext.campusConfig.campusSlug
        ) {
            const lowerCampusSlug = campusSlug ? campusSlug.toLowerCase() : "";
            CrashReport.setCampus(lowerCampusSlug);
            if (campusSlug) {
                loadConfig(lowerCampusSlug, null);
            }
        }
    }, [campusSlug]);

    useEffect(() => {
        if (
            (orgId && !campusContext.campusConfig.organizationId) ||
            orgId !== campusContext.campusConfig.organizationId
        ) {
            const lowerOrgId = orgId ? orgId.toLowerCase() : "";
            CrashReport.setOrganization(lowerOrgId);
            if (orgId) {
                loadConfig(null, lowerOrgId);
            }
        }
    }, [orgId]);

    useEffect(() => {
        if(!!redirectUrl) {
            globalContext.updateSelections({
                selections: {
                    redirectOnSignInUrl: redirectUrl,
                    thirdPartyExternalId: externalId,
                    thirdPartyUsername: name,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (authPersistenceInfo?.signUpData?.reuserAuthToken && authPersistenceInfo?.signUpData.signUpComplete) {
            Auth.signIn(authPersistenceInfo.phoneNumber).then((user) => {
                Auth.sendCustomChallengeAnswer(user, "AUTHTOKEN").then((user) =>
                    Auth.sendCustomChallengeAnswer(
                        user,
                        authPersistenceInfo?.signUpData.reuserAuthToken
                    ).then((user) => finishSignIn())
                );
            });
        }
    }, [authPersistenceInfo?.signUpData, user]);

    const loadConfig = async (
        campusSlug?: string,
        organizationId?: string
    ): CampusConfig => {
        const config = await getCampusConfig({ campusSlug, organizationId });
        config && campusContext.setCampusConfig(config);
        if(!campusSlug) {
            navigation.setParams({ campusSlug: config.campusSlug });
        }
        return config;
    };

    const startSignIn = async (phoneNumber: string) =>
        await Auth.signIn(phoneNumber)
            .then((user) => Auth.sendCustomChallengeAnswer(user, "OTP"))
            .then((user) => {
                setUser(user);
                // [TEC-1410] cannot push route with goToNextStage because user
                // object is not serializable and navigation.push remounts the
                // component which means we loose the user object saved to state prior to
                navigation.setParams({
                    step: 2,
                    campusSlug,
                    signUpInfo: Buffer.from(
                        JSON.stringify({
                            phoneNumber,
                            initiateSignUp: false,
                        })
                    ).toString("base64"),
                });
            });

    const signInSubmit = async (
        response: string
    ): Promise<[boolean, boolean]> => {
        await Auth.sendCustomChallengeAnswer(user, response);
        try {
            const user = await Auth.currentAuthenticatedUser();
            return [true, false];
        } catch (err) {
            return [false, false];
        }
    };

    const restartAuth = () => {
        navigation.push("ThirdPartySignInScreen", {
            step: 1,
            signUpInfo: null,
            campusSlug,
            redirectUrl,
            externalId,
            name
        });
    };

    const goToNextStage = (
        authInfo: AuthPersistenceInfo,
        goToStep: int
    ): void => {
        const bufferedAuthInfo = Buffer.from(JSON.stringify(authInfo)).toString("base64");
        navigation.push("ThirdPartySignInScreen", {
            signUpInfo: bufferedAuthInfo,
            step: goToStep,
            campusSlug,
            redirectUrl,
            externalId,
            name
        });
    };

    const signUpSubmit = async (
        response: string, extraField: object = {}
    ): Promise<[boolean, boolean]> => {
        const challenge = authPersistenceInfo?.signUpData.nextChallenge;
        const confCode = authPersistenceInfo.confirmationCode || response;
        const phoneNumber = authPersistenceInfo?.phoneNumber;

        try {
            const responseData = await API.graphql({
                query: mutations.respondToSignUpChallenge,
                variables: {
                    input: {
                        challenge,
                        organizationId,
                        challengeResponse: response,
                        phoneNumber,
                        confirmationCode: confCode,
                        marketingOptIn: {
                            email: extraField?.marketingOptIn?.email || false,
                            phoneNumber: false,
                        },
                    },
                },
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
            });
            const newSignUpData =
                responseData.data.initiateSignUp ||
                responseData.data.respondToSignUpChallenge;

            goToNextStage(
                {
                    signUpInitiated: true,
                    signUpData: newSignUpData,
                    phoneNumber,
                    confirmationCode: confCode,
                },
                parseInt(step) + 1
            );
            return [true, true];
        } catch (err) {
            console.error(err);
            return [false, true];
        }
    };

    const startSignUp = async (phoneNumber: string) => {
        const signUpData = await API.graphql({
            query: mutations.initiateSignUp,
            variables: {
                input: { organizationId, phoneNumber, externalId },
            },
            authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        goToNextStage(
            {
                signUpInitiated: true,
                signUpData: signUpData.data.initiateSignUp,
                phoneNumber,
            },
            2
        );
    };

    const finishSignIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const extIdgqlRequest = {
                query: mutations.externalSignUpCheck,
                variables: {
                    input: {
                        referredOrganizationId: organizationId,
                        referredExternalId: externalId,
                    },
                },
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
            };
            let externAuthError = "";
            try {
              await API.graphql(extIdgqlRequest);
            } catch (err) {
                const handleAbleErrors = ['OrgMismatch', 'ExternalIdMismatch'];
                for(let i = 0; i < err.errors.length; i++) {
                    if(handleAbleErrors.includes(err.errors[i].errorType)) {
                        externAuthError = err.errors[i].errorType;
                    } else {
                        throw err;
                    }
                }
            }

            const authUserConfig = loadConfig(
                null,
                _.get(user, "attributes.custom:organizationId")
            );

            globalContext.updateSelections({
                selections: {
                    isAuthenticated: true,
                    phoneNumber: _.get(user, "attributes.phone_number"),
                    consumerId: _.get(user, "attributes.custom:consumerId"),
                    consumerAcceptedTermsAt: _.get(
                        user,
                        "attributes.custom:termsAcceptedAt"
                    ),
                    thirdPartyAuthError: externAuthError,
                },
            });
            CrashReport.setUser(_.get(user, "attributes.custom:consumerId"));
            CrashReport.setCampus(authUserConfig.campusSlug);
        } catch (err) {
            console.error("Error in finishSignIn", err);
        }
    };

    return (
        <StyledSignInView
            progress={step / (user ? totalSteps : totalSteps + 1)}
            showBranding={!!campusSlug && !campusConfigLoading}
        >
            <LoadingOverlay isVisible={campusConfigLoading} />
            {campusSlug && !campusConfigErrors ? (
                <SignInWrapper>
                    {(step == 1 ||
                        (step >= 2 && !(user || authPersistenceInfo?.signUpData))) &&
                        !campusConfigLoading && (
                            <SignInStepOne
                                startSignIn={startSignIn}
                                initPhoneNumber={authPersistenceInfo?.phoneNumber}
                                startSignUp={startSignUp}
                            />
                        )}
                    {step == 2 &&
                        (user ||
                            authPersistenceInfo?.signUpData?.nextChallenge?.challengeType ==
                                ChallengeType.Confirmphone) && (
                            <SignInStepTwo
                                phoneNumber={authPersistenceInfo?.phoneNumber}
                                submitCode={
                                    authPersistenceInfo.signUpInitiated
                                        ? signUpSubmit
                                        : signInSubmit
                                }
                                reInitCallback={
                                    authPersistenceInfo.signUpInitiated
                                        ? startSignUp
                                        : startSignIn
                                }
                                finishSignIn={finishSignIn}
                            />
                        )}
                    {authPersistenceInfo?.signUpData?.nextChallenge?.challengeType ==
                        ChallengeType.Email && (
                        <EmailChallenge
                            submitChallenge={signUpSubmit}
                            challengeInfo={
                                authPersistenceInfo.signUpData.nextChallenge
                            }
                        />
                    )}
                    {authPersistenceInfo?.signUpData?.nextChallenge?.challengeType ==
                        ChallengeType.Campusid && (
                        <CampusIDChallenge
                            submitChallenge={signUpSubmit}
                            challengeInfo={
                                authPersistenceInfo.signUpData.nextChallenge
                            }
                        />
                    )}
                    {authPersistenceInfo?.signUpData?.nextChallenge?.challengeType ==
                        ChallengeType.Sso &&
                        singleSignOnConfig && (
                            <SingleSignOn
                                challenge={authPersistenceInfo.signUpData.nextChallenge}
                                phoneNumber={authPersistenceInfo?.phoneNumber}
                                confirmationCode={authPersistenceInfo?.confirmationCode}
                                campusName={campusName}
                                campusSlug={campusSlug}
                                organizationId={organizationId}
                                singleSignOnConfig={singleSignOnConfig}
                                step={step}
                                errorEncountered={authPersistenceInfo?.errorEncountered}
                                errorPhoneNumber={
                                    campusContext.campusConfig
                                        .contactPhoneNumber
                                }
                                route={route}
                            />
                        )}
                    {authPersistenceInfo?.signUpData?.nextChallenge?.challengeType ==
                        ChallengeType.Terms && (
                        <AcceptTerms acceptTerms={signUpSubmit} authFailRetry={restartAuth}/>
                    )}
                    {authPersistenceInfo?.signUpData?.signUpComplete &&
                        authPersistenceInfo?.signUpData?.previousChallengeAccepted && (
                            <SplashScreen
                                size="large"
                                style={{
                                    transform: [{ scaleX: 2 }, { scaleY: 2 }],
                                    marginTop: "30vh",
                                }}
                                headerText="Hang tight!"
                                subHeaderText="Signing you into your account."
                            />
                        )}
                </SignInWrapper>
            ) : !orgId ? (
                <StyleCampusList />
            ) : (
                <></>
            )}
        </StyledSignInView>
    );
}
