import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogConfig, environment } from "../../configs.json";

const app = require("../../app.json");

// RUM source maps upload (see scripts/upload_sourcemaps.js) must match version here
// and uploads do not update if version does not change which would be a problem
// for staging -> production promotion scheme without a manufactured version diff here
const version =
    environment == "production" ? app.expo.version : `${app.expo.version}-rc`;

const RUMconfig = {
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    env: environment,
    site: "datadoghq.com",
    service: "campuspass",
    version,
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "mask-user-input",
};

const logsConfig = {
    clientToken: datadogConfig.clientToken,
    site: "datadoghq.com",
    env: environment,
    service: "campuspass",
    version,
    forwardConsoleLogs: ["info", "warn", "error"],
    forwardErrorsToLogs: true,
    sampleRate: 100,
};

export default {
    init: () => {
        datadogRum.init(RUMconfig);
        datadogRum.startSessionReplayRecording();
        datadogLogs.init(logsConfig);
    },
    setUser: (userId: string) => datadogRum.setUser({ id: userId }),
    clearUser: () => datadogRum.clearUser(),
    setCampus: (campusSlug: string) =>
        datadogRum.setGlobalContextProperty("campusConfig", {
            campusSlug,
        }),
    setOrganization: (organizationId: string) =>
        datadogRum.setGlobalContextProperty("campusConfig", {
            organizationId,
        }),
};
