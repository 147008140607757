import Activity from "./Activity";

export default function getSameDaySameTypeActivities(
    activities: Activity[],
    compare: Activity
): Activity[] {
    return activities.filter((activity) => sameDaySameType(activity, compare));
}

function sameDaySameType(activity: Activity, compare: Activity): boolean {
    return (
        activity.date.getFullYear() === compare.date.getFullYear() &&
        activity.date.getMonth() === compare.date.getMonth() &&
        activity.date.getDate() === compare.date.getDate() &&
        activity.name === compare.name
    );
}
