import React, { useContext } from "react";
import { View, Pressable } from "react-native";
import CampusContext from "../contexts/CampusContext";
import Text from "../components/Text";
import CampusLogo from "../components/CampusLogo.tsx";
import styled, { useTheme } from "../styling/styled-components";
import { openURL } from "../utils/helpers";
import { REUSEPASS_SITE_URL } from "../utils/constants";
import { ReusePassLogoDesktop, ReusePassLogoMobile } from "../assets/vectors";

const STRINGS = {
    HEADER: "ReusePass",
};

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 95px;
`;

const HeaderTitles = styled(View)``;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    font-family: DTGetaiGrotesk;
    letter-spacing: -2px;
    padding-bottom: ${({ theme }) => theme.spacing.tiny}px;
    font-size: ${({ theme }) => (theme.isDesktop ? 42 : 24)}px;
`;

const LogoWrapper = styled(View)``;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {
    // override for hiding hiding branding
    showBranding: boolean;
    headerType: string;
};

export default function CampusHeader({
    showBranding,
    headerType,
    style,
}: Props) {
    const { campusName, s3LogoKey } = useContext(CampusContext).campusConfig;
    const { isDesktop } = useTheme();

    return (
        <HeaderWrapper style={style}>
            <HeaderTitles>
                <Pressable onPress={() => openURL(REUSEPASS_SITE_URL)}>
                    <HeaderText type={headerType}>{STRINGS.HEADER}</HeaderText>
                </Pressable>
                <BlueText type={isDesktop ? "b1" : "b2"}>
                    {showBranding && campusName}
                </BlueText>
            </HeaderTitles>
            <LogoWrapper>
                {showBranding ? (
                    <CampusLogo
                        s3LogoKey={s3LogoKey}
                        width={isDesktop ? 125 : 75}
                        height={isDesktop ? 125 : 75}
                    />
                ) : isDesktop ? (
                    <ReusePassLogoDesktop />
                ) : (
                    <ReusePassLogoMobile />
                )}
            </LogoWrapper>
        </HeaderWrapper>
    );
}

CampusHeader.defaultProps = { showBranding: true, headerType: "h1" };
