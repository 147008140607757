import { API } from "aws-amplify";
import React from "react";

import MockRentals from "../mocks/rentals/MockRentals";
import * as queries from "../queries";

import { graphErrorDetails } from "../../utils/helpers";

import { environment } from "../../../configs.json";

export const useSearchRentals = () => {
    const [loading, setLoading] = React.useState(false);
    const [initiated, setInitiated] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const queryCallback = React.useCallback((variables) => {
        const load = async () => {
            setLoading(true);
            if (!variables.size) {
                variables.size = 50;
            }
            if (!variables.paginateFrom) {
                variables.paginateFrom = 0;
            }
            try {
                if (environment == "sandbox") {
                    // Use the locally mocked results
                    const mockRentals = new MockRentals();
                    const rentals = mockRentals.mockSearchRentals(variables);

                    setData(rentals);
                } else {
                    // Use the env driven backend

                    const rentalsResults = await API.graphql({
                        query: queries.searchRentals,
                        variables,
                    });

                    const rentals = rentalsResults?.data?.searchRentals;
                    setData(rentals);
                }
            } catch (error) {
                const [_errorType, errorMessage] = graphErrorDetails(
                    error?.errors
                );
                if (errorMessage == "Network Error") {
                    console.warn(
                        "Network Error for useSearchRentals request.",
                        error
                    );
                } else {
                    console.error("Error with useSearchRentals", error);
                }
                setErrors(error);
            } finally {
                setLoading(false);
                setInitiated(true);
            }
        };
        load();
    }, []);

    return [queryCallback, data || [], initiated, loading, errors];
};
