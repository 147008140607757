import {
    RentalPhase,
    RentalStatus,
    RentalSearchDocument,
    RentalSearchResults,
} from "../../API";

export function getDueSoon(
    rentals: RentalSearchResults
): RentalSearchDocument[] {
    return filterRentals(rentals, isDueSoon);
}

export function getPastDue(
    rentals: RentalSearchResults
): RentalSearchDocument[] {
    return filterRentals(rentals, isPastDue);
}

export function getLate(rentals: RentalSearchResults): RentalSearchDocument[] {
    return filterRentals(rentals, isLate);
}

export function getFailed(
    rentals: RentalSearchResults
): RentalSearchDocument[] {
    return filterRentals(rentals, isFailed);
}

export function getFined(rentals: RentalSearchResults): RentalSearchDocument[] {
    return filterRentals(rentals, isFined);
}

function filterRentals(
    rentals: RentalSearchResults,
    filterFunction: (rental: RentalSearchDocument) => boolean
): RentalSearchDocument[] {
    if (!rentals.rentals) {
        return [];
    }
    return rentals.rentals.filter((rental) => filterFunction(rental));
}

function isDueSoon(rental: RentalSearchDocument): boolean {
    return (
        rental.status == RentalStatus.InProgress &&
        rental.lastPhase == RentalPhase.Initiated
    );
}

function isPastDue(rental: RentalSearchDocument): boolean {
    return (
        rental.status == RentalStatus.InProgress &&
        rental.lastPhase == RentalPhase.LatePending
    );
}

function isLate(rental: RentalSearchDocument): boolean {
    return (
        rental.status == RentalStatus.InProgress &&
        rental.lastPhase == RentalPhase.Late
    );
}

function isFailed(rental: RentalSearchDocument): boolean {
    return (
        rental.status == RentalStatus.Complete &&
        rental.lastPhase == RentalPhase.Failed
    );
}

function isFined(rental: RentalSearchDocument): boolean {
    return (
        rental.status == RentalStatus.Complete &&
        rental.lastPhase == RentalPhase.Fined
    );
}
