import { API } from "aws-amplify";
import { environment } from "../../../configs.json";
import React from "react";
import MockRentals from "../mocks/rentals/MockRentals";
import * as queries from "../queries";
import { RentalStep } from "../../API";
import { graphErrorDetails } from "../../utils/helpers";

const filterForPhaseChangeRentalSteps = (
    steps: RentalStep[] | []
): RentalStep[] => {
    /* We just care about these types of Steps because the others are notifications
     * and not states that we care to present in this app.
     */
    return steps.filter(
        (step) => step.rentalStepConfigType == "PhaseChangeRentalStepConfig"
    );
};

export const useListRentalStepsForRental = () => {
    const [loading, setLoading] = React.useState(false);
    const [initiated, setInitiated] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const queryCallback = React.useCallback((rentalId: string) => {
        const load = async () => {
            setLoading(true);
            try {
                if (environment == "sandbox") {
                    // Use the locally mocked results
                    const mockRentals = new MockRentals();
                    const rentalSteps = filterForPhaseChangeRentalSteps(
                        mockRentals.mockListRentalStepsForRental(rentalId) || []
                    );
                    setData(rentalSteps);
                } else {
                    // Use the env driven backend
                    const results = await API.graphql({
                        query: queries.listRentalStepsForRental,
                        variables: { rentalId },
                    });
                    const rentalSteps = filterForPhaseChangeRentalSteps(
                        results?.data?.listRentalStepsForRental || []
                    );

                    setData(rentalSteps);
                }
            } catch (error) {
                const [_errorType, errorMessage] = graphErrorDetails(
                    error?.errors
                );
                if (errorMessage == "Network Error") {
                    console.warn(
                        "Network Error for useListRentalStepsForRental request.",
                        error
                    );
                } else {
                    console.error(
                        "Error with useListRentalStepsForRental",
                        error
                    );
                }
                setErrors(error);
            } finally {
                setLoading(false);
                setInitiated(true);
            }
        };
        load();
    }, []);

    return [queryCallback, data, initiated, loading, errors];
};
