import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { Clouds, GreenGlobe } from "../assets/vectors";
import styled from "../styling/styled-components";
import Text from "./Text";

const Container = styled(View)`
    display: flex;
    flex-direction: column;
    justfify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.small}px;
`;

const AssetContainer = styled(View)``;

const TextContent = styled(View)`
    display: flex;
    flex-direction: column;
    justfify-content: center;
    text-align: center;
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const Title = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.tiny}px;
    color: ${({ theme }) => theme.colors.ASH};
`;

const Description = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    color: ${({ theme }) => theme.colors.ASH};
`;

type Props = {
    description: string;
    displayName?: string;
    style?: StyleProp<ViewStyle>;
    type?: string;
    title?: string | null;
};

export default function EmptyPlaceholder({
    description,
    style,
    type = "clouds",
    title,
}: Props) {
    return (
        <Container
            style={style}
            accessible={true}
            accessibilityLabel="Empty Placeholder"
        >
            <AssetContainer accessible={true} accessibilityRole="image">
                {type == "clouds" ? <Clouds /> : <GreenGlobe />}
            </AssetContainer>
            <TextContent>
                {title ? <Title type="sh2">{title}</Title> : null}
                <Description type="b2">{description}</Description>
            </TextContent>
        </Container>
    );
}
