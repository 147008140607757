import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import styled from "../styling/styled-components";
import EmptyPlaceholder from "./EmptyPlaceholder";

const StyledEmptyPlaceholder = styled(EmptyPlaceholder)`
    margin-top: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const STRINGS = {
    FINE_TITLE: "No fines to report",
    DESCRIPTION: "You have no failed rentals.\nThank you for being awesome!",
};

type Props = {
    canCharge: boolean;
    displayName?: string;
    style?: StyleProp<ViewStyle>;
};

export default function NoFinePlaceholder({ canCharge, style }: Props) {
    const title = canCharge ? STRINGS.FINE_TITLE : null;

    return (
        <StyledEmptyPlaceholder
            description={STRINGS.DESCRIPTION}
            title={title}
            style={style}
            type="green"
        />
    );
}
