import React, { useContext, useEffect } from "react";
import { ActivityIndicator, View } from "react-native";
import Text from "../components/Text";
import GlobalContext from "../contexts/GlobalContext";
import CampusContext from "../contexts/CampusContext";
import ScreenView from "../components/ScreenView";
import styled from "../styling/styled-components";
import Calendar from "../components/Calendar";
import DueAssetCard from "../components/DueAssetCard";
import Error from "../components/Error";
import BackHeader from "../components/BackHeader";
import NavReturnLocations from "../components/NavReturnLocations";
import { useSearchRentals } from "../graphql/hooks/useSearchRentals";
import { RentalPhase, RentalStatus } from "../API";

const STRINGS = {
    HEADER: "Due soon",
    SUB_HEADER: (dueBackDays) =>
        `Containers are due back ${dueBackDays} days after checkout.`,
    BACK: "BACK",
};

const Container = styled(ScreenView)``;

const Body = styled(View)`
    max-width: ${({ theme }) => theme.maxBodyWidth}px;
`;

const StyledCalendar = styled(Calendar)``;

const StyledDueAssetCard = styled(DueAssetCard)`
    padding-bottom: ${({ theme }) => theme.spacing.huge}px;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.large : theme.spacing.medium}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export default function DueSoonScreen({ navigation }: props) {
    const { dueBackDays, returnLocationsURL } =
        useContext(CampusContext).campusConfig;
    const { consumerAcceptedTermsAt } =
        useContext(GlobalContext).globalSelections;

    const [
        searchRentals,
        rentals,
        rentalsInitiated,
        rentalsLoading,
        rentalsErrors,
    ] = useSearchRentals();

    useEffect(() => {
        const toTimestamp = new Date().toISOString();

        searchRentals({
            fromTimestamp: consumerAcceptedTermsAt,
            toTimestamp,
            rentalPhases: [RentalPhase.Initiated],
            rentalStatuses: [RentalStatus.InProgress],
        });
    }, []);

    return (
        <Container>
            <Body>
                <BackHeader
                    label={STRINGS.BACK}
                    onPress={() => navigation.navigate("OverviewScreen", {})}
                    headerRight={() => (
                        <NavReturnLocations
                            returnLocationsURL={returnLocationsURL}
                        />
                    )}
                />
                <HeaderWrapper>
                    <HeaderText type="h2">{STRINGS.HEADER}</HeaderText>
                    <BlueText type="b2">
                        {STRINGS.SUB_HEADER(dueBackDays)}
                    </BlueText>
                </HeaderWrapper>
                {rentalsLoading ? (
                    <ActivityIndicator />
                ) : rentalsErrors ? (
                    <Error />
                ) : (
                    <>
                        <StyledDueAssetCard rentals={rentals?.rentals || []} />
                        <StyledCalendar rentals={rentals?.rentals || []} />
                    </>
                )}
            </Body>
        </Container>
    );
}
