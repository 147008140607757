// react
import React, { useEffect, useContext, useState } from "react";
import { Pressable, View } from "react-native";

// style
import styled, { useTheme } from "../styling/styled-components";

// context
import CampusContext from "../contexts/CampusContext";
import GlobalContext from "../contexts/GlobalContext";

// api
import { RentalPhase, RentalStatus } from "../API";
import { useQueryLoops } from "../graphql/hooks/useQueryLoops";
import { useSearchRentals } from "../graphql/hooks/useSearchRentals";

// components
import Account from "../components/Account";
import BottomDrawer from "../components/BottomDrawer";
import CampusHeader from "../components/CampusHeader";
import DueSoonCountCard from "../components/overview/DueSoonCountCard";
import FailedCountCard from "../components/overview/FailedCountCard";
import NavReturnLocations from "../components/NavReturnLocations";
import NeedHelp from "../components/NeedHelp";
import PastDueCountCard from "../components/overview/PastDueCountCard";
import RecentActivityCard from "../components/overview/RecentActivityCard";
import ReusePass from "../components/ReusePass";
import ScreenView from "../components/ScreenView";
import Text from "../components/Text";
import ThirdPartyAuthError from "../components/overview/ThirdPartyAuthError";
import ThirdPartyRedirect from "../components/ThirdPartyRedirect";
import TrashStats from "../components/TrashStats";

// images
import { Clover } from "../assets/vectors";

const STRINGS = {
    OPEN_PASS: "Open pass",
};

const Container = styled(ScreenView)`
    background-color: ${({ theme }) => theme.colors.FROTH};
    color: ${({ theme }) => theme.colors.SMOG};
`;

const OpenPass = styled(Pressable)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.BLUE};
    padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
    padding-horizontal: ${({ theme }) => theme.spacing.small}px;
    border-radius: 100px;
    margin-right: ${({ theme }) => theme.spacing.medium}px;
`;

const ActionsWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
    justify-content: ${({ theme }) =>
        theme.isDesktop ? "flex-start" : "space-between"};
`;

const ActionDivider = styled(View)`
    width: ${({ theme }) => theme.spacing.xsmall}px;
`;

const CardsWrapper = styled(View)``;

const StyledTrashStats = styled(TrashStats)`
    margin-horizontal: -${({ theme }) => (theme.isDesktop ? theme.globalDesktopPadding : theme.globalMobilePadding)}px;
    margin-top: ${({ theme }) => theme.spacing.huge}px;
    padding-top: ${({ theme }) => theme.spacing.xxlarge}px;
`;

const OtherBodyWrapper = styled(View)`
    padding-top: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xxlarge : theme.spacing.large}px;
    flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
    justify-content: space-between;
`;

const StyledAccount = styled(Account)`
    padding-vertical: ${({ theme }) => theme.spacing.xlarge}px;
`;

const StyledNeedHelp = styled(NeedHelp)`
    padding-vertical: ${({ theme }) => theme.spacing.xlarge}px;
`;

const WhiteText = styled(Text)`
    color: ${({ theme }) => theme.colors.WHITE};
`;

export default function OverviewScreen({ navigation, route }: props) {
    const { directive } = route?.params || {};

    const { campusName, hidePass, returnLocationsURL } =
        useContext(CampusContext).campusConfig;

    const {
        consumerAcceptedTermsAt,
        redirectOnSignInUrl,
        thirdPartyAuthError,
    } = useContext(GlobalContext).globalSelections;

    const [
        queryLoops,
        loops,
        nextToken,
        loopsInitiated,
        loopsLoading,
        loopsErrors,
    ] = useQueryLoops();

    const [
        queryRentals,
        rentals,
        rentalsInitiated,
        rentalsLoading,
        rentalsErrors,
    ] = useSearchRentals();

    const [hideAuthError, setHideAuthError] = useState(false);

    const redirectUrlWithResponseParams =
        redirectOnSignInUrl.indexOf("?") == -1
            ? redirectOnSignInUrl + "?success=true"
            : redirectOnSignInUrl + "&success=true";

    useEffect(() => {
        if (!!redirectOnSignInUrl && !thirdPartyAuthError) {
            const timeout = setTimeout(() => {
                window.location.replace(redirectUrlWithResponseParams);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [redirectOnSignInUrl]);

    useEffect(() => {
        const extLinkParam = !!thirdPartyAuthError ? "false" : "true";
        navigation.setParams({ externalLinkSuccess: extLinkParam });
    }, [thirdPartyAuthError]);

    useEffect(() => {
        if (consumerAcceptedTermsAt) {
            queryLoops();
            const toTimestamp = new Date().toISOString();
            const fromTimestamp = new Date(
                consumerAcceptedTermsAt
            ).toISOString();
            queryRentals({ toTimestamp, fromTimestamp });
        }
    }, [consumerAcceptedTermsAt]);
    // consumerAcceptedTermsAt should only change one time when the page first loads
    // we use this instead of [] to handle when a newly created user is signed in

    return (
        <Container>
            <BottomDrawer
                isOpen={directive == "pass" && !hidePass}
                onExit={() => navigation.setParams({ directive: "" })}
            >
                <ReusePass />
            </BottomDrawer>
            <BottomDrawer
                isOpen={!!redirectOnSignInUrl && !thirdPartyAuthError}
                dialogStyle={{
                    justifyContent: "top",
                    minHeight: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <ThirdPartyRedirect
                    redirectUrl={redirectUrlWithResponseParams}
                />
            </BottomDrawer>
            <BottomDrawer
                isOpen={!!thirdPartyAuthError && !hideAuthError}
                dialogStyle={{
                    justifyContent: "top",
                    minHeight: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <ThirdPartyAuthError campusName={campusName} />
            </BottomDrawer>
            <CampusHeader />
            <ActionsWrapper>
                {!hidePass && (
                    <OpenPass
                        onPress={() =>
                            navigation.setParams({ directive: "pass" })
                        }
                    >
                        <Clover />
                        <ActionDivider />
                        <WhiteText type="h3">{STRINGS.OPEN_PASS}</WhiteText>
                    </OpenPass>
                )}
                <NavReturnLocations returnLocationsURL={returnLocationsURL} />
            </ActionsWrapper>
            <CardsWrapper>
                <DueSoonCountCard
                    rentals={rentals}
                    navigation={navigation}
                    rentalsInitiated={rentalsInitiated}
                />
                <PastDueCountCard
                    rentals={rentals}
                    navigation={navigation}
                    rentalsInitiated={rentalsInitiated}
                />
                <FailedCountCard
                    rentals={rentals}
                    navigation={navigation}
                    rentalsInitiated={rentalsInitiated}
                />
                <RecentActivityCard
                    loops={loops}
                    navigation={navigation}
                    loopsInitiated={loopsInitiated}
                />
            </CardsWrapper>
            <StyledTrashStats />
            <OtherBodyWrapper>
                <StyledAccount />
                <StyledNeedHelp />
            </OtherBodyWrapper>
        </Container>
    );
}
