import { genDateArray, randomToken } from "../../utils/helpers";
import { Loop, AssetType, Consumer, Location } from "../../API";

// Config settings
const DUE_DAYS = 3;
const OVERDUE_DAYS = 5;
const CHARGED_DAYS = 12;
const ASSET_TYPES: AssetType[] = [
    {
        assetTypeId: "1",
        name: "Loop Clamshell",
    },
    {
        assetTypeId: "2",
        name: "Loop Tumbler",
    },
];
const CONSUMER: Consumer = { consumerId: "test-consumer-id" };
const LOCATION_ONE: Location = {
    locationId: "test-location-1-id",
    name: "Test Location One",
};

// Dates
const TODAY = new Date();
const NEXT_TEN_DAYS = genDateArray(new Date(TODAY), 10);

const SIXTY_DAYS_AGO = new Date(TODAY);
SIXTY_DAYS_AGO.setDate(SIXTY_DAYS_AGO.getDate() - 60);
const LAST_SIXTY_DAYS = genDateArray(new Date(SIXTY_DAYS_AGO), 60);

const genLoop = (
    loopId: string,
    openedAt: Date,
    assetType: AssetType,
    consumer: Consumer,
    location: Location,
    closedAt?: Date
): Loop => {
    const assetId = randomToken();
    const DATES = genDateArray(new Date(openedAt), 20);
    const DUE_DATE = DATES[DUE_DAYS];
    const OVERDUE_DATE = DATES[OVERDUE_DAYS];
    const CHARGED_DATE = DATES[CHARGED_DAYS];

    return {
        loopId,
        asset: {
            assetId,
            assetType,
        },
        consumer,
        locations: {
            openedAt: location,
            closedAt: closedAt ? location : null,
        },
        openedAt: openedAt.toISOString(),
        closedAt: closedAt ? closedAt.toISOString() : null,
        loopStatus: closedAt ? "closed" : "open",
        updatedAt: closedAt ? closedAt.toISOString() : openedAt.toISOString(),
    };
};

export default {
    data: {
        queryLoops: {
            results: [
                // Due Soons
                genLoop(
                    "due-soonloop-1",
                    TODAY,
                    ASSET_TYPES[0],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "due-soon-loop-2",
                    TODAY,
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "due-soon-loop-3",
                    LAST_SIXTY_DAYS[58],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                //Lates
                genLoop(
                    "late-loop-1",
                    LAST_SIXTY_DAYS[56],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "late-loop-2",
                    LAST_SIXTY_DAYS[56],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "late-loop-3",
                    LAST_SIXTY_DAYS[57],
                    ASSET_TYPES[0],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                // Charges
                genLoop(
                    "charged-loop-1",
                    LAST_SIXTY_DAYS[52],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),

                genLoop(
                    "charged-loop-2",
                    LAST_SIXTY_DAYS[50],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "charged-loop-3",
                    LAST_SIXTY_DAYS[45],
                    ASSET_TYPES[1],
                    CONSUMER,
                    LOCATION_ONE,
                    null
                ),
                genLoop(
                    "charged-loop-4",
                    LAST_SIXTY_DAYS[15],
                    ASSET_TYPES[0],
                    CONSUMER,
                    LOCATION_ONE,
                    LAST_SIXTY_DAYS[45]
                ),
                // Other
                genLoop(
                    "other-loop-1",
                    LAST_SIXTY_DAYS[40],
                    ASSET_TYPES[0],
                    CONSUMER,
                    LOCATION_ONE,
                    LAST_SIXTY_DAYS[45]
                ),
            ],
            nextToken: null,
        },
    },
};
