import _ from "lodash";
import React, {
    useCallback,
    useRef,
    useState,
    useContext,
    useEffect,
} from "react";
import { FlatList, Pressable, View } from "react-native";
import styled, { useTheme } from "../styling/styled-components";
import CampusContext from "../contexts/CampusContext";
import Text from "../components/Text";
import {
    LeftChevron,
    RightChevron,
    YellowDot,
    YellowPlus,
} from "../assets/vectors";
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { genDateArray, nextDateDetails, randomToken } from "../utils/helpers";
import { RentalSearchDocument } from "../API";
import {
    groupByDueDateLocal,
    groupByAssetType,
} from "../utils/rentals/groupBy";

const STRINGS = {
    TODAY: "TODAY",
    TOMORROW: "TOMORROW",
    SELECTED_DATE: (date: Date, campusTimezone: string) =>
        `Due ${formatInTimeZone(date, campusTimezone, "iiii, MMMM d")}`,
    NOTHING_DUE: (isToday: bool) =>
        isToday ? "Nothing due today" : "Nothing due",
    LOCAL_TIME: "Due dates & times are shown in local time for your campus.",
    DUE_AT: (dueDateTime: Date, campusTimezone: string) =>
        dueDateTime &&
        `Due by ${formatInTimeZone(
            dueDateTime,
            campusTimezone,
            "h:mm aaa iiii, MMMM d"
        )}`,
};

const NavigationButtonWrapper = styled(Pressable)`
    position: absolute;
    height: 100%;
    width: 24px;
    border-radius: 33px;
    background-color: ${({ theme }) => theme.colors.WHITE}90;
    align-items: center;
    justify-content: center;
`;

const CalendarWrapper = styled(View)``;

const CardsWrapper = styled(View)``;

const DateWrapper = styled(Pressable)`
    justify-content: space-between";
    min-height: 96px;    
    border-bottom-width: 2px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    padding-horizontal: ${({ theme }) => theme.spacing.xsmall}px;
    border-color: ${({ isSelected, theme }) =>
        isSelected ? theme.colors.BLUE : theme.colors.SMOG};
`;

const DateCircle = styled(View)`
    flex; 3;
    width: 54px;
    height: 54px;
    border-radius: 26px;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    background-color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.BLUE : theme.colors.SMOG};
`;

const DateText = styled(Text)`
    color: ${({ theme, isSelected }) =>
        isSelected ? theme.colors.WHITE : theme.colors.DEEP_BLUE_SEA};
`;

const DateHeader = styled(View)`
    text-align: center;
    align-self: center;
    position: absolute;
    top: 0;
`;

const DateFooter = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    flex: 1;
`;

const SelectedBody = styled(View)`
    padding-vertical: ${({ theme }) => theme.spacing.large}px;
`;

const AssetTypeCard = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
    border-bottom-width: 2px;
    border-color: ${({ theme }) => theme.colors.SMOG};
`;

const DotsWrapper = styled(View)`
    flex-direction: row;
    align-items: center;
    align-self: center;
`;

const Dot = styled(View)`
    padding-right: 1px;
`;

const MonthWrapper = styled(View)`
    background-color: ${({ theme }) => theme.colors.SMOG};
    border-radius: 100px;
    padding: ${({ theme }) => theme.spacing.xsmall}px;
    text-align: center;
    align-self: flex-end;
`;

const NothingWrapper = styled(View)`
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.SMOG};
    border-radius: 2px;
    padding-vertical: ${({ theme }) => theme.spacing.large}px;
    margin-top: ${({ theme }) => theme.spacing.large}px;
    text-align: center;
`;

const LeftNavigationButtonWrapper = styled(NavigationButtonWrapper)`
    left: -20px;
`;
const RightNavigationButtonWrapper = styled(NavigationButtonWrapper)`
    right: -20px;
`;

const YellowText = styled(Text)`
    color: ${({ theme }) => theme.colors.YELLOW};
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const HurricaneText = styled(Text)`
    color: ${({ theme }) => theme.colors.HURRICANE};
`;

const LocalTimeText = styled(HurricaneText)`
    padding-top: 30px;
`;

const GalleryFlatList = styled(FlatList).attrs(() => ({}))``;

const NavigationButton = ({
    direction,
    onPress,
}: {
    direction: string;
    onPress: () => void;
}) => {
    const Wrapper =
        direction === "left"
            ? LeftNavigationButtonWrapper
            : RightNavigationButtonWrapper;
    return (
        <Wrapper onPress={onPress} direction={direction}>
            {direction === "left" ? <LeftChevron /> : <RightChevron />}
        </Wrapper>
    );
};

const Dots = ({ num }: { num: number }) => {
    const keyBase = randomToken();
    const withPlus = num > 7;
    const dots = [];
    let i = 1;
    while (i <= Math.min(7, num)) {
        dots.push(
            <Dot key={`${keyBase}${i}`}>
                <YellowDot />
            </Dot>
        );
        i = i + 1;
    }
    withPlus && dots.push(<YellowPlus key={`${keyBase}+`} />);

    return <DotsWrapper>{dots}</DotsWrapper>;
};

type Props = {
    displayName?: string;
    rentals: RentalSearchDocument[];
};

export default function Calendar({ style, rentals }: Props) {
    const [groupedRentals, setGroupedRentals] = useState({});
    const [firstVisibleIndex, setFirstVisibleIndex] = useState(0);
    const [lastVisibleIndex, setLastVisibleIndex] = useState(0);
    const [selectedDate, setSelectedDate] = useState();
    const [today, setToday] = useState();
    const [tomorrow, setTomorrow] = useState();
    const [dates, setDates] = useState([]);

    const list = useRef(null);

    const { isMobile } = useTheme();
    const { dueBackDays, localTimezone } =
        useContext(CampusContext).campusConfig;

    useEffect(() => {
        const theseGroupedRentals = groupByDueDateLocal(rentals, localTimezone);
        const [isToday, isTomorrow, firstDate] = nextDateDetails(
            Object.keys(theseGroupedRentals)
        );
        const datesArray = genDateArray(
            new Date(new Date().toLocaleDateString("en-US")),
            7
        );
        setDates(datesArray);
        setSelectedDate(datesArray[0]);
        setToday(datesArray[0]);
        setTomorrow(datesArray[1]);
        setGroupedRentals(theseGroupedRentals);
    }, [rentals, dueBackDays]);

    const onViewableItemsChanged = useCallback(({ viewableItems }) => {
        const firstItem = viewableItems[0];
        const lastItem = viewableItems[viewableItems.length - 1];
        if (!firstItem || !lastItem) return;
        setFirstVisibleIndex(firstItem.index);
        setLastVisibleIndex(lastItem.index);
    }, []);

    const itemNavigation = (offset) => {
        const index =
            (offset > 0 ? firstVisibleIndex : lastVisibleIndex) + offset;

        setFirstVisibleIndex(index);
        setLastVisibleIndex(index);

        const snapToFirst = firstVisibleIndex < 3 && offset < 0;

        snapToFirst
            ? list.current.scrollToOffset({ animated: true, offset: 0 })
            : list.current.scrollToIndex({
                  animated: true,
                  index: index,
                  viewPosition: offset > 0 ? 0 : 1,
              });
    };

    const renderDate = ({ item }) => {
        const date = item;
        const isSelected = item.getTime() == selectedDate.getTime();
        const isToday = item == today;
        const isTomorrow = item == tomorrow;
        return (
            <DateWrapper
                isSelected={isSelected}
                onPress={() => setSelectedDate(item)}
            >
                <DateHeader>
                    <YellowText type="eyebrow">
                        {isToday && isSelected
                            ? STRINGS.TODAY
                            : isTomorrow && isSelected
                            ? STRINGS.TOMORROW
                            : null}
                    </YellowText>
                </DateHeader>
                <DateCircle isSelected={isSelected}>
                    <DateText type="eyebrow" isSelected={isSelected}>
                        {format(date, "eee")}
                    </DateText>
                    <DateText type="sh1" isSelected={isSelected}>
                        {format(date, "d")}
                    </DateText>
                </DateCircle>
                <DateFooter>
                    <Dots
                        num={
                            _.get(
                                groupedRentals,
                                date.toLocaleDateString("en-US"),
                                []
                            ).length
                        }
                    />
                </DateFooter>
            </DateWrapper>
        );
    };

    const renderAssetTypes = (date: Date) => {
        const dateRentals = _.get(
            groupedRentals,
            date.toLocaleDateString("en-US"),
            []
        );

        const assetGrouped = groupByAssetType(dateRentals);
        const assetTypes = Object.keys(assetGrouped);

        return (
            <>
                {assetTypes.length > 0 ? (
                    <CardsWrapper>
                        {assetTypes.map((assetType, idx) => (
                            <AssetTypeCard key={`${assetType}-${idx}`}>
                                <BlueText type="sh1">{assetType}</BlueText>
                                <BlueText type="h2">
                                    {assetGrouped[assetType].length}
                                </BlueText>
                            </AssetTypeCard>
                        ))}
                        <LocalTimeText type="b1">
                            {STRINGS.LOCAL_TIME}
                        </LocalTimeText>
                    </CardsWrapper>
                ) : (
                    <NothingWrapper>
                        <HurricaneText type="b1">
                            {STRINGS.NOTHING_DUE(today == selectedDate)}
                        </HurricaneText>
                    </NothingWrapper>
                )}
            </>
        );
    };

    const renderDueDate = (date: Date): string => {
        const dateRentals = _.get(
            groupedRentals,
            date.toLocaleDateString("en-US"),
            []
        );
        return dateRentals.length > 0
            ? STRINGS.DUE_AT(new Date(dateRentals[0].dueAt), localTimezone)
            : STRINGS.SELECTED_DATE(date, localTimezone);
    };

    return (
        <View style={style}>
            {selectedDate && (
                <MonthWrapper>
                    <BlueText type="b2">
                        {formatInTimeZone(
                            selectedDate,
                            localTimezone,
                            "MMM yyyy"
                        )}
                    </BlueText>
                </MonthWrapper>
            )}
            <CalendarWrapper>
                <GalleryFlatList
                    ref={list}
                    contentContainerStyle={{ flex: 1 }}
                    onViewableItemsChanged={onViewableItemsChanged}
                    showsHorizontalScrollIndicator={false}
                    data={dates}
                    renderItem={renderDate}
                    keyExtractor={(item) => item}
                    horizontal
                />
                {firstVisibleIndex > 0 && (
                    <NavigationButton
                        direction="left"
                        onPress={() => itemNavigation(-1)}
                    />
                )}
                {lastVisibleIndex < dates.length - 1 && (
                    <NavigationButton
                        direction="right"
                        onPress={() => itemNavigation(1)}
                    />
                )}
            </CalendarWrapper>
            {selectedDate && (
                <SelectedBody>
                    <BlueText type="b2">{renderDueDate(selectedDate)}</BlueText>
                    {renderAssetTypes(selectedDate)}
                </SelectedBody>
            )}
        </View>
    );
}

Calendar.defaultProps = {
    displayName: "Calendar",
};
