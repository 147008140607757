// react
import _ from "lodash";
import React, { useContext } from "react";

// style
import styled from "../styling/styled-components";

//context
import CampusContext from "../contexts/CampusContext";

// components
import { View, FlatList } from "react-native";
import RecentActivityPlaceholder from "../components/RecentActivityPlaceholder";
import Text from "../components/Text";

//API
import { Loop } from "../API";

//utils
import { formatInTimeZone } from "date-fns-tz";
import { LoopStatus } from "../API";
import { assetTypeName } from "../utils/loopState";
import Activity from "../utils/activities/Activity";

//assets
import { CheckCircle } from "../assets/vectors";

const STRINGS = {
    ACTIVITY: "ACTIVITY",
    RETURNED: "Received",
    CHECKED_OUT: "Checked-out",
    NUM_ITEMS: (num: number) => `${num} ITEMS`,
};

const Container = styled(View)``;

const ListHeader = styled(View)`
    margin-vertical: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.SUMMER_STORM};
`;

const CardWrapper = styled(View)`
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
    border-bottom-width: 1px;
    border-color: ${({ theme }) => theme.colors.SMOG};
`;

const CardLeft = styled(View)`
    flex: 1;
`;

const CardMiddle = styled(View)`
    flex: 2;
    align-items: left;
    text-align: left;
`;

const CardRight = styled(View)`
    flex: 2;
    align-items: flex-end;
`;

const BadgeWrapper = styled(View)`
    border-radius: 100px;
    background-color: ${({ theme }) => theme.colors.SMOG};
    padding-vertical: ${({ theme }) => theme.spacing.xsmall}px;
    padding-horizontal: ${({ theme }) => theme.spacing.xsmall}px;
    text-align: center;
    align-self: flex-end;
`;

const ReturnedWrapper = styled(BadgeWrapper)`
    background-color: ${({ theme }) => theme.colors.GREEN_4};
    flex-direction: row;
    justify-content: space-between;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const GreenText = styled(Text)`
    color: ${({ theme }) => theme.colors.GREEN_1};
`;

const ExhaustText = styled(Text)`
    color: ${({ theme }) => theme.colors.EXHAUST};
`;

const AshText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const ReturnedText = styled(GreenText)`
    padding-left: 6px;
`;

const BADGES = {
    CHECKED_OUT: (
        <BadgeWrapper>
            <AshText type="b2">{STRINGS.CHECKED_OUT} </AshText>
        </BadgeWrapper>
    ),
    RETURNED: (
        <ReturnedWrapper>
            <CheckCircle />
            <ReturnedText type="b2">{STRINGS.RETURNED} </ReturnedText>
        </ReturnedWrapper>
    ),
};

const loopToActivity = (
    loop: Loop,
    canCharge: boolean,
    localTimezone?: string
): Activity[] => {
    const activity = [];

    // all Loops have an opened at event
    activity.push({
        date: new Date(loop?.openedAt),
        name: STRINGS.CHECKED_OUT,
        header: assetTypeName(loop.asset),
        subHeader: loop?.locations?.openedAt.name,
        badge: BADGES.CHECKED_OUT,
        key: `${loop.loopId}opened`,
    });

    if (loop.loopStatus == LoopStatus.Closed) {
        activity.push({
            date: new Date(loop?.closedAt),
            name: STRINGS.RETURNED,
            header: assetTypeName(loop.asset),
            subHeader: null,
            badge: BADGES.RETURNED,
            key: `${loop.loopId}closed`,
        });
    }

    return activity;
};

export const loopsToActivities = (
    loops: Loop[],
    canCharge?: boolean,
    localTimezone?: string
): Activity[] => {
    const loopActivity = _.flatten(
        loops.map((loop) => loopToActivity(loop, canCharge, localTimezone))
    ).sort((a, b) => b.date - a.date);

    return loopActivity;
};

const Card = ({
    activity,
    campusTimezone,
}: {
    activity: Activity;
    campusTimezone: string;
}) => {
    return (
        <CardWrapper>
            <CardLeft>
                <BlueText type="b2">
                    {formatInTimeZone(activity.date, campusTimezone, "M/d")}
                </BlueText>
            </CardLeft>
            <CardMiddle>
                <BlueText type="sh2">{activity.header}</BlueText>
                {activity.subHeader && (
                    <AshText type="b2">{activity.subHeader}</AshText>
                )}
            </CardMiddle>
            <CardRight>{activity.badge}</CardRight>
        </CardWrapper>
    );
};

type Props = {
    loops: Loop[];
    initiated: boolean;
};

export default function ActivityLog({ loops, initiated, style }: Props) {
    const { canCharge, localTimezone } = useContext(CampusContext).campusConfig;
    const loopActivity = loopsToActivities(loops, canCharge, localTimezone);

    const renderCard = ({ item }) => {
        return (
            <Card
                activity={item}
                campusTimezone={localTimezone}
                canCharge={canCharge}
            />
        );
    };

    return (
        <Container style={style}>
            <FlatList
                data={loopActivity}
                ListHeaderComponent={
                    <ListHeader>
                        <ExhaustText type="eyebrow">
                            {STRINGS.ACTIVITY}
                        </ExhaustText>
                        <ExhaustText type="eyebrow">
                            {STRINGS.NUM_ITEMS(loopActivity.length)}
                        </ExhaustText>
                    </ListHeader>
                }
                ListEmptyComponent={initiated && <RecentActivityPlaceholder />}
                renderItem={renderCard}
                showsVerticalScrollIndicator={false}
                initialNumToRender={20}
                keyExtractor={(item) => item?.key}
            />
        </Container>
    );
}

ActivityLog.defaultProps = {};
