// react
import React from "react";

//style
import styled, { useTheme } from "../styling/styled-components";
import { TEXT_STYLES } from "../styling";
import { TextProps } from "./Themed";

// components
import { Text, TextStyle } from "react-native";


export const BlueText = styled(MyText)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {
    type?: keyof typeof TEXT_STYLES.mobile & keyof typeof TEXT_STYLES.desktop;
    style?: TextStyle;
    children?: JSX.Element | string | string[];
    onPress?: () => void;
};

export default function MyText({
    type,
    style,
    children,
    onPress,
}: Props & TextProps) {
    const { isMobile, textStyles } = useTheme();

    const typeClass = isMobile ? "mobile" : "desktop";

    const typeStyle = textStyles[typeClass][type];

    return (
        <Text onPress={onPress && onPress} style={[typeStyle, style]}>
            {children}
        </Text>
    );
}

MyText.defaultProps = {
    type: "b2",
    style: null,
    children: null,
};

