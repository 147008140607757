import React from "react";
import { View, Image } from "react-native";
import Text from "../components/Text";
import styled from "../styling/styled-components";
import tearJug from "../assets/images/tearJug.png";

const STRINGS = {
    PROMPT: "Oops. Something went wrong. Check your internet and try again.",
};

const Container = styled(View)`
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: ${({ theme }) => theme.spacing.large}px;
`;

const JugWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
`;

const BlueText = styled(Text)`
    max-width: 360px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export default function Error({ style }: Props) {
    return (
        <Container style={style}>
            <JugWrapper>
                <Image
                    source={tearJug}
                    resizeMode="contain"
                    style={{
                        width: 100,
                        height: 100,
                    }}
                />
            </JugWrapper>
            <BlueText type="sh1">{STRINGS.PROMPT}</BlueText>
        </Container>
    );
}

Error.defaultProps = {};
