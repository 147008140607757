import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { environment } from "../../../configs.json";
import React from "react";
import queryLoops from "../mocks/queryLoops";
import * as queries from "../queries";
import { LoopStatus } from "../../API";
import { graphErrorDetails } from "../../utils/helpers";

const getDataObject = (data: GraphQLResult) =>
    data ? Object.values(data.data)[0] : data; // this is to get to the meaty data and avoid having to destructure for each query based on its name

export const useQueryLoops = () => {
    const [loading, setLoading] = React.useState(false);
    const [initiated, setInitiated] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const queryCallback = React.useCallback((variables) => {
        const load = async () => {
            setLoading(true);
            try {
                if (environment == "sandbox") {
                    let results = queryLoops;
                    if (variables?.loopStatus == LoopStatus.Open) {
                        results = results;
                    } else if (variables?.loopStatus == LoopStatus.Closed) {
                        results = results;
                    }

                    setData(results);
                } else {
                    const results = await API.graphql({
                        query: queries.queryLoops,
                        variables,
                    });
                    setData(results);
                }
            } catch (error) {
                const [_errorType, errorMessage] = graphErrorDetails(
                    error?.errors
                );
                if (errorMessage == "Network Error") {
                    console.warn(
                        "Network Error for useQueryLoops request.",
                        error
                    );
                } else {
                    console.error("Error with useQueryLoops", error);
                }
                setErrors(error);
            } finally {
                setLoading(false);
                setInitiated(true);
            }
        };
        load();
    }, []);

    return [
        queryCallback,
        getDataObject(data)?.results || [],
        getDataObject(data)?.nextToken,
        initiated,
        loading,
        errors,
    ];
};
