export const CAMPUS_CONFIGS = [
    {
        campusSlug: "faber",
        organizationId: "5d63cdcdbcf9",
        campusName: "Faber College",
        contactPhoneNumber: "+13105659196",
        s3LogoKey: "faber/logo.svg",
        canSignUp: true,
        canCharge: true,
        localTimezone: "America/Los_Angeles",
        dueBackDays: 3,
        pendingPeriod: "3-days",
        programDetailsURL: "https://reusepass.com",
        returnLocationsURL: "https://google.com",
        lateCopy:
            "You have 48 hours to return late containers before your dining account is subject to an $8.00 charge.   Check your dining account for final charges.",
        returnLocations: [
            { locationId: "location-1", name: "Runk Dining Hall" },
            { locationId: "location-2", name: "Old Dorms First Floor" },
        ],
    },
    {
        campusSlug: "reuse",
        organizationId: "faber-org-id",
        campusName: "Reuse Revolution",
        contactPhoneNumber: "+13105659196",
        s3LogoKey: "faber/logo.svg",
        canSignUp: false,
        canCharge: true,
        localTimezone: "America/Los_Angeles",
        signUpPrompt:
            "Please make sure you have opted-in to the Campus Reuse Program on the Grubhub Dining app and then try again.",
        signUpLink: "https://google.com",
        dueBackDays: 3,
        pendingPeriod: "3-days",
        programDetailsURL: "https://reusepass.com",
        returnLocationsURL: "https://google.com",
        lateCopy:
            "You have 48 hours to return late containers before your dining account is subject to an $8.00 charge.   Check your dining account for final charges.",
        returnLocations: [
            { locationId: "location-1", name: "Runk Dining Hall" },
            { locationId: "location-2", name: "Old Dorms First Floor" },
        ],
    },
];

export const getCampusConfig = {
    data: {
        getCampusConfig: CAMPUS_CONFIGS[0],
    },
};

export const listCampusConfigs = {
    data: {
        getCampusConfig: {
            results: CAMPUS_CONFIGS,
            nextToken: null,
        },
    },
};
