import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { environment } from "../../../configs.json";
import React from "react";
import Mocks from "../mocks";
const configs = require("../../../configs.json");
import { graphErrorDetails } from "../../utils/helpers";

const getDataObject = (data: GraphQLResult) =>
    data ? Object.values(data.data)[0] : data; // this is to get to the meaty data and avoid having to destructure for each query based on its name

export const useQuery = (
    query: string,
    authMode: string = configs.awsConfig.API.aws_appsync_authenticationType
): [any | null, boolean, object[] | null] => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const queryCallback = React.useCallback(
        (variables) => {
            const load = async () => {
                setLoading(true);
                try {
                    if (environment == "sandbox") {
                        const results = Mocks[query];
                        setData(results);
                    } else {
                        const results = await API.graphql({
                            query,
                            variables,
                            authMode,
                        });
                        setData(results);
                    }
                } catch (error) {
                    const [_errorType, errorMessage] = graphErrorDetails(
                        error?.errors
                    );
                    if (errorMessage == "Network Error") {
                        console.warn(
                            "Network Error for useQuery request.",
                            query,
                            error
                        );
                    } else {
                        console.error(
                            "useQuery error",
                            query,
                            JSON.stringify(error)
                        );
                    }
                    setErrors(error);
                } finally {
                    setLoading(false);
                }
            };
            load();
        },
        [query]
    );

    return [queryCallback, getDataObject(data), loading, errors];
};
