// react
import React from "react";

// style
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";

// components
import Text from "../components/Text";

const PillText = styled(Text)`
    color: color;
    border-radius: 100px;
    min-width: ${({ theme: { isMobile } }) => (isMobile ? 70 : 80)}px;
    padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
    padding-horizontal: ${({ theme }) => theme.spacing.xsmall}px;
    width: fit-content;
    text-align: center;
`;

type Props = {
    children?: JSX.Element | string | string[];
    backgroundColor: string;
    textColor: string;
    fontType: string;
};

export function GreenPill({ children, fontType }) {
    const { colors } = useTheme();
    return (
        <Pill
            textColor={colors.GREEN_1}
            backgroundColor={colors.GREEN_4}
            fontType={fontType}
        >
            {children}
        </Pill>
    );
}

export function GreyPill({ children, fontType }) {
    const { colors } = useTheme();
    return (
        <Pill
            textColor={colors.ASH}
            backgroundColor={colors.SMOG}
            fontType={fontType}
        >
            {children}
        </Pill>
    );
}

export function RedPill({ children, fontType }) {
    const { colors } = useTheme();
    return (
        <Pill
            textColor={colors.RED_1}
            backgroundColor={colors.RED_4}
            fontType={fontType}
        >
            {children}
        </Pill>
    );
}

export function YellowPill({ children, fontType }) {
    const { colors } = useTheme();
    return (
        <Pill
            textColor={colors.YELLOW_1}
            backgroundColor={colors.YELLOW_4}
            fontType={fontType}
        >
            {children}
        </Pill>
    );
}

export default function Pill({
    children,
    backgroundColor,
    textColor,
    fontType,
    style = {},
}: Props) {
    const { colors } = useTheme();

    return (
        <PillText
            style={[
                { color: textColor, backgroundColor: backgroundColor },
                style,
            ]}
            type={fontType}
        >
            {children}
        </PillText>
    );
}
