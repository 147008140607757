import React, { useEffect, useState, useContext } from "react";
import { ActivityIndicator, View } from "react-native";
import styled, { css, useTheme } from "../styling/styled-components";
import { useNavigation } from "@react-navigation/native";
import GlobalContext from "../contexts/GlobalContext";
import Text from "../components/Text";
import { Checkmark } from "../assets/vectors";
import { COLORS } from "../styling";
import SignInInput from "./LoginTextInput";
import { prettifyPhoneNumber } from "../utils/helpers";

const LAST_N_DIGIT = 4; // how many digit of the phone number will be shown.
const DISABLE_RESEND_FOR_N_S = 10;
const CODE_LENGTH = 6;
const MAX_RETRIES = 3;

const STRINGS = {
    TITLE: "Enter the 6-digit code we just texted to you.",
    TEXT: (number = "") => `6-DIGIT PIN SENT TO ${prettifyPhoneNumber(number)}`,
    RESEND: "Resend code",
    FEEDBACK: "New code sent",
    FEEDBACK_ERROR: "Something went wrong. Please try again.",
    CODE_NOT_MATCH: "Sorry, that's not the correct 6-digit code",
    CODE_NOT_MATCH_HELP:
        "Please enter the 6-digit code we sent to your phone number or send a new code.",
    ATTEMPTS_LEFT: (triesLeft: number) => `${triesLeft} attempts remaining`,
};

const Container = styled(View)``;

const Prompt = styled(View)``;

const ResendContainer = styled(View)`
    flex-direction: row;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    margin-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
    margin: ${({ theme }) =>
        `${theme.spacing.medium}px auto ${theme.spacing.xxlarge}px`}
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    justify-content: flex-start;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            align-self: bottom;
        `}
`;

const ResentConfirmationText = styled(Text)`
    flex-direction: row;
    margin-horizontal: ${({ theme }) => theme.spacing.small}px;
    color: ${({ theme }) => theme.colors.GOOD_GREEN};
`;

const CodeInput = styled(SignInInput)`
    margin-top: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.yuge : theme.spacing.huge}px;
    color: ${({ theme }) => theme.colors.GOOD_GREEN};
`;

const CodeError = styled(Text)`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ErrorText = styled(Text)`
    color: ${({ theme }) => theme.colors.YELLOW};
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            max-width: 315px;
            text-align: center;
            align-self: center;
        `}
`;

const ResendLink = styled(Text)``;

type Props = {
    phoneNumber: string;
    submitCode: (code: string) => Promise<[boolean, boolean]>;
    reInitCallback: (username: string) => void;
    finishSignIn: () => void;
    displayName?: string;
};

export default function SignInStepTwo({
    phoneNumber,
    submitCode,
    reInitCallback,
    finishSignIn,
    style,
}: Props) {
    const theme = useTheme();
    const [title, setTitle] = useState(STRINGS.TITLE);
    const [loading, setLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isResendEnabled, setResendEnabled] = useState(true);
    const [failedAttemptCount, setFailedAttemptCount] = useState(0);
    const [codeError, setCodeError] = useState(false);
    const [codeSuccess, setCodeSuccess] = useState(false);

    const context = useContext(GlobalContext);
    const navigation = useNavigation();

    useEffect(() => {
        setCodeError(false);
        const timer = setTimeout(
            () => setResendEnabled(true),
            DISABLE_RESEND_FOR_N_S * 1000
        );
        return () => clearTimeout(timer);
    }, [isResendEnabled]);

    useEffect(() => {
        if (confirmationCode.length == CODE_LENGTH) {
            checkCode(confirmationCode);
        }
    }, [confirmationCode]);

    const getColor = () => {
        if (codeError) return theme.colors.ROJO;
        if (codeSuccess) return theme.colors.GOOD_GREEN;
        return null;
    };

    const resendCode = async () => {
        setTitle(STRINGS.TITLE);
        setResendEnabled(false);
        try {
            await reInitCallback(phoneNumber);
            setCodeError(false);
            setConfirmationCode("");
        } catch (err) {
            setTitle(STRINGS.FEEDBACK_ERROR);
        }
    };

    const checkCode = async (code: string) => {
        setCodeError(false);
        setLoading(true);
        try {
            const [codeValid, signUpProcess] = await submitCode(code);
            setLoading(false);
            if (codeValid) {
                if (!signUpProcess) {
                    finishSignIn();
                } else {
                    navigation.setParams({ step: 3 });
                }
            } else {
                setCodeError(true);
                setFailedAttemptCount(failedAttemptCount + 1);
                setTitle(STRINGS.CODE_NOT_MATCH);
                if (failedAttemptCount + 1 == MAX_RETRIES) {
                    resendCode();
                    setFailedAttemptCount(0);
                }
            }
        } catch (err) {
            if (err.message) {
                if (err.message == "Invalid session for the user.") {
                    navigation.setParams({
                        step: 1,
                        errorMessage:
                            "Sorry, your session expired. Please try again.",
                    });
                } else if (err.message == "Network error") {
                    navigation.setParams({
                        step: 1,
                        errorMessage:
                            "Sorry, there was a network error. Please try again.",
                    });
                } else {
                    throw err;
                }
            } else {
                throw err;
            }
        }
    };

    return (
        <Container style={style}>
            <Prompt>
                <BlueText type="h1">{title}</BlueText>
            </Prompt>
            <CodeInput
                color={getColor()}
                labelColor={theme.colors.HURRICANE}
                length={CODE_LENGTH}
                value={confirmationCode}
                label={STRINGS.TEXT(phoneNumber)}
                onChange={(code) => {
                    setConfirmationCode(code);
                }}
                type="otp"
            />
            {failedAttemptCount > 0 && (
                <>
                    codeError &&
                    <ErrorText type="h3">
                        {STRINGS.CODE_NOT_MATCH_HELP}
                    </ErrorText>
                    <CodeError type="b2">
                        {STRINGS.ATTEMPTS_LEFT(
                            Math.max(MAX_RETRIES - failedAttemptCount, 0)
                        )}
                    </CodeError>
                </>
            )}
            <ResendContainer>
                {loading && <ActivityIndicator />}
                {!isResendEnabled && (
                    <>
                        <Checkmark color={COLORS.GOOD_GREEN} />
                        <ResentConfirmationText>
                            {STRINGS.FEEDBACK}
                        </ResentConfirmationText>
                    </>
                )}
                {isResendEnabled && (
                    <ResendLink
                        type="link"
                        onPress={isResendEnabled ? resendCode : null}
                    >
                        {STRINGS.RESEND}
                    </ResendLink>
                )}
            </ResendContainer>
        </Container>
    );
}

SignInStepTwo.defaultProps = {
    displayName: "SignInStepTwo",
};
