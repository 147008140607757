import React, { useEffect } from "react";
import { ActivityIndicator, ImageBackground, View } from "react-native";
import Text from "../components/Text";
import ScreenView from "../components/ScreenView";
import styled, { css, useTheme } from "../styling/styled-components";
import BackHeader from "../components/BackHeader";
import Error from "../components/Error";
import { useQueryLoops } from "../graphql/hooks/useQueryLoops";
import ActivityLog from "../components/ActivityLog";
import DialChart from "../components/DialChart";
import { useQuery } from "../graphql/hooks/query";
import * as queries from "../graphql/queries";

const STRINGS = {
    HEADER: "Your activity",
    BACK: "BACK",
};

const Container = styled(ScreenView)``;

const Body = styled(View)``;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const StyledActivityLog = styled(ActivityLog)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            min-width: 395px;
            margin-right: 80px;
            flex: 1;
        `}
`;

const LoadingErrorWrapper = styled(View)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            min-width: 600px;
            min-height: 600px;
            margin-right: 148px;
        `}
`;

const ContentWrapper = styled(View)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            flex-direction: row-reverse;
            width: 100%;
            justify-content: space-between;
        `}
`;

const BannerWrapper = styled.View`
    height: 44px;
    width: 100%;
    margin: ${({ theme }) => theme.spacing.small}px 0px;
`;

export default function ActivityScreen({ navigation }: props) {
    const [
        queryLoops,
        loops,
        nextToken,
        loopsInitiated,
        loopsLoading,
        loopsErrors,
    ] = useQueryLoops();

    const { isDesktop } = useTheme();

    useEffect(() => {
        queryLoops();
    }, []);

    const [getConsumerStats, data, loading, error] = useQuery(
        queries.getConsumerStats
    );

    useEffect(() => {
        getConsumerStats();
    }, []);

    const { totalClosedLoops, totalOpenLoops } = data || {};

    return (
        <Container>
            <Body>
                <BackHeader
                    label={STRINGS.BACK}
                    onPress={() => navigation.navigate("OverviewScreen", {})}
                />
                <HeaderWrapper>
                    <HeaderText type="h2">{STRINGS.HEADER}</HeaderText>
                </HeaderWrapper>
                <ContentWrapper>
                    <DialChart
                        isLoading={loading || loopsLoading}
                        numberExpected={totalOpenLoops}
                        numberReceived={totalClosedLoops}
                    />
                    {!isDesktop ? (
                        <BannerWrapper>
                            <ImageBackground
                                source={require("../assets/images/blueBell.png")}
                                imageStyle={{
                                    width: "100%",
                                    height: 44,
                                    resizeMode: "repeat",
                                }}
                                style={{
                                    width: "100vw",
                                    height: 44,
                                    marginLeft: -12,
                                }}
                            />
                        </BannerWrapper>
                    ) : null}
                    {loopsLoading ? (
                        <LoadingErrorWrapper>
                            <ActivityIndicator />
                        </LoadingErrorWrapper>
                    ) : loopsErrors ? (
                        <LoadingErrorWrapper>
                            <Error />
                        </LoadingErrorWrapper>
                    ) : (
                        <StyledActivityLog
                            loops={loops}
                            initiated={loopsInitiated}
                        />
                    )}
                </ContentWrapper>
            </Body>
        </Container>
    );
}
