import React, { useContext } from "react";
import { View, Pressable } from "react-native";
import Text from "../components/Text";
import styled, { css, useTheme } from "../styling/styled-components";
import CampusContext from "../contexts/CampusContext";
import { Bubble, BubbleDesktop } from "../assets/vectors";
import { openURL, prettifyPhoneNumber } from "../utils/helpers";

const STRINGS = {
    FAQ: "See FAQ’s",
    HELP_DESCR: (number: string) =>
        `Reach out to your university or send us a text at ${prettifyPhoneNumber(
            number
        )} if you have questions.`,
    NEED_HELP: "Need help?",
    TEXT_US: "Text us",
};

const Container = styled(View)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.FOAM};
    padding-vertical: ${({ theme }) => theme.spacing.xlarge}px;
    padding-horizontal: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xlarge : theme.spacing.small}px;
    border-radius: ${({ theme }) => (theme.isDesktop ? 8 : 2)}px;
`;

const HeaderWrapper = styled(View)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
`;

const DescrWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.medium}px;
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
`;

const DescrText = styled(Text)`
    text-align: ${({ theme }) => (theme.isDesktop ? "left" : "center")};
`;

const ActionWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: ${({ theme }) => (theme.isDesktop ? "flex-end" : "center")};
    gap: ${({ theme }) => theme.spacing.large}px;
`;

const CtaWrapper = styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: ${({ theme }) => (theme.isDesktop ? "flex-end" : "center")};
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            width: 100%;
        `}
`;

const Cta = styled(Pressable)`
    width: 101px;
    height: 43px;
    justify-content: center;
    border-radius: 100px;
    padding-vertical: ${({ theme }) => theme.spacing.tiny}px;
    text-align: center;
    ${({ theme: { isMobile } }) =>
        isMobile &&
        css`
            width: 85px;
            height: 29px;
            border-radius: 14px;
        `}
`;

const TextUsCta = styled(Cta)`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.UI_BLUE};
    border: solid 1px ${({ theme }) => theme.colors.UI_BLUE};
`;

const FaqCta = styled(Cta)`
    background-color: ${({ theme }) => theme.colors.UI_BLUE};
    color: ${({ theme }) => theme.colors.WHITE};
`;

const BlueText = styled(Text)`
    text-align: center;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const WhiteText = styled(Text)`
    color: ${({ theme }) => theme.colors.WHITE};
`;

const BubbleDeskContainer = styled(View)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const TextUsText = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

type Props = {};

export default function NeedHelp({ style }: Props) {
    const { isDesktop } = useTheme();
    const { programDetailsURL, contactPhoneNumber } =
        useContext(CampusContext).campusConfig;

    const handleTextUsCta = () => {
        openURL(`sms:${contactPhoneNumber}`);
    };

    return (
        <Container style={style}>
            {!isDesktop && <Bubble />}
            <HeaderWrapper>
                <BlueText type="sh1">{STRINGS.NEED_HELP}</BlueText>
            </HeaderWrapper>
            <DescrWrapper>
                <DescrText type="b2">
                    {STRINGS.HELP_DESCR(contactPhoneNumber)}
                </DescrText>
            </DescrWrapper>
            <CtaWrapper>
                <ActionWrapper>
                    <TextUsCta onPress={handleTextUsCta}>
                        <TextUsText type="h3">{STRINGS.TEXT_US}</TextUsText>
                    </TextUsCta>
                    {programDetailsURL && (
                        <FaqCta onPress={() => openURL(programDetailsURL)}>
                            <WhiteText type="h3">{STRINGS.FAQ}</WhiteText>
                        </FaqCta>
                    )}
                </ActionWrapper>
                {isDesktop && (
                    <BubbleDeskContainer>
                        <BubbleDesktop />
                    </BubbleDeskContainer>
                )}
            </CtaWrapper>
        </Container>
    );
}

NeedHelp.defaultProps = { showCount: true };
