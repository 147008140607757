import { addDays } from "date-fns";

import {
    Asset,
    Loop,
    RentalSearchResults,
    RentalSearchDocument,
    RentalStatus,
    RentalPhase,
    RentalStep,
    RentalStepStatus,
    ForgivenessDetails,
    ForgivenessReason,
} from "../../../API";
import queryLoops from "../queryLoops";

import aggregateHits from "../../../utils/rentals/aggregateHits";

const FINED_RENTAL_PHASES: { [phase: RentalPhase]: number } = {
    [RentalPhase.Initiated]: 0,
    [RentalPhase.AboutToExpire]: 1,
    [RentalPhase.LatePending]: 3,
    [RentalPhase.Late]: 5,
    [RentalPhase.FinePending]: 7,
    [RentalPhase.Fined]: 12,
    [RentalPhase.FinedManual]: 12,
    [RentalPhase.FinedAutomatic]: 12,
    [RentalPhase.FineError]: 12,
    [RentalPhase.Expired]: 12,
};

const FAILED_RENTAL_PHASES: { [phase: RentalPhase]: number } = {
    [RentalPhase.Initiated]: 0,
    [RentalPhase.AboutToExpire]: 1,
    [RentalPhase.LatePending]: 3,
    [RentalPhase.Late]: 5,
    [RentalPhase.FailedPending]: 7,
    [RentalPhase.Failed]: 12,
    [RentalPhase.FinedManual]: 12,
    [RentalPhase.FinedAutomatic]: 12,
    [RentalPhase.FineError]: 12,
    [RentalPhase.Expired]: 12,
};

const ASSETS: Asset[] = [
    {
        assetId: "1",
        assetType: {
            assetTypeId: "1",
            name: "Rental Clamshell",
        },
    },
    {
        assetId: "2",
        assetType: {
            assetTypeId: "2",
            name: "Rental Cup",
        },
    },
];
const TODAY = new Date();

type MockConfig = {
    rentalId: string;
    currentPhase: RentalPhase;
    currentStatus: RentalStatus;
    asset: Asset;
    isFine: boolean;
    initiatedAt?: Date;
    resolvingLoop?: Loop | null;
    forgivenessDetails?: ForgivenessDetails;
};

// Update these to drive the mocked rentals
const CONFIGS: MockConfig[] = [
    // All steps of the fined sad path
    {
        rentalId: "rental-1",
        currentPhase: RentalPhase.Initiated,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[0],
        isFine: true,
        resolvingLoop: null,
    },
    {
        rentalId: "rental-2",
        currentPhase: RentalPhase.Initiated,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[1],
        isFine: true,
        resolvingLoop: queryLoops?.data?.queryLoops?.results[0],
    },
    {
        rentalId: "rental-3",
        currentPhase: RentalPhase.LatePending,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[0],
        isFine: true,
        resolvingLoop: queryLoops?.data?.queryLoops?.results[3],
    },
    {
        rentalId: "rental-4",
        currentPhase: RentalPhase.Late,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[0],
        isFine: true,
        resolvingLoop: queryLoops?.data?.queryLoops?.results[4],
    },
    {
        rentalId: "rental-5",
        currentPhase: RentalPhase.FinePending,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[0],
        isFine: true,
        resolvingLoop: queryLoops?.data?.queryLoops?.results[6],
    },
    {
        rentalId: "rental-6",
        currentPhase: RentalPhase.Fined,
        currentStatus: RentalStatus.Expired,
        asset: ASSETS[0],
        isFine: true,
        resolvingLoop: queryLoops?.data?.queryLoops?.results[10],
    },
    {
        rentalId: "rental-7",
        currentPhase: RentalPhase.AboutToExpire,
        currentStatus: RentalStatus.InProgress,
        asset: ASSETS[0],
        isFine: true,
    },
    {
        rentalId: "rental-8",
        currentPhase: RentalPhase.FineError,
        currentStatus: RentalStatus.Forgiven,
        asset: ASSETS[0],
        isFine: true,
    },
    {
        rentalId: "rental-9",
        currentPhase: RentalPhase.Expired,
        currentStatus: RentalStatus.Canceled,
        asset: ASSETS[0],
        isFine: true,
    },
    {
        rentalId: "rental-10",
        currentPhase: RentalPhase.FinedAutomatic,
        currentStatus: RentalStatus.CanceledWithRefund,
        asset: ASSETS[0],
        isFine: true,
        forgivenessDetails: {
            reason: ForgivenessReason.ManualForgiveness,
            timestamp: "1722872732",
        },
    },
    {
        rentalId: "rental-11",
        currentPhase: RentalPhase.FinedManual,
        currentStatus: RentalStatus.CanceledWithRefund,
        asset: ASSETS[0],
        isFine: true,
    },
];

type SearchRentalVariables = {
    fromTimestamp: string;
    toTimestamp: string;
    timezone: string;
    calendarInterval: string;
    rentalPhases: RentalPhase[];
    rentalStatuses: RentalStatus[];
    assetTypeIds: string[];
    searchQuery: string;
    size: number;
    paginateFrom: number;
};

export default class MockRentals {
    // these will serve as local datastores for the Rental objects we'll contruct
    // and use within these mocks
    rentalSearchDocs: RentalSearchDocument[] = [];
    rentalStepConfigs: { [rentalId: string]: RentalStep[] } = {};

    genRentalSearchDoc = (config: MockConfig): RentalSearchDocument => {
        return {
            rentalId: config.rentalId,
            loopId: crypto.randomUUID(),
            lastPhase: config.currentPhase,
            status: config.currentStatus,
            rentedAsset: config.asset,
            dueAt: addDays(
                config.initiatedAt,
                FINED_RENTAL_PHASES[RentalPhase.LatePending]
            ).toISOString(),
            createdAt: config.initiatedAt.toISOString(),
            resolvingLoop: config.resolvingLoop,
            forgivenessDetails: config.forgivenessDetails,
        };
    };

    genRentalStepsForRental = (config: MockConfig): RentalStep[] => {
        const steps: RentalStep[] = [];

        const phaseMap = config.isFine
            ? FINED_RENTAL_PHASES
            : FAILED_RENTAL_PHASES;

        const phases = Object.keys(phaseMap);

        phases.map((phase) => {
            const triggeredAt = new Date(config.initiatedAt);
            triggeredAt.setDate(triggeredAt.getDate() + phaseMap[phase]);

            steps.push({
                id: crypto.randomUUID(),
                rentalId: config.rentalId,
                status:
                    phaseMap[config.currentPhase] - phaseMap[phase] <= 0
                        ? RentalStepStatus.CheckFailed
                        : RentalStepStatus.Pending,
                rentalPhase: phase,
                rentalStepConfigType: "PhaseChangeRentalStepConfig",
                statusUpdatedAt: triggeredAt.toISOString(),
                triggerAt: triggeredAt.toISOString(),
            });
        });

        return steps;
    };

    constructor(rentalSearchDocs, rentalStepConfigs) {
        CONFIGS.map((config) => {
            const initAt = new Date(TODAY);
            const phases = config.isFine
                ? FINED_RENTAL_PHASES
                : FAILED_RENTAL_PHASES;
            initAt.setDate(initAt.getDate() - phases[config.currentPhase]);
            config.initiatedAt = initAt;
            this.rentalSearchDocs.push(this.genRentalSearchDoc(config));
            this.rentalStepConfigs[config.rentalId] =
                this.genRentalStepsForRental(config);
        });
    }

    mockSearchRentals = ({
        fromTimestamp,
        toTimestamp,
        timezone,
        calendarInterval,
        rentalPhases,
        rentalStatuses,
        assetTypeIds,
        searchQuery,
        size,
        paginateFrom,
    }: SearchRentalVariables): RentalSearchResults => {
        let matchedRentals = this.rentalSearchDocs;

        if ((rentalPhases || []).length > 0) {
            matchedRentals = matchedRentals.filter((rental) =>
                rentalPhases.includes(rental.lastPhase)
            );
        }

        if ((rentalStatuses || []).length > 0) {
            matchedRentals = matchedRentals.filter((rental) =>
                rentalStatuses.includes(rental.status)
            );
        }

        return {
            rentals: size > 0 ? matchedRentals : [],
            totalHits: matchedRentals.length,
            hitAggregations: aggregateHits(matchedRentals),
        };
    };

    mockListRentalStepsForRental = (
        rentalId: string,
        rentalStepStatus?: RentalStepStatus
    ): RentalStep => {
        return this.rentalStepConfigs[rentalId];
    };
}
