import { Linking, Platform } from "react-native";
import { intervalToDuration } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

export function randomToken() {
    return (
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    );
}

export function frequencyMapper(details) {
    const detailsMap = { "-1": {}, "1": {}, "2": {}, "3": {}, "4": {} };
    details.map((detail, detailIdx) => {
        detailsMap[detail.frequencyWeeks][detail.distributionCenterProductId] =
            detail;
    });

    return detailsMap;
}

export function displayDollars(cents) {
    return `$${(cents / 100).toFixed(2)}`;
}

export function convertCents(dollars) {
    return Number(dollars.replace("$", "").replace(".", ""));
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function openURL(url) {
    if (Platform.OS == "web") {
        window.open(url, "_blank");
    } else {
        Linking.openURL(url);
    }
}

export function navTo(url) {
    if (Platform.OS == "web") {
        window.location = url;
    } else {
        Linking.openURL(url);
    }
}

export function prettifyNumber(num: number): string {
    return num.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

export function prettifyPhoneNumber(str: string): string {
    //Filter only numbers from the input
    const cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = match[1] ? "+1" : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            ""
        );
    }

    return null;
}

export function prettifyYearDate(date: Date, campusTimezone): string {
    /*Sept 10 if year is same as current year
     * Sept 10, 2022 if year is not the same as current year
     * */
    const today = new Date();
    return formatInTimeZone(
        date,
        campusTimezone,
        date.getYear() == today.getYear() ? "MMM dd" : "MMM dd, yyyy"
    );
}

export function genDateArray(fromDate: Date, numDays: number): Date[] {
    const dateMove = fromDate;
    const listDate = [];
    let i = 0;

    while (i < numDays) {
        const newDate = new Date(dateMove);
        listDate.push(newDate);
        dateMove.setDate(dateMove.getDate() + 1);
        i = i + 1;
    }

    return listDate;
}

export function nextDateDetails(
    dates: string[]
): [boolean, boolean, Date?, number?] {
    const TODAY = new Date();
    const TOMORROW = new Date();
    TOMORROW.setDate(TOMORROW.getDate() + 1);

    const sortedDates = dates
        .map((date) => new Date(date))
        .sort((a, b) => a - b);

    if (sortedDates.length > 0) {
        const firstDate = sortedDates[0];

        return [
            firstDate.toLocaleDateString("en-US") ==
                TODAY.toLocaleDateString("en-US"),
            firstDate.toLocaleDateString("en-US") ==
                TOMORROW.toLocaleDateString("en-US"),
            firstDate,
            intervalToDuration({ start: TODAY, end: firstDate }).days + 1,
        ];
    } else {
        return [false, false, null, null];
    }
}

/*
 * Parse the GQL errors array if exists and return the first .errorType and .errorMessage
 * that matches the errorTypeMatch argument if exists or simply the first error in the array
 * */
export function graphErrorDetails(
    errors: any[] | void,
    errorTypeMatch: string | void
): [string | void, string | void] {
    let matchedError = {};

    errors = errors || [];

    if (errors.length == 0) {
        return [null, null];
    } else {
        if (errorTypeMatch) {
            matchedError = errors.find(
                (error) => error.errorType == errorTypeMatch
            );
        } else {
            const matchedError = errors[0];
            // network errors look different: [{"message":"Network Error"}]
            if (matchedError.message == "Network Error") {
                return ["NetworkError", "Network Error"];
            }
        }
        return [matchedError?.errorType, matchedError?.errorMessage];
    }
}
