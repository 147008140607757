// react
import React, { useState, useContext } from "react";
import { Pressable, View } from "react-native";
import { Link } from "@react-navigation/native";

// context
import CampusContext from "../contexts/CampusContext";

// style
import styled, { useTheme } from "../styling/styled-components";

// components
import Button from "./Button";
import CheckBox from "./CheckBox";
import Errorable from "./common/Errorable";
import Text from "./Text";

// utils
import { prettifyPhoneNumber } from "../utils/helpers";

// images
import Oyster from "../assets/vectors/oyster.svg";


const STRINGS = {
    HEADER: "Oh, shucks. Something went wrong.",
    SUBHEADER_BEFORE_PHONE: "Please try again or text us at ",
    SUBHEADER_AFTER_PHONE: " if you need help.",
    BUTTON_TEXT: "Try again",
};

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const Container = styled(View)`
    ${({ theme }) =>
        theme.isDesktop &&
        `
        align-items: center;
        width: 50vw;
        margin: 0 auto 60px;
    `}
`;

const HeaderWrapper = styled(View)`
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
    text-align: center;
`;

const OysterWrapper = styled(View)`
    margin-bottom: ${({ theme }) => theme.spacing.large}px;
    align-items: center;
`;

const PhoneLink = styled(Link)`
    color: ${({ theme }) => theme.colors.BLUE};
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.BLUE};
`;

const SubheaderWrapper = styled(View)`
    margin-bottom: ${({ theme }) => theme.spacing.xmlarge}px;
    text-align: center;
`;

const StyledButton = styled(Button)`
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    width: ${({ theme }) => (theme.isMobile ? "100%" : "294px")};
    ${({ theme }) => theme.isDesktop && "margin: 0 auto;"}
`;


type Props = {
    onTryAgain: () => void;
};

export default function ThirdPartySignInScreen({ onTryAgain }: props) {
    const { contactPhoneNumber } = useContext(CampusContext).campusConfig;

    const phoneHref = "tel:" + contactPhoneNumber;

    const phoneText = prettifyPhoneNumber(contactPhoneNumber);

    return(
        <Container>
            <OysterWrapper>
                <Oyster/>
            </OysterWrapper>
            <HeaderWrapper>
                <BlueText type="h1">{STRINGS.HEADER}</BlueText>
            </HeaderWrapper>
            <SubheaderWrapper>
                <BlueText type="b1">{STRINGS.SUBHEADER_BEFORE_PHONE}
                    {"\n"}
                    <PhoneLink to={phoneHref}>
                        {phoneText}
                    </PhoneLink>
                {STRINGS.SUBHEADER_AFTER_PHONE}
                </BlueText>
            </SubheaderWrapper>
            <StyledButton
                label={STRINGS.BUTTON_TEXT}
                type="primary"
                size="large"
                onPress={onTryAgain}
            />
        </Container>
    );
}
