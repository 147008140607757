// react
import React, { useEffect } from "react";
import { View } from "react-native";

// style
import styled from "../styling/styled-components";
import { ContainerBox } from "../assets/vectors";

// components
import Button from "./Button";
import Text from "./Text";
import { ProgressBar } from "./ProgressBar";
import { navTo } from "../utils/helpers";

const STRINGS = {
    AUTO_REDIRECT: "You wil be automatically redirected shortly.",
    HEADER: "You’re all set!",
    HEADER_P2:
        "You can now order reusable containers  at any participating Grubhub location.",
    NOW: "Return to order",
};

const ReturnTextContainer = styled(View)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.medium}px;
    margin: ${({ theme }) => theme.spacing.xmlarge}px
        ${({ theme }) => theme.spacing.xlarge}px;
`;

const ContainerBoxWrapper = styled.View`
    display: flex;
    width: 100%;
`;

const HeaderWrapper = styled.View`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.huge}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const ProgressBarWrapper = styled.View`
    width: calc(100% + 60px);
    margin-bottom: -${({ theme }) => theme.spacing.xmlarge}px;
`;

type Props = {
    redirectUrl: string;
};

export default function ThirdPartyRedirect({ redirectUrl }: Props) {
    useEffect(() => {
        // Set a timeout to redirect after 5 seconds
        const timer = setTimeout(() => {
            navTo(redirectUrl);
        }, 5000);

        return () => clearTimeout(timer);
    }, [redirectUrl]);

    return (
        <ReturnTextContainer>
            <ContainerBoxWrapper>
                <ContainerBox />
            </ContainerBoxWrapper>
            <HeaderWrapper>
                <Text type="h1">{STRINGS.HEADER}</Text>
                <Text type="h1">{STRINGS.HEADER_P2}</Text>
            </HeaderWrapper>
            <Button
                type="primary"
                size="large"
                label={STRINGS.NOW}
                style={{ width: "100%" }}
                onPress={() => {
                    navTo(redirectUrl);
                }}
                isLoading={false}
            />
            <View>
                <Text type="b2">{STRINGS.AUTO_REDIRECT}</Text>
            </View>
            <ProgressBarWrapper>
                <ProgressBar />
            </ProgressBarWrapper>
        </ReturnTextContainer>
    );
}
