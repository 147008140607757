import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";
import {
    Poppins_200ExtraLight,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_500Medium,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_800ExtraBold,
} from "@expo-google-fonts/poppins";
import { Lato_700Bold, Lato_400Regular } from "@expo-google-fonts/lato";

export default function useCachedResources() {
    const [isLoadingComplete, setLoadingComplete] = React.useState(false);

    // Load any resources or data that we need prior to rendering the app
    React.useEffect(() => {
        async function loadResourcesAndDataAsync() {
            try {
                SplashScreen.preventAutoHideAsync();

                // Load fonts
                await Font.loadAsync({
                    ...Ionicons.font,
                    poppinsLight: Poppins_200ExtraLight,
                    poppinsLighter: Poppins_300Light,
                    poppinsRegular: Poppins_400Regular,
                    poppinsMedium: Poppins_500Medium,
                    poppinsSemiBold: Poppins_600SemiBold,
                    poppinsBold: Poppins_700Bold,
                    poppinsHeavy: Poppins_800ExtraBold,
                    latoBold: Lato_700Bold,
                    lato: Lato_400Regular,
                    DTGetaiGrotesk: require("../assets/fonts/DTGetaiGrotesk.otf"),
                });
            } catch (e) {
                console.warn(e);
            } finally {
                setLoadingComplete(true);
                SplashScreen.hideAsync();
            }
        }

        loadResourcesAndDataAsync();
    }, []);

    return isLoadingComplete;
}
