// react
import React, { useCallback, useContext } from "react";

// style
import styled, { useTheme } from "../../styling/styled-components";

// context
import CampusContext from "../../contexts/CampusContext";

// images
import { CheckCircle } from "../../assets/vectors";

// components
import CountCardButton from "../common/CountCardButton";
import { GreenPill } from "../Pill";
import { View } from "react-native";
import Text, { BlueText } from "../Text";
import { Loop } from "../../API";

// utils
import { formatInTimeZone } from "date-fns-tz";
import getSameDaySameTypeActivities from "../../utils/activities/getSameDaySameTypeActivities";
import { loopsToActivities } from "../../components/ActivityLog";

const STRINGS = {
    RECENT_ACTIVITY: "Recent activity",
    RECENT_ACTIVITY_DESCR_NONE: "Nothing to see here, yet!",
};

const FlexContainer = styled(View)`
    display: "flex";
    flex-direction: row;
    justify-content: "center";
    gap: ${({ theme: { spacing } }) => spacing.xsmall}px;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StyledCountCard = styled(CountCardButton)`
    min-height: 85px;
`;

type Props = {
    loops: Loop[];
    loopsInitiated: boolean;
    navigation: unknown;
};

export default function RecentActivityCard({
    loops,
    loopsInitiated,
    navigation,
}: Props) {
    // Ideally we would have another component instead of passing null count to
    // StyledCountCard, but this refactor has already taken up a lot of time.
    // Hopefully the work done here makes it easier to take the final step.
    const totalCount = null;

    return (
        <StyledCountCard
          count={totalCount}
          onPress={() => navigation.navigate("ActivityScreen")}
      >
            <HeaderWrapper>
                <BlueText type="sh1">{STRINGS.RECENT_ACTIVITY}</BlueText>
            </HeaderWrapper>
            <LoadingSubheader loops={loops} loopsInitiated={loopsInitiated} />
        </StyledCountCard>
    );
}

type LoadingSubheaderProps = {
    loops: Loop[];
    loopsInitiated: boolean;
}

function LoadingSubheader({ loops, loopsInitiated }: LoadingSubheaderProps) {
    const { isDesktop } = useTheme();
    return (
        <>
            {!loopsInitiated ? (
                <BlueText type={isDesktop ? "b1" : "b2"}>
                    {STRINGS.RECENT_ACTIVITY_DESCR_NONE}
                </BlueText>
            ) : (
                <LastActivitySubheader loops={loops} />
            )}
        </>
    );
}

type LastActivitySubheaderProps = {
    loops: Loop[];
};

function LastActivitySubheader({ loops }: LastActivitySubheaderProps) {
    const { isDesktop } = useTheme();
    const { localTimezone } = useContext(CampusContext).campusConfig;

    const activities = loopsToActivities(loops);
    const lastActivities = getSameDaySameTypeActivities(
        activities,
        activities[0]
    );
    const lastActivity = activities[0];

    const pluralModifier = lastActivities.length > 1 ? "s" : "";

    const getLastActivityDate = useCallback(() => {
        const today = new Date();
        return today.getFullYear() === lastActivity.date.getFullYear()
            ? formatInTimeZone(lastActivity.date, localTimezone, "MMM d")
            : formatInTimeZone(lastActivity.date, localTimezone, "M/d/yy");
    }, [lastActivity, localTimezone]);

    const subheaderFontType = isDesktop ? "b1" : "b2";

    return (
        <>
            {lastActivities.length == 0 ? (
                <BlueText type={subheaderFontType}>
                    {STRINGS.RECENT_ACTIVITY_DESCR_NONE}
                </BlueText>
            ) : lastActivity.name == "Received" ? (
                <GreenPill type={subheaderFontType}>
                    <FlexContainer>
                        <CheckCircle />
                        <Text>
                            {lastActivities.length} return{pluralModifier} received on{" "}
                            {getLastActivityDate()}
                        </Text>
                    </FlexContainer>
                </GreenPill>
            ) : (
                <BlueText type={subheaderFontType}>
                    Checked-out {lastActivities.length} item{pluralModifier} on {getLastActivityDate()}
                </BlueText>
            )}
        </>
    );
}
