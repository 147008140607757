// react
import React, { useEffect, useContext } from "react";

// style
import styled, { useTheme } from "../../styling/styled-components";

// context
import CampusContext from "../../contexts/CampusContext";

// components
import CountCardButton from "../common/CountCardButton";
import { View } from "react-native";
import { BlueText } from "../Text";

// utils
import { formatInTimeZone } from "date-fns-tz";
import { getDueSoon } from "../../utils/rentals/filterByState";
import getNextDueDate from "../../utils/rentals/getNextDueDate";

const STRINGS = {
    DUE_SOON_HEADER: "Due soon",
    NEXT_DUE: (
        dueDays: number,
        nextDue: Date,
        campusTimezone: string,
        dueBackDays: number
    ) =>
        dueDays != null
            ? `Next due: ${
                  dueDays == 0
                      ? "Today"
                      : dueDays == 1
                      ? "Tomorrow"
                      : dueDays < 7
                      ? `${formatInTimeZone(nextDue, campusTimezone, "EEEE")}`
                      : dueDays >= 7
                      ? `${formatInTimeZone(nextDue, campusTimezone, "MMMM d")}`
                      : ""
              }`
            : `Due ${dueBackDays}-days after check-out`,
};

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StyledCountCard = styled(CountCardButton)`
    min-height: 85px;
`;

export default function DueSoonCountCard({ navigation, rentals, rentalsInitiated }: props) {

    const { isDesktop } = useTheme();

    const { dueBackDays, localTimezone, } = useContext(CampusContext).campusConfig;

    const dueSoonRentals = getDueSoon(rentals);
    const [nextDue, nextDueDays] = getNextDueDate(dueSoonRentals);

    const totalCount = rentalsInitiated ? dueSoonRentals.length : null;

    return (
      <StyledCountCard
          count={totalCount}
          onPress={() => navigation.navigate("DueSoonScreen")}
      >
          <HeaderWrapper>
              <BlueText type="sh1">
                  {STRINGS.DUE_SOON_HEADER}
              </BlueText>
          </HeaderWrapper>
          <BlueText type={isDesktop ? "b1" : "b2"} >
              { STRINGS.NEXT_DUE( nextDueDays, nextDue, localTimezone, dueBackDays) }
          </BlueText>
      </StyledCountCard>
    );
}
