// react
import React, { useContext } from "react";
import { View } from "react-native";

// context
import GlobalContext from "../../contexts/GlobalContext";

// style
import styled from "../../styling/styled-components";

// components
import Button from "../Button";
import Text from "../Text";

// utils
import { navTo } from "../../utils/helpers";

const STRINGS = {
    ACCOUNT_MISMATCH_EMAIL:
        "Please make sure you are signed in to the correct Grubhub account and then try ordering from a ReusePass location. Text Grubhub if you need help.",
    ACCOUNT_MISMATCH_HEADER:
        "Your account is already linked with a different Grubhub profile.",
    ORG_MISMATCH_HEADER:
        "Hmm, your ReusePass account is associated with a different campus.",
    ORG_MISMATCH:
        "Your ReusePass account must be associated with the same campus as your Grubhub profile. Try using a different Grubhub account, or text ReusePass to change the campus associated with your account.",
    RETURN: "Return to Grubhub order",
    REUSEPASS: "ReusePass",
    EMAIL_GRUBHUB: "Email Grubhub",
    TEXT_REUSEPASS: "Text ReusePass",
};

const Container = styled.View`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.medium}px;
    margin: ${({ theme }) => theme.spacing.large}px
        ${({ theme }) => theme.spacing.small}px;
`;

const ReturnTextContainer = styled(View)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.medium}px;
`;

const HeaderWrapper = styled.View`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.huge}px;
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const TopHeader = styled.View``;

const ReusePassLabel = styled(Text)``;
const CampusLabel = styled(Text)``;

type Props = {
    campusName: string;
};

export default function ThirdPartyRedirect({ campusName }: Props) {
    const { redirectOnSignInUrl, thirdPartyAuthError } =
        useContext(GlobalContext).globalSelections;

    const contactDetail =
        thirdPartyAuthError === "OrgMismatch"
            ? "1-818-423-5142"
            : "grubhubforcampus@grubhub.com";

    const contactLabel =
        thirdPartyAuthError === "OrgMismatch"
            ? STRINGS.TEXT_REUSEPASS
            : STRINGS.EMAIL_GRUBHUB;

    const contactHref =
        thirdPartyAuthError === "OrgMismatch"
            ? "sms:" + contactDetail
            : "mailto:" + contactDetail;

    const handleReturn = () => {
        navTo(redirectUrlWithResponseParams);
    };

    const redirectUrlWithResponseParams =
        redirectOnSignInUrl.indexOf("?") == -1
            ? redirectOnSignInUrl + "?success=false"
            : redirectOnSignInUrl + "&success=false";

    const headerText =
        thirdPartyAuthError === "OrgMismatch"
            ? STRINGS.ORG_MISMATCH_HEADER
            : STRINGS.ACCOUNT_MISMATCH_HEADER;

    const MessageComponent = () => {
        if (thirdPartyAuthError === "OrgMismatch") {
            return <Text type="b1">{STRINGS.ORG_MISMATCH}</Text>;
        }
        return <Text type="b1">{STRINGS.ACCOUNT_MISMATCH_EMAIL}</Text>;
    };
    return (
        <Container>
            <TopHeader>
                <ReusePassLabel type="sh1">{STRINGS.REUSEPASS}</ReusePassLabel>
                <CampusLabel type="b2">{campusName}</CampusLabel>
            </TopHeader>
            <ReturnTextContainer>
                <HeaderWrapper>
                    <Text type="h1">{headerText}</Text>
                    <MessageComponent />
                </HeaderWrapper>
                <Button
                    type="secondary"
                    size="large"
                    label={contactLabel}
                    style={{ width: "100%" }}
                    onPress={() => {
                        navTo(contactHref);
                    }}
                    isLoading={false}
                />
                <View>
                    <Text type="link" onPress={handleReturn}>
                        {STRINGS.RETURN}
                    </Text>
                </View>
            </ReturnTextContainer>
        </Container>
    );
}
