const baseColors = {
    ACTIVATED_CHARCOAL: "#313031",
    ASPHALT: "#D6D8DC",
    ASH: "#36393F",
    BLUE: "#0450DB",
    CHILL_HURRICANE: "#5F6978",
    DEEP_BLUE_SEA: "#162350",
    EXHAUST: "#707683",
    FOAM: "#FBFEF5",
    FROTH: "#F7F3E8",
    GREEN: "#135430",
    GREEN_1: "#295732",
    GREEN_2: "#2E8540",
    GREEN_3: "#4DCB7B",
    GREEN_4: "#DDFDE4",
    HURRICANE: "#5F6978",
    LIME_SQUEEZE: "#E2F89C",
    MARINE_LAYER: "#C2CFE0",
    RED: "#B84E44",
    RED_1: "#732626",
    RED_2: "#B82E2E",
    RED_3: "#E54D4D",
    RED_4: "#FDD9D8",
    ROCK_BOTTOM: "#1D2130",
    ROYAL_BLUE: "#3B5EDB",
    SLATE: "#FFF",
    SMOG: "#F5F6F8",
    SUMMER_STORM: "#90A0B7",
    SUSTAINABLE_SALMON: "#F79183",
    TORY_BLUE: "#2A439C",
    UI_BLUE: "#1745CF",
    UI_GREEN: "#2E8540",
    WAVE_STORM: "#1745CF",
    WHITE: "#FFFFFF",
    YELLOW: "#B8860B",
    YELLOW_1: "#796515",
    YELLOW_2: "#B88D0B",
    YELLOW_3: "#E8B430",
    YELLOW_4: "#F9E7B8",
};

const semanticColors = {
    default: baseColors.DEEP_BLUE_SEA,
    hover: baseColors.ROYAL_BLUE,
    pressed: baseColors.TORY_BLUE,
    disabled: baseColors.MARINE_LAYER,
};

export const COLORS = {
    ...baseColors,
    ...semanticColors,
};
