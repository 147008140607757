// react
import React, { useContext, useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";

//style
import styled, { css } from "../styling/styled-components";

// context
import CampusContext from "../contexts/CampusContext";
import GlobalContext from "../contexts/GlobalContext";

// API
import { RentalSearchDocument } from "../API";
import { useSearchRentals } from "../graphql/hooks/useSearchRentals";

// components
import BackHeader from "../components/BackHeader";
import BottomDrawer from "../components/BottomDrawer";
import Error from "../components/Error";
import NavReturnLocations from "../components/NavReturnLocations";
import NeedHelp from "../components/NeedHelp";
import PastDueCard from "../components/PastDueCard";
import PastDueOverlay from "../components/PastDueOverlay";
import PastDuePlaceholder from "../components/PastDuePlaceholder";
import PastDueSection from "../components/PastDueSection";
import ScreenView from "../components/ScreenView";
import Text from "../components/Text";

// utils
import { queryForLate } from "../utils/rentals/queryByState";

const STRINGS = {
    HEADER: (rentals: Maybe<RentalSearchDocument[]>) =>
        `Past due ${rentals ? `(${rentals.length})` : ""}`,
    BLURB: "Past due means your rental was recently due but has not yet been scanned in. If you haven't already, please return your container as soon as possible.",
    BACK: "BACK",
    WAIT: "Please allow up to 24 hours for returned containers to be reflected in your account.",
};

const Container = styled(ScreenView)``;

const BodyWrapper = styled(View)`
    flex-direction: ${({ theme }) => (theme.isDesktop ? "row" : "column")};
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacing.xlarge}px;
`;

const CardsWrapper = styled(View)`
    min-width: ${({ theme }) => (theme.isDesktop ? 460 : null)}px;
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            flex: 1;
        `}
`;

const StyledNeedHelp = styled(NeedHelp)`
    max-width: 428px;
    padding-left: ${({ theme }) => theme.spacing.xmlarge}px;
    padding-right: ${({ theme }) => theme.spacing.xmlarge}px;
`;

const StaticWrapper = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
    gap: ${({ theme }) => theme.spacing.large}px;
`;

const StyledPastDuePlaceholder = styled(PastDuePlaceholder)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            flex: 1;
        `}
`;

const ErrorLoadingWrapper = styled(View)`
    ${({ theme: { isDesktop } }) =>
        isDesktop &&
        css`
            flex: 1;
        `}
`;

const HeaderWrapper = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const StyledReturnLocations = styled(NavReturnLocations)`
    flex: 1;
`;

const BlurbText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xlarge : theme.spacing.medium}px;
`;

const HeaderText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

export default function LateScreen({ navigation }: props) {
    const [overlayRental, setOverlayRental] = useState();
    const { returnLocationsURL } = useContext(CampusContext).campusConfig;

    const { consumerAcceptedTermsAt } =
        useContext(GlobalContext).globalSelections;

    const [
        queryRentals,
        rentals,
        rentalsInitiated,
        rentalsLoading,
        rentalsErrors,
    ] = useSearchRentals();

    useEffect(() => {
        const toTimestamp = new Date().toISOString();
        const fromTimestamp = new Date(consumerAcceptedTermsAt).toISOString();
        queryForLate(queryRentals, fromTimestamp, toTimestamp);
    }, [consumerAcceptedTermsAt]);
    // consumerAcceptedTermsAt should only change one time when the page first loads

    return (
        <Container>
            <BottomDrawer
                isOpen={!!overlayRental}
                onExit={() => setOverlayRental(null)}
                dialogStyle={{ justifyContent: "flexStart" }}
            >
                <PastDueOverlay rental={overlayRental} />
            </BottomDrawer>
            <BackHeader
                label={STRINGS.BACK}
                onPress={() => navigation.navigate("OverviewScreen", {})}
                headerRight={() => (
                    <StyledReturnLocations
                        returnLocationsURL={returnLocationsURL}
                    />
                )}
            />
            <HeaderWrapper>
                <HeaderText type="h2">
                    {STRINGS.HEADER(rentals.rentals)}
                </HeaderText>
            </HeaderWrapper>
            <BlurbText type="b2">{STRINGS.BLURB}</BlurbText>
            <BodyWrapper>
                {rentalsLoading ? (
                    <ErrorLoadingWrapper>
                        <ActivityIndicator />
                    </ErrorLoadingWrapper>
                ) : rentalsErrors ? (
                    <ErrorLoadingWrapper>
                        <Error />
                    </ErrorLoadingWrapper>
                ) : (rentals.rentals || []).length > 0 ? (
                    <CardsWrapper>
                        {rentals.rentals
                            .slice()
                            .reverse()
                            .map((lateRental, idx) => (
                                <PastDueCard
                                    key={`${lateRental.rentalId}${idx}`}
                                    rental={lateRental}
                                    onPress={() => setOverlayRental(lateRental)}
                                />
                            ))}
                    </CardsWrapper>
                ) : rentalsInitiated ? (
                    <StyledPastDuePlaceholder />
                ) : null}
                <StaticWrapper>
                    <PastDueSection />
                    <StyledNeedHelp />
                </StaticWrapper>
            </BodyWrapper>
        </Container>
    );
}
