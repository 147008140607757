import React from "react";
import { View } from "react-native";
import styled from "../styling/styled-components";

const Separator = styled(View)`
    width: 100%;
    border-top-width: 1px;
    border-top-style: solid;
    border-color: ${({ theme }) => theme.colors.ASPHALT};

    ${({ theme }) =>
        theme.isDesktop &&
        `
        max-width: 428px;
    `}
`;

export default Separator;
