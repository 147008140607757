/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendCommunication = /* GraphQL */ `
  mutation SendCommunication($input: CommunicationInput) {
    sendCommunication(input: $input) {
      communicationId
      communicationType
      userId
      consumerId
      parameters
      phoneNumber
    }
  }
`;
export const adminCreateConsumer = /* GraphQL */ `
  mutation AdminCreateConsumer($input: CreateCampusConsumerInput!) {
    adminCreateConsumer(input: $input) {
      consumerId
      phoneNumber
      name
      externalId
      email
      reuserId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
    }
  }
`;
export const initiateSignUp = /* GraphQL */ `
  mutation InitiateSignUp($input: InitiateSignUpInput) {
    initiateSignUp(input: $input) {
      signUpComplete
      reuserAuthToken
      previousChallengeAccepted
      previousChallengeErrorMessage
      countCompletedSignUpChallenges
      countTotalSignUpChallenges
      nextChallenge {
        organizationId
        challengeId
        challengeType
        inputName
        inputMinLength
        inputMaxLength
        inputRegEx
        isRequired
      }
    }
  }
`;
export const respondToSignUpChallenge = /* GraphQL */ `
  mutation RespondToSignUpChallenge($input: SignUpChallengeResponseInput) {
    respondToSignUpChallenge(input: $input) {
      signUpComplete
      reuserAuthToken
      previousChallengeAccepted
      previousChallengeErrorMessage
      countCompletedSignUpChallenges
      countTotalSignUpChallenges
      nextChallenge {
        organizationId
        challengeId
        challengeType
        inputName
        inputMinLength
        inputMaxLength
        inputRegEx
        isRequired
      }
    }
  }
`;
export const adminInitiateUserCompletedSignUpChallenges = /* GraphQL */ `
  mutation AdminInitiateUserCompletedSignUpChallenges(
    $input: InitiateUserCompletedSignUpChallengesInput
  ) {
    adminInitiateUserCompletedSignUpChallenges(input: $input) {
      phoneNumber
      organizationId
      confirmationCode
      completedChallenges {
        phoneNumber
        organizationId
        confirmationCode
        challengeResponse
        challenge {
          organizationId
          challengeId
          challengeType
          inputName
          inputMinLength
          inputMaxLength
          inputRegEx
          isRequired
        }
      }
      externalId
    }
  }
`;
export const adminAppendUserCompletedSignUpChallenge = /* GraphQL */ `
  mutation AdminAppendUserCompletedSignUpChallenge(
    $input: SignUpChallengeResponseInput
  ) {
    adminAppendUserCompletedSignUpChallenge(input: $input) {
      phoneNumber
      organizationId
      confirmationCode
      completedChallenges {
        phoneNumber
        organizationId
        confirmationCode
        challengeResponse
        challenge {
          organizationId
          challengeId
          challengeType
          inputName
          inputMinLength
          inputMaxLength
          inputRegEx
          isRequired
        }
      }
      externalId
    }
  }
`;
export const externalSignUpCheck = /* GraphQL */ `
  mutation ExternalSignUpCheck($input: ExternalSignUpCheckInput) {
    externalSignUpCheck(input: $input) {
      consumerId
      phoneNumber
      name
      externalId
      email
      reuserId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
    }
  }
`;
