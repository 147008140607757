import { StackScreenProps } from "@react-navigation/stack";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import Text from "../components/Text";
import Button from "../components/Button";

import { RootStackParamList } from "../types";

export default function NotFoundScreen({
    navigation,
}: StackScreenProps<RootStackParamList, "NotFound">) {
    return (
        <View style={styles.container}>
            <Text style={styles.title}>Oops... this screen doesn't exist.</Text>
            <Button
                onPress={() => navigation.navigate("Root")}
                label="Go home!"
            />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    link: {
        marginTop: 15,
        paddingVertical: 15,
    },
    linkText: {
        fontSize: 14,
        color: "#2e78b7",
    },
});
