// react
import React from "react";

// API
import { RentalSearchDocument } from "../API";

// components
import { GreyPill, RedPill, YellowPill } from "./Pill";

// utils
import isLate from "../utils/rentals/isLate";
import isOverdue from "../utils/rentals/isOverdue";
import isAboutToExpire from "../utils/rentals/isAboutToExpire";

type Props = {
    rental: RentalSearchDocument;
};

export default function PastDuePill({ rental }: Props) {
    let PillComponent = GreyPill;
    let pillText = "Pending";

    if (isLate(rental)) {
        PillComponent = YellowPill;
        pillText = "Late";
    } else if (isOverdue(rental) || isAboutToExpire(rental)) {
        PillComponent = RedPill;
        pillText = "Overdue";
    }

    return <PillComponent fontType={"sh2"}>{pillText}</PillComponent>;
}
