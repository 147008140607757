import { RentalPhase, RentalStatus } from "../../API";

export function queryForLate(
    queryRentals,
    fromTimestamp,
    toTimestamp,
    extraQueryParams = {}
) {
    queryRentals({
        toTimestamp,
        fromTimestamp,
        rentalPhases: [
            RentalPhase.AboutToExpire,
            RentalPhase.Late,
            RentalPhase.LatePending,
            RentalPhase.FinePending,
            RentalPhase.FailedPending,
        ],
        rentalStatuses: [RentalStatus.InProgress],
        ...extraQueryParams,
    });
}

export function queryForFailed(
    queryRentals,
    fromTimestamp,
    toTimestamp,
    extraQueryParams = {}
) {
    queryRentals({
        toTimestamp,
        fromTimestamp,
        rentalPhases: [
            RentalPhase.Expired,
            RentalPhase.Failed,
            RentalPhase.Fined,
            RentalPhase.FinedAutomatic,
            RentalPhase.FineError,
            RentalPhase.FinedManual,
        ],
        rentalStatuses: [
            RentalStatus.Expired,
            RentalStatus.Canceled,
            RentalStatus.CanceledWithRefund,
            RentalStatus.Failed,
            RentalStatus.Forgiven,
        ],
        ...extraQueryParams,
    });
}
