import React from "react";
import { View, ScrollView, useWindowDimensions } from "react-native";
import Footer from "../components/Footer";
import styled, { useTheme } from "../styling/styled-components";

const StyledScrollView = styled(ScrollView)`
    background-color: ${({ theme }) => theme.colors.WHITE};
    color: ${({ theme }) => theme.colors.WHITE};
    flex-grow: 1;
    flex-direction: column;
    padding: ${({ theme }) =>
        theme.isDesktop
            ? theme.globalDesktopPadding
            : theme.globalMobilePadding}px;
    padding-top: ${({ theme }) =>
        theme.isMobile ? 24 : theme.globalDesktopPadding}px;
    min-height: ${({ minHeight }) => minHeight}px;
`;

const StyledFooter = styled(Footer)`
    margin-bottom: -${({ theme }) => (theme.isDesktop ? theme.globalDesktopPadding : theme.globalMobilePadding)}px;
    height: ${({ footerHeight }) => footerHeight}px;
`;

const Body = styled(View)`
    min-height: ${({ screenHeight }) => screenHeight}px;
    padding-bottom: 60px;
`;

type Props = {
    children?: JSX.Element | string | string[];
};

export default function ScreenView({ className, children, style }: Props) {
    const { isMobile } = useTheme();

    // controls the stickiness of the footer
    const footerHeight = 240;
    const screenHeight = useWindowDimensions().height;
    const minHeight = footerHeight + screenHeight;

    return (
        <StyledScrollView
            className={className}
            contentContainerStyle={{ flexGrow: 1 }}
            minHeight={minHeight}
            footerHeight={footerHeight}
            style={style}
            showsVerticalScrollIndicator={false}
        >
            <Body screenHeight={screenHeight}>{children}</Body>
            <StyledFooter footerHeight={footerHeight} />
        </StyledScrollView>
    );
}

ScreenView.defaultProps = {};
