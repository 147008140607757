import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { useMediaQuery } from "react-responsive";
import { ThemeProvider } from "./src/styling/styled-components";

import useCachedResources from "./src/hooks/useCachedResources";
import useColorScheme from "./src/hooks/useColorScheme";
import Navigation from "./src/navigation";
import { theme } from "./src/styling/theme";
import CrashReport from "./src/utils/crashReport";

import configs from "./configs.json";

CrashReport.init();

//update the header to pass ID token instead of access token to provide
//cognito attributes to AppSync in the identity context
configs.awsConfig.API.graphql_headers = async () => {
    try {
        await Auth.currentAuthenticatedUser();
        const session = await Auth.currentSession();
        return {
            Authorization: session.getIdToken().getJwtToken(),
        };
    } catch (err) {
        return;
    }
};

Amplify.configure(configs.awsConfig);

function App() {
    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isTablet = useMediaQuery({ minWidth: 767, maxWidth: 992 });
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const media = {
        isMobile,
        isTablet,
        isDesktop,
    };

    const fullTheme = { ...theme, ...media };

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <ThemeProvider theme={fullTheme}>
                <Navigation colorScheme={colorScheme} />
            </ThemeProvider>
        );
    }
}

export default App;
