import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import EmptyPlaceholder from "./EmptyPlaceholder";

const STRINGS = {
    TITLE: "Nothing to see here yet!",
    DESCRIPTION:
        "Recent activity will show a record of all of your check-outs and returns.",
};

type Props = {
    displayName?: string;
    style?: StyleProp<ViewStyle>;
};

export default function RecentActivityPlaceholder({ style }: Props) {
    return (
        <EmptyPlaceholder
            description={STRINGS.DESCRIPTION}
            title={STRINGS.TITLE}
            style={style}
        />
    );
}
