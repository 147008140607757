// TODO: if you are running into visual bugs for his on mobile
// in Firefox then address those issues in task TEC-1560

// react
import React, { useContext, useEffect } from "react";
import { View } from "react-native";

// style
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";

// context
import CampusContext from "../contexts/CampusContext";

// API
import { RentalPhase, RentalSearchDocument } from "../API";
import { useListRentalStepsForRental } from "../graphql/hooks/useListRentalStepsForRental";

// components
import PastDuePill from "./PastDuePill";
import Card from "./Card";
import Text from "../components/Text";

// images
import { CirclePlus } from "../assets/vectors";

// utils
import { formatInTimeZone } from "date-fns-tz";
import { assetTypeName } from "../utils/loopState";
import {
    getClosedDate,
    getExpirationDate,
} from "../utils/rentals/getPhaseTriggerDate";
import isLate from "../utils/rentals/isLate";
import isOverdue from "../utils/rentals/isOverdue";
import isAboutToExpire from "../utils/rentals/isAboutToExpire";

const STRINGS = {
    HEADER: (rental: RentalSearchDocument) => assetTypeName(rental.rentedAsset),
    CHECKED_OUT: (rental: RentalSearchDocument, campusTimezone: string) =>
        `Checked-out: ${
            rental?.createdAt
                ? formatInTimeZone(rental.createdAt, campusTimezone, "MMMM d")
                : ""
        }`,
    EXPIRED_BLURB_CHARGE: (
        rentalExpireDate: Date | null | undefined,
        campusTimezone: string
    ) =>
        rentalExpireDate
            ? `Return window closed. Eligible for a fine if return is not registered by ${formatInTimeZone(
                  rentalExpireDate,
                  campusTimezone,
                  "MMM d"
              )}.`
            : "",
    EXPIRED_BLURB_NO_CHARGE: (
        rentalExpireDate: Date | null | undefined,
        campusTimezone: string
    ) =>
        rentalExpireDate
            ? `Return window closed. Will be marked as “Failed” if return is not registered by ${formatInTimeZone(
                  rentalExpireDate,
                  campusTimezone,
                  "MMM d"
              )}.`
            : "",
    FINE_BLURB: (
        rentalCloseDate: Date | null | undefined,
        campusTimezone: string
    ) =>
        rentalCloseDate
            ? `Return window closes on ${formatInTimeZone(
                  rentalCloseDate,
                  campusTimezone,
                  "MMM d"
              )}`
            : "",
};

const StyledCard = styled(Card)`
    min-height: 100px;
`;

const StyledText = styled(Text)`
    color: ${({ color }) => color};
`;

const PaddedText = styled(StyledText)`
    padding-bottom: ${({ theme }) =>
        theme.isMobile ? theme.spacing.xsmall : theme.spacing.medium}px;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

// max-width here is to prevent the text from wrapping in a way
// that leaves the last word on it's own line alone
const CardLeftWrapper = styled(View)`
    align-self: flex-start;
    flex: 3;
    height: 100%;
    max-width: ${({ theme }) =>
        theme.isMobile ? "215" : theme.maxBodyWidth}px;
`;

const CardRightWrapper = styled(View)`
    align-items: end;
    display: flex;
    flex: 1;
    height: 100%;
    justify-content: space-between;
`;

type Props = {
    rental: RentalSearchDocument;
    onPress: () => void;
};

type PastDueBlurbProps = {
    rental: RentalSearchDocument;
};

export default function PastDueCard({ rental, onPress }: Props) {
    const { colors } = useTheme();

    const { localTimezone } = useContext(CampusContext).campusConfig;

    const [listRentalStepsForRental] = useListRentalStepsForRental();

    useEffect(() => {
        listRentalStepsForRental(rental?.rentalId);
    }, [rental]);

    return (
        <StyledCard
            onPress={onPress}
            header={assetTypeName(rental.rentedAsset)}
        >
            <CardLeftWrapper>
                <HeaderWrapper>
                    <StyledText color={colors.DEEP_BLUE_SEA} type="sh1">
                        {STRINGS.HEADER(rental)}
                    </StyledText>
                </HeaderWrapper>
                <PaddedText color={colors.DEEP_BLUE_SEA} type={"sh2"}>
                    {STRINGS.CHECKED_OUT(rental, localTimezone)}
                </PaddedText>
                <PastDueBlurb rental={rental} />
            </CardLeftWrapper>
            <CardRightWrapper>
                <PastDuePill rental={rental} />
                <CirclePlus />
            </CardRightWrapper>
        </StyledCard>
    );
}

function PastDueBlurb({ rental }: PastDueBlurbProps) {
    const { colors } = useTheme();
    const { canCharge, localTimezone } = useContext(CampusContext).campusConfig;

    const [listRentalStepsForRental, rentalSteps, rentalStepsInitiated] =
        useListRentalStepsForRental();

    useEffect(() => {
        listRentalStepsForRental(rental?.rentalId);
    }, [rental]);

    const rentalHasSteps =
        rentalStepsInitiated &&
        Array.isArray(rentalSteps) &&
        rentalSteps.length > 0;

    const closedAt = rentalHasSteps ? getClosedDate(rentalSteps) : null;
    const expiredAt = rentalHasSteps ? getExpirationDate(rentalSteps) : null;

    const rentalOverdue = isOverdue(rental) || isAboutToExpire(rental);

    let blurbText = "";
    let blurbColor = null;

    if (isLate(rental)) {
        blurbColor = colors.YELLOW_1;
        blurbText = STRINGS.FINE_BLURB(closedAt, localTimezone);
    } else if (rentalOverdue) {
        blurbColor = colors.RED_1;
        if (
            (isAboutToExpire(rental) && canCharge) ||
            rental.lastPhase == RentalPhase.FinePending
        ) {
            blurbText = STRINGS.EXPIRED_BLURB_CHARGE(expiredAt, localTimezone);
        }
        if (
            (isAboutToExpire(rental) && !canCharge) ||
            rental.lastPhase == RentalPhase.FailedPending
        ) {
            blurbText = STRINGS.EXPIRED_BLURB_NO_CHARGE(
                expiredAt,
                localTimezone
            );
        }
    }

    return (
        !!blurbColor && (
            <StyledText color={blurbColor} type={"sh2"}>
                {blurbText}
            </StyledText>
        )
    );
}

PastDueCard.defaultProps = {
    onPress: () => null,
};
