import * as Linking from "expo-linking";

export default {
    prefixes: [Linking.makeUrl("/")],
    config: {
        screens: {
            Root: {
                screens: {
                    OverviewScreen: {
                        path: "/home/:directive?",
                    },
                    ReturnLocationsScreen: {
                        path: "/return-locations",
                    },
                    DueSoonScreen: {
                        path: "/due-soon",
                    },
                    LateScreen: {
                        path: "/late",
                    },
                    ChargesScreen: {
                        path: "/charges",
                    },
                    ActivityScreen: {
                        path: "/activity",
                    },
                    SignInScreen: {
                        path: "/:campusSlug?",
                    },
                    ThirdPartySignInScreen: {
                        path: "/external-auth/:campusSlug?",
                    },
                },
            },
            NotFoundScreen: "*",
        },
    },
};
