/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const consumerQrData = /* GraphQL */ `
  query ConsumerQrData {
    consumerQRData {
      data
    }
  }
`;
export const adminGetConsumerQrData = /* GraphQL */ `
  query AdminGetConsumerQrData($organizationId: ID!, $consumerId: ID!) {
    adminGetConsumerQRData(
      organizationId: $organizationId
      consumerId: $consumerId
    ) {
      data
    }
  }
`;
export const getAppleWalletPass = /* GraphQL */ `
  query GetAppleWalletPass {
    getAppleWalletPass {
      presignedS3URL
    }
  }
`;
export const adminGetAppleWalletPass = /* GraphQL */ `
  query AdminGetAppleWalletPass($organizationId: ID!, $consumerId: ID!) {
    adminGetAppleWalletPass(
      organizationId: $organizationId
      consumerId: $consumerId
    ) {
      presignedS3URL
    }
  }
`;
export const getConsumerStats = /* GraphQL */ `
  query GetConsumerStats {
    getConsumerStats {
      consumerId
      openLoops
      totalClosedLoops
      totalOpenLoops
      updatedAt
    }
  }
`;
export const queryLoops = /* GraphQL */ `
  query QueryLoops(
    $fromTimestamp: String
    $toTimestamp: String
    $loopStatus: LoopStatus
    $paginationToken: String
  ) {
    queryLoops(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      loopStatus: $loopStatus
      paginationToken: $paginationToken
    ) {
      results {
        loopId
        asset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        consumer {
          consumerId
          phoneNumber
          name
          externalId
          email
          reuserId
          metadata {
            key
            value
          }
          marketingOptIn {
            email
            phoneNumber
          }
        }
        locations {
          openedAt {
            locationId
            name
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
          }
          closedAt {
            locationId
            name
            externalId
            address {
              line1
              line2
              line3
              postalCode
              country
              latitude
              longitude
              googlePlacesId
            }
            metadata {
              key
              value
            }
          }
        }
        externalId
        openedAt
        closedAt
        loopStatus
        resolutions {
          loopId
          resolution {
            resolutionId
            label
            useAgain
            name
            description
            metadata {
              key
              value
            }
          }
          resolutionStatus
          triggerResolutionAt
        }
        updatedAt
        metadata {
          key
          value
        }
      }
      nextToken
    }
  }
`;
export const getCampusConfig = /* GraphQL */ `
  query GetCampusConfig($organizationId: String, $campusSlug: String) {
    getCampusConfig(organizationId: $organizationId, campusSlug: $campusSlug) {
      campusSlug
      organizationId
      campusName
      canSignUp
      canCharge
      contactPhoneNumber
      comboCampus
      dueBackDays
      hidePass
      lateCopy
      latePeriod
      localTimezone
      messageOptIn
      messageFirstLoop
      messageLate
      pendingPeriod
      programDetailsURL
      returnLocations {
        locationId
        name
        externalId
        address {
          line1
          line2
          line3
          postalCode
          country
          latitude
          longitude
          googlePlacesId
        }
        metadata {
          key
          value
        }
      }
      returnLocationsURL
      s3LogoKey
      singleSignOnConfig {
        url
        mappings {
          externalField
          internalField
        }
      }
      signUpLink
      signUpPrompt
      signUpChallenges {
        organizationId
        challengeId
        challengeType
        inputName
        inputMinLength
        inputMaxLength
        inputRegEx
        isRequired
      }
      termsCopy
      termsLink
    }
  }
`;
export const listCampusConfigs = /* GraphQL */ `
  query ListCampusConfigs($paginationToken: String) {
    listCampusConfigs(paginationToken: $paginationToken) {
      results {
        campusSlug
        organizationId
        campusName
        canSignUp
        canCharge
        contactPhoneNumber
        comboCampus
        dueBackDays
        hidePass
        lateCopy
        latePeriod
        localTimezone
        messageOptIn
        messageFirstLoop
        messageLate
        pendingPeriod
        programDetailsURL
        returnLocations {
          locationId
          name
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
        }
        returnLocationsURL
        s3LogoKey
        singleSignOnConfig {
          url
          mappings {
            externalField
            internalField
          }
        }
        signUpLink
        signUpPrompt
        signUpChallenges {
          organizationId
          challengeId
          challengeType
          inputName
          inputMinLength
          inputMaxLength
          inputRegEx
          isRequired
        }
        termsCopy
        termsLink
      }
      nextToken
    }
  }
`;
export const adminGetConsumer = /* GraphQL */ `
  query AdminGetConsumer($organizationId: ID!, $consumerId: ID!) {
    adminGetConsumer(organizationId: $organizationId, consumerId: $consumerId) {
      consumerId
      phoneNumber
      name
      externalId
      email
      reuserId
      metadata {
        key
        value
      }
      marketingOptIn {
        email
        phoneNumber
      }
    }
  }
`;
export const adminGetLoop = /* GraphQL */ `
  query AdminGetLoop($organizationId: ID!, $loopId: ID!) {
    adminGetLoop(organizationId: $organizationId, loopId: $loopId) {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
          }
        }
        metadata {
          key
          value
        }
      }
      consumer {
        consumerId
        phoneNumber
        name
        externalId
        email
        reuserId
        metadata {
          key
          value
        }
        marketingOptIn {
          email
          phoneNumber
        }
      }
      locations {
        openedAt {
          locationId
          name
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
        }
        closedAt {
          locationId
          name
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
        }
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
            }
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
          }
        }
        resolutionStatus
        triggerResolutionAt
      }
      updatedAt
      metadata {
        key
        value
      }
    }
  }
`;
export const getLoop = /* GraphQL */ `
  query GetLoop($loopId: ID!) {
    getLoop(loopId: $loopId) {
      loopId
      asset {
        assetId
        externalId
        value
        assetType {
          assetTypeId
          name
          description
          externalId
          value
          metadata {
            key
            value
          }
        }
        metadata {
          key
          value
        }
      }
      consumer {
        consumerId
        phoneNumber
        name
        externalId
        email
        reuserId
        metadata {
          key
          value
        }
        marketingOptIn {
          email
          phoneNumber
        }
      }
      locations {
        openedAt {
          locationId
          name
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
        }
        closedAt {
          locationId
          name
          externalId
          address {
            line1
            line2
            line3
            postalCode
            country
            latitude
            longitude
            googlePlacesId
          }
          metadata {
            key
            value
          }
        }
      }
      externalId
      openedAt
      closedAt
      loopStatus
      resolutions {
        loopId
        resolution {
          resolutionId
          type {
            likeAssetTypeResolution {
              likeAssetTypeIds
            }
          }
          label
          useAgain
          name
          description
          metadata {
            key
            value
          }
        }
        resolutionStatus
        triggerResolutionAt
      }
      updatedAt
      metadata {
        key
        value
      }
    }
  }
`;
export const adminGetResolution = /* GraphQL */ `
  query AdminGetResolution($organizationId: ID!, $resolutionId: ID!) {
    adminGetResolution(
      organizationId: $organizationId
      resolutionId: $resolutionId
    ) {
      resolutionId
      type {
        likeAssetTypeResolution {
          likeAssetTypeIds
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
        sameAssetTypeResolution {
          resolutionTiming {
            timestamp
            duration
            roundToTime
            roundToTimezone
          }
        }
      }
      label
      useAgain
      name
      description
      metadata {
        key
        value
      }
    }
  }
`;
export const adminGetUserCompletedSignUpChallenges = /* GraphQL */ `
  query AdminGetUserCompletedSignUpChallenges(
    $phoneNumber: String!
    $organizationId: String!
    $confirmationCode: String!
  ) {
    adminGetUserCompletedSignUpChallenges(
      phoneNumber: $phoneNumber
      organizationId: $organizationId
      confirmationCode: $confirmationCode
    ) {
      phoneNumber
      organizationId
      confirmationCode
      completedChallenges {
        phoneNumber
        organizationId
        confirmationCode
        challengeResponse
        challenge {
          organizationId
          challengeId
          challengeType
          inputName
          inputMinLength
          inputMaxLength
          inputRegEx
          isRequired
        }
      }
      externalId
    }
  }
`;
export const searchRentals = /* GraphQL */ `
  query SearchRentals(
    $fromTimestamp: String!
    $toTimestamp: String!
    $rentalPhases: [RentalPhase!]
    $rentalStatuses: [RentalStatus!]
    $assetTypeIds: [String!]
    $searchQuery: String
    $size: Int
    $paginateFrom: Int
  ) {
    searchRentals(
      fromTimestamp: $fromTimestamp
      toTimestamp: $toTimestamp
      rentalPhases: $rentalPhases
      rentalStatuses: $rentalStatuses
      assetTypeIds: $assetTypeIds
      searchQuery: $searchQuery
      size: $size
      paginateFrom: $paginateFrom
    ) {
      totalHits
      rentals {
        rentalId
        loopId
        lastPhase
        status
        rentedAsset {
          assetId
          externalId
          value
          assetType {
            assetTypeId
            name
            description
            externalId
            value
            metadata {
              key
              value
            }
          }
          metadata {
            key
            value
          }
        }
        dueAt
        createdAt
        updatedAt
        resolvingLoop {
          loopId
          closedAt
        }
        charge {
          chargeStatus
        }
        forgivenessDetails {
          timestamp
          reason
        }
      }
      hitAggregations {
        key
        docCount
        subtotals {
          key
          docCount
        }
      }
    }
  }
`;
export const listRentalStepsForRental = /* GraphQL */ `
  query ListRentalStepsForRental(
    $rentalId: ID!
    $rentalStepStatus: RentalStepStatus
  ) {
    listRentalStepsForRental(
      rentalId: $rentalId
      rentalStepStatus: $rentalStepStatus
    ) {
      id
      rentalId
      rentalPhase
      status
      triggerAt
      statusUpdatedAt
      rentalStepConfigType
    }
  }
`;
