export default {
    data: {
        getConsumerStats: {
            consumerId: "mock-consumer-id",
            openLoops: 9,
            totalClosedLoops: 999,
            totalOpenLoops: 9999,
            updatedAt: "mock-opened-at",
        },
    },
};
