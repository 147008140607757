import _ from "lodash";
import React from "react";
import { View } from "react-native";
import Text from "../components/Text";
import styled, { useTheme } from "../styling/styled-components";
import { RentalSearchDocument } from "../API";
import {
    groupByDueDateLocal,
    groupByAssetType,
} from "../utils/rentals/groupBy";
import CountCard from "./common/CountCard";

const STRINGS = {
    TODAY_DUE: (count: number) => `${count} due today`,
    TOMORROW_DUE: (count: number) => `${count} due tomorrow`,
};

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const Container = styled(View)``;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const YellowText = styled(Text)`
    color: ${({ theme }) => theme.colors.YELLOW};
`;

const TODAY = new Date();
const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

type Props = {
    rentals: RentalSearchDocument[];
};

export default function DueAssetCard({ rentals, style }: Props) {
    const { isDesktop } = useTheme();

    const assetGroupedRentals = groupByAssetType(rentals);
    const assetTypes: string[] = Object.keys(assetGroupedRentals);

    const subHeaderText = (rentals: RentalSearchDocument[]): string => {
        const dueAtGroupedRentals = groupByDueDateLocal(rentals);

        const todayDue = _.get(
            dueAtGroupedRentals,
            TODAY.toLocaleDateString("en-US"),
            []
        );
        const tomorrowDue = _.get(
            dueAtGroupedRentals,
            TOMORROW.toLocaleDateString("en-US"),
            []
        );

        if (todayDue.length > 0) {
            return STRINGS.TODAY_DUE(todayDue.length);
        } else if (tomorrowDue.length > 0) {
            return STRINGS.TOMORROW_DUE(tomorrowDue.length);
        } else {
            return "";
        }
    };

    return (
        <Container style={style}>
            {assetTypes.length > 0
                ? assetTypes.map((assetTypeName, idx) => (
                      <CountCard
                          key={idx}
                          count={assetGroupedRentals[assetTypeName].length}
                      >
                          <HeaderWrapper>
                              <BlueText type="sh1">{assetTypeName}</BlueText>
                          </HeaderWrapper>
                          <YellowText type={isDesktop ? "b1" : "b2"}>
                              {subHeaderText(
                                  assetGroupedRentals[assetTypeName]
                              )}
                          </YellowText>
                      </CountCard>
                  ))
                : null}
        </Container>
    );
}

DueAssetCard.defaultProps = {};
