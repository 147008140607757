import React, { useEffect } from "react";
import { View } from "react-native";
import Text from "../components/Text";
import * as queries from "../graphql/queries";
import { useQuery } from "../graphql/hooks/query";
import { ReusePassQr } from "../API";
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";
import { Trees } from "../assets/vectors";
import { prettifyNumber } from "../utils/helpers";

const STRINGS = {
    LOAD_FAILED: "We couldn't load your stats, try again later.",
    HEADER: "Trash stats",
    SUB_HEADER:
        "Each container you return helps reduce waste, save water and divert greenhouse gases from the atmosphere.",
    CLOSED_LOOPS_DESCR:
        "FEWER CONTAINERS IN THE TRASH THANKS TO YOUR PARTICIPATION",
};

const Container = styled(View)`
    background-color: ${({ theme }) => theme.colors.LIME_SQUEEZE}60;
    border-radius: 2px;
    padding-vertical: ${({ theme }) => theme.spacing.large}px;
    padding-horizontal: ${({ theme }) =>
        theme.isDesktop
            ? theme.globalDesktopPadding
            : theme.globalMobilePadding}px;
    justiy-content: space-between;
    z-index: -1000;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const StatsWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const TreesWrapper = styled(View)`
    margin-horizontal: -${({ theme }) => theme.spacing.huge}px;
    margin-bottom: -${({ theme }) => theme.spacing.large}px;
    width: 120vw;
`;

const GreenText = styled(Text)`
    color: ${({ theme }) => theme.colors.GREEN};
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const SubHeaderText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ClosedLoopsText = styled(Text)`
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
    color: ${({ theme }) => theme.colors.GREEN_1};
`;

type Props = {};

export default function TrashStats({ style }: Props) {
    const { isMobile } = useTheme();
    const [getConsumerStats, data, loading, error]: ReusePassQr = useQuery(
        queries.getConsumerStats
    );

    useEffect(() => {
        getConsumerStats();
    }, []);

    const { totalClosedLoops } = data || {};

    if (loading) return null;
    if (error) return <Text type="b2">{STRINGS.LOAD_FAILED}</Text>;
    if (!data) return <Text type="b2">{STRINGS.LOAD_FAILED}</Text>;

    return (
        <Container style={style}>
            <HeaderWrapper>
                <GreenText type="sh1">{STRINGS.HEADER}</GreenText>
                <SubHeaderText type="b2">{STRINGS.SUB_HEADER}</SubHeaderText>
            </HeaderWrapper>
            <StatsWrapper>
                <GreenText type="statement">
                    {prettifyNumber(totalClosedLoops || 0)}
                </GreenText>
                <ClosedLoopsText type="eyebrow">
                    {STRINGS.CLOSED_LOOPS_DESCR}
                </ClosedLoopsText>
            </StatsWrapper>
            <TreesWrapper>
                <Trees />
            </TreesWrapper>
        </Container>
    );
}

TrashStats.defaultProps = {};
