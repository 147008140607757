// react
import React, { useEffect, useRef } from "react";

// style
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";

// components
import { Animated, View } from "react-native";

const Container = styled(View)`
    height: 20px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.SMOG};
    border-radius: 0px;
    overflow: hidden;
`;

export const ProgressBar = () => {
    const { colors } = useTheme();
    const progress = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        Animated.timing(progress, {
            toValue: 1,
            duration: 5000, // 5 seconds
            useNativeDriver: false,
        }).start();
    }, [progress]);

    const widthInterpolated = progress.interpolate({
        inputRange: [0, 1],
        outputRange: ["0%", "100%"],
    });

    return (
        <Container>
            <Animated.View
                style={{
                    width: widthInterpolated,
                    height: "100%",
                    backgroundColor: colors.GREEN_3,
                }}
            />
        </Container>
    );
};
