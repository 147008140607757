import React from "react";
import { Pressable, View, ViewStyle } from "react-native";
import Text from "../components/Text";
import { LeftChevron } from "../assets/vectors";
import styled from "../styling/styled-components";
import { useNavigation } from "@react-navigation/native";

const Container = styled(Pressable)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: ${({ theme }) => theme.spacing.medium}px;
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xlarge : theme.spacing.medium}px;
    margin-top: -${({ theme }) => theme.spacing.large}px;
`;

const ChevronWrapper = styled(View)`
    margin-left: -6px;
`;

const HeaderLeft = styled(Pressable)`
    flex-direction: row;
    align-items: center;
    flex: 1;
`;

const HeaderRight = styled(View)`
    flex-direction: row;
    align-self: center;
    justify-content: flex-end;
    flex: 1;
`;

const LabelText = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    padding-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    onPress: () => void;
    label: string;
    headerRight?: () => void;
    tryGoBack: boolean;
    style: ViewStyle;
};

export default function BackHeader({
    onPress,
    label,
    headerRight,
    style,
    tryGoBack,
}: Props) {
    const navigation = useNavigation();

    const handleGoBack = () => {
        if (tryGoBack) {
            navigation.canGoBack() ? navigation.goBack() : onPress();
        } else {
            onPress();
        }
    };

    return (
        <Container style={style}>
            <HeaderLeft onPress={handleGoBack}>
                <ChevronWrapper>
                    <LeftChevron />
                </ChevronWrapper>
                <LabelText type="eyebrow">{label}</LabelText>
            </HeaderLeft>
            <HeaderRight>{headerRight && headerRight()}</HeaderRight>
        </Container>
    );
}

BackHeader.defaultProps = { tryGoBack: true };
