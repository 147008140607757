import {
    NavigationContainer,
    DefaultTheme,
    DarkTheme,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import React from "react";
import { ColorSchemeName } from "react-native";
import NotFoundScreen from "../screens/NotFoundScreen";
import { RootStackParamList } from "../types";
import Navigator from "./Navigator";
import LinkingConfiguration from "./LinkingConfiguration";
import ErrorBoundary from "../components/ErrorBoundary";

export default function Navigation({
    colorScheme,
}: {
    colorScheme: ColorSchemeName;
}) {
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
        >
            <RootNavigator />
        </NavigationContainer>
    );
}

const Stack = createStackNavigator<RootStackParamList>();
function RootNavigator() {
    return (
        <ErrorBoundary>
            <Stack.Navigator
                screenOptions={{ headerShown: false, title: "ReusePass" }}
                initialRouteName={"Root"}
            >
                <Stack.Screen name="Root" component={Navigator} />
                <Stack.Screen
                    name="NotFoundScreen"
                    component={NotFoundScreen}
                    options={{ title: "Oops!" }}
                />
            </Stack.Navigator>
        </ErrorBoundary>
    );
}
