//react
import React from "react";

//components
import { ActivityIndicator } from "react-native";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import Text from "../components/Text";

// style
import styled, { useTheme } from "../styling/styled-components";

//assets
import {
    BlackCheckCircle,
    BlackCheckCircleDesktop,
    CheckCircle,
    GreenCheckCircleDesktop,
} from "../assets/vectors";
import { COLORS } from "../styling";

const STRINGS = {
    RETURNS_RECEIVED: "Returns received",
    TITLE_SCENARIO_1: "You’re leading the change!",
    DESCR_SCENARIO_1:
        "Thank you for helping our community reduce waste and make a real impact. Keep it up!",
    TITLE_SCENARIO_2: "You’re making an impact– keep it up!",
    DESCR_SCENARIO_2:
        "Each container returned means one less container in the trash. Thank you for helping your community drive change.",
    TITLE_SCENARIO_3: "We’re counting on you to close the loop!",
    DESCR_SCENARIO_3:
        "Every return reduces waste. Thanks for helping our community drive change!",
    TITLE_SCENARIO_4: "Don’t ghost us on returns!",
    DESCR_SCENARIO_4:
        "Do the right thing and return your containers to help reduce waste. We're in this together!",
    TITLE_SCENARIO_5: "Don’t let your community down",
    DESCR_SCENARIO_5:
        "Not returning containers does more harm than good for our environment. \n You can fix this!",
    TITLE_SCENARIO_6: "The future is bright!",
    DESCR_SCENARIO_6:
        "When ReusePass members return containers, we keep waste out of landfills and create a cleaner future together.",
};

const Container = styled.View`
    justify-content: center;
    align-items: center;
    ${({ theme }) =>
        theme.isDesktop &&
        `
        align-self: start;
    `}
`;

const TextContainer = styled.View`
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${({ bgColor }) => bgColor};
`;

const StatsWrapper = styled.View`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
`;

const NumberReceived = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    ${({ theme }) =>
        theme.isDesktop &&
        `
        font-size: 60px;
        line-height: 72px;
    `}
`;

const TotalExpected = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    ${({ theme }) =>
        !theme.isDesktop &&
        `
        font-size: 14px;
        line-height: 16.8px;
    `}
`;

const LabelReceived = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    margin-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.large : theme.spacing.xsmall}px;
`;

const Wrapper = styled.View`
    ${({ theme }) =>
        theme.isDesktop &&
        `
        max-width: 400px;
    `}
`;

const TitleActivityStatus = styled(Text)`
    color: ${({ textColor }) => textColor};
    margin-top: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xlarge : theme.spacing.medium}px;
    text-align: center;
    padding: 0px
        ${({ theme }) =>
            theme.isDesktop ? theme.spacing.large : theme.spacing.medium}px;
`;

const DescrActivityStatus = styled(Text)`
    margin-top: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.small : theme.spacing.xsmall}px;
    text-align: center;
    padding: 0px
        ${({ theme }) =>
            theme.isDesktop ? theme.spacing.large : theme.spacing.medium}px;
`;

type Props = {
    isLoading?: boolean;
    numberExpected: number;
    numberReceived: number;
};

type Colors = {
    backgroundColor: string;
    tintColor: string;
    titleColor: string;
    scenarioId: string;
};

type ColorsType = typeof COLORS;

function determineScenarioProps(
    colors: ColorsType,
    loopsOpened: number,
    loopsClosed: number
): Colors {
    const difference = loopsOpened - loopsClosed;

    if (loopsOpened === 0 && loopsClosed === 0) {
        return {
            backgroundColor: colors.WHITE,
            tintColor: colors.MARINE_LAYER,
            titleColor: colors.DEEP_BLUE_SEA,
            scenarioId: "6",
        };
    }

    if (difference === 0 && loopsOpened >= 1) {
        return {
            backgroundColor: colors.GREEN_4,
            tintColor: colors.GREEN_3,
            titleColor: colors.GREEN_1,
            scenarioId: "1",
        };
    }

    if (difference > 0 && difference <= 5) {
        return {
            backgroundColor: colors.WHITE,
            tintColor: colors.GREEN_3,
            titleColor: colors.GREEN_1,
            scenarioId: "2",
        };
    }

    if (difference > 5 && difference <= 15) {
        return {
            backgroundColor: colors.WHITE,
            tintColor: colors.DEEP_BLUE_SEA,
            titleColor: colors.DEEP_BLUE_SEA,
            scenarioId: "3",
        };
    }

    if (difference > 15 && difference <= 30) {
        return {
            backgroundColor: colors.WHITE,
            tintColor: colors.YELLOW_2,
            titleColor: colors.DEEP_BLUE_SEA,
            scenarioId: "4",
        };
    }

    if (difference > 30) {
        return {
            backgroundColor: colors.WHITE,
            tintColor: colors.RED_3,
            titleColor: colors.DEEP_BLUE_SEA,
            scenarioId: "5",
        };
    }

    return {
        backgroundColor: colors.WHITE,
        tintColor: colors.MARINE_LAYER,
        titleColor: colors.DEEP_BLUE_SEA,
        scenarioId: "1",
    };
}

function calculatePercentageReceived(
    numberExpected: number,
    numberReceived: number
) {
    if (numberExpected === 0) {
        return 0;
    }

    const percentageReceived = (numberReceived / numberExpected) * 100;
    return percentageReceived;
}

const DialChart = ({ isLoading, numberExpected, numberReceived }: Props) => {
    const { colors, isDesktop } = useTheme();
    const percentage = calculatePercentageReceived(
        numberExpected,
        numberReceived
    );
    const { backgroundColor, tintColor, titleColor, scenarioId } =
        determineScenarioProps(colors, numberExpected, numberReceived);

    if (isLoading) return <ActivityIndicator />;

    return (
        <Container>
            <AnimatedCircularProgress
                size={isDesktop ? 400 : 156}
                width={isDesktop ? 28 : 10}
                fill={percentage}
                tintColor={tintColor}
                backgroundColor={colors.SMOG}
                rotation={0}
                lineCap="butt"
            >
                {() => (
                    <TextContainer bgColor={backgroundColor}>
                        <StatsWrapper>
                            <NumberReceived type="h1">
                                {`${numberReceived}`}
                            </NumberReceived>
                            <TotalExpected type={`${isDesktop ? "h1" : "h3"}`}>
                                {`/ ${numberExpected}`}
                            </TotalExpected>
                        </StatsWrapper>
                        <LabelReceived type={`${isDesktop ? "h2" : "eyebrow"}`}>
                            {STRINGS.RETURNS_RECEIVED}
                        </LabelReceived>
                        {isDesktop && scenarioId === "1" ? (
                            <GreenCheckCircleDesktop />
                        ) : null}
                        {isDesktop && scenarioId !== "1" ? (
                            <BlackCheckCircleDesktop />
                        ) : null}
                        {!isDesktop && scenarioId === "1" ? (
                            <CheckCircle />
                        ) : null}
                        {!isDesktop && scenarioId !== "1" ? (
                            <BlackCheckCircle />
                        ) : null}
                    </TextContainer>
                )}
            </AnimatedCircularProgress>
            <Wrapper>
                <TitleActivityStatus
                    type={`${isDesktop ? "h2" : "h3"}`}
                    textColor={titleColor}
                >
                    {
                        STRINGS[
                            `TITLE_SCENARIO_${scenarioId}` as keyof typeof STRINGS
                        ]
                    }
                </TitleActivityStatus>
                <DescrActivityStatus type="b2">
                    {
                        STRINGS[
                            `DESCR_SCENARIO_${scenarioId}` as keyof typeof STRINGS
                        ]
                    }
                </DescrActivityStatus>
            </Wrapper>
        </Container>
    );
};

export default DialChart;
