// react
import React, { useState, useContext } from "react";
import { Pressable, View } from "react-native";

// context
import CampusContext from "../contexts/CampusContext";

// style
import styled, { useTheme } from "../styling/styled-components";

// components
import AuthErrorEncountered from "../components/AuthErrorEncountered";
import Button from "../components/Button";
import CheckBox from "../components/CheckBox";
import Errorable from "../components/common/Errorable";
import Separator from "../components/Separator";
import Text from "../components/Text";

// utils
import { openURL } from "../utils/helpers";

// constants
import { PRIVACY_URL, TERMS_URL } from "../utils/constants";


const STRINGS = {
    ACCEPT_TERMS_BEFORE: "I accept the ",
    ACCEPT_TERMS_AFTER: " of ReusePass.",
    AND: " and ",
    CONTINUE: "Continue",
    DESCRIPTION_CONDITION: "Containers are due back 3 days after check-out. Containers that are not received within 3 days will be marked as late. Once a container is late, you have 48 hours to return it before it is recorded on your account as a failed return.",
    EMAIL_OPTIN: "I’d like to receive occasional email updates about the ReusePass program and reducing waste.",
    PRIVACY_POLICY: "privacy policy",
    REQUIRED: "REQUIRED",
    TERMS: "terms and conditions",
    TITLE: "Almost done! Review and accept the T&C’s and you’re good to go.",
    VIEW_TERMS: "View full terms and conditions",
};

const Container = styled(View)`
    ${({ theme }) =>
        theme.isDesktop &&
        `
        align-items: center;
        width: 50vw;
        margin: 0 auto 60px;
    `}
`;

const Prompt = styled(View)`
    margin-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.large : theme.spacing.small}px;
`;

const DetailWrapper = styled(View)`
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.huge : theme.spacing.xlarge}px;
    text-align: ${({ theme }) => (theme.isDesktop ? "center" : "left")};
`;

const Body = styled(View)`
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.spacing.huge}px;
    align-items: flex-start;
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;

    ${({ theme }) =>
        theme.isDesktop &&
        `
        width: 100%;
        max-width: 428px;
    `}
`;

const BodyLeft = styled(CheckBox)``;

const BodyRight = styled(View)`
    text-align: left;
    max-width: 300px;
    padding-left: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.medium : theme.spacing.small}px;

    ${({ theme }) =>
        theme.isDesktop &&
        `
        max-width: 428px;
    `}
`;

const StyledButton = styled(Button)`
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    width: ${({ theme }) => (theme.isMobile ? "100%" : "294px")};
    ${({ theme }) => theme.isDesktop && "margin: 0 auto;"}
`;

const TermsLink = styled(Pressable)``;

const TermsLinkLabel = styled(Text)`
    letter-spacing: -0.32px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const RequiredText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
    margin-top: ${({ theme }) => theme.spacing.small}px;
`;

const SeparatorTerms = styled(Separator)`
    margin-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

type Props = {
    acceptTerms: (arg0: string, arg1: object) => void;
    authFailRetry: () => void;
};

export default function AcceptTerms({ acceptTerms, authFailRetry, style }: Props) {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [emailOptinAccepted, setEmailOptinAccepted] = useState(true);
    const [errorEncountered, setErrorEncountered] = useState(false);

    const { colors } = useTheme();

    const { termsCopy, termsLink } = useContext(CampusContext).campusConfig;

    const handleAcceptTerms = async () => {
        setSubmitLoading(true);
        const marketingOptIn = {
            email: emailOptinAccepted,
            phoneNumber: false,
        };
        const [signUpComplete, signUpProcess] = await acceptTerms("true", { marketingOptIn: marketingOptIn });
        if(!signUpComplete) {
            setSubmitLoading(false);
            setErrorEncountered(true);
        }
    };
    
    const AuthErrorComponent = () => {
        return (
            <AuthErrorEncountered onTryAgain={authFailRetry} />
        );
    };

    return (
        <Errorable error={errorEncountered} ErrorComponent={AuthErrorComponent}>
            <Container style={style}>
                <Prompt>
                    <BlueText type="h1">{STRINGS.TITLE}</BlueText>
                </Prompt>
                <DetailWrapper>
                    <BlueText type="b1">{STRINGS.DESCRIPTION_CONDITION}</BlueText>
                </DetailWrapper>
                <Separator/>
                <Body>
                    <BodyLeft accessibilityLabel="I accept the terms and conditions" checked={termsAccepted} onValueChange={() => setTermsAccepted(!termsAccepted)}/>
                    <BodyRight>
                        <BlueText type="h3">
                            {STRINGS.ACCEPT_TERMS_BEFORE}
                            <TermsLink
                                onPress={() => openURL(termsLink || TERMS_URL)}
                            >
                                <TermsLinkLabel type="link">{STRINGS.TERMS}</TermsLinkLabel>
                            </TermsLink>
                            {STRINGS.AND}
                            <TermsLink
                                onPress={() => openURL(PRIVACY_URL)}
                            >
                                <TermsLinkLabel type="link">{STRINGS.PRIVACY_POLICY}</TermsLinkLabel>
                            </TermsLink>
                            {STRINGS.ACCEPT_TERMS_AFTER}
                        </BlueText>
                        <RequiredText type="b2">{STRINGS.REQUIRED}</RequiredText>
                    </BodyRight>
                </Body>
                <Separator/>
                <Body>
                    <BodyLeft accessibilityLabel="I accept to receive occasional email updates" checked={emailOptinAccepted} onValueChange={() => setEmailOptinAccepted(!emailOptinAccepted)}/>
                    <BodyRight>
                        <BlueText type="h3">{STRINGS.EMAIL_OPTIN}</BlueText>
                    </BodyRight>
                </Body>
                {termsAccepted && (
                    <StyledButton
                        label={STRINGS.CONTINUE}
                        type="primary"
                        size="large"
                        isLoading={submitLoading}
                        onPress={handleAcceptTerms}
                    />
                )}
            </Container>
        </Errorable>
    );
}

AcceptTerms.defaultProps = {};
