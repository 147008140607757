import * as queries from "../queries";

import consumerQrData from "./consumerQrData";
import getConsumerStats from "./getConsumerStats";
import { getCampusConfig, listCampusConfigs } from "./campusConfigs";

export default {
    [queries.consumerQrData]: consumerQrData,
    [queries.getConsumerStats]: getConsumerStats,
    [queries.getCampusConfig]: getCampusConfig,
    [queries.listCampusConfigs]: listCampusConfigs,
};
