import React from "react";
import { ActivityIndicator, Modal, View } from "react-native";
import styled, { useTheme } from "../styling/styled-components";
const Wrapper = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.HURRICANE};
    opacity: 0.5;
`;

type Props = {
    isVisible: boolean;
    className?: string;
};

export default function LoadingOverlay({ isVisible, className }: Props) {
    const { colors } = useTheme();

    return (
        <Modal
            className={className}
            visible={isVisible}
            transparent={true}
            animationType={"none"}
        >
            <Wrapper className={className}>
                <ActivityIndicator color={colors.ACTIVATED_CHARCOAL} />
            </Wrapper>
        </Modal>
    );
}

LoadingOverlay.defaultProps = { className: "LoadingOverlay" };
