// react
import React, { useCallback, useState } from "react";
import { useNavigation } from "@react-navigation/native";

// style
import styled, { useTheme } from "../styling/styled-components";
import "react-phone-number-input/style.css";

// components
import { Pressable, View } from "react-native";
import Button from "./Button";
import SignInInput from "./LoginTextInput";
import Text from "./Text";

// type
import { InitiateSignUpInput, SignUpChallenge } from "../API";

const STRINGS = {
    CTA: "Continue",
    SKIP_LINK: "Skip this step",
    // TODO: make dynamic for other integration providers
    TRY_AGAIN: "Try again",
};

const Container = styled(View)``;

const Prompt = styled(View)``;

const NextButton = styled(Button)`
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    width: ${({ theme }) => (theme.isMobile ? "100%" : "294px")};
    margin: ${({ theme: { isMobile, spacing } }) =>
            isMobile ? spacing.xlarge : spacing.yuge}px
        auto 0;
`;

const SkipLink = styled(Pressable)`
    margin-top: ${({ theme }) => theme.spacing.large}px;
    color: ${({ theme }) => theme.colors.WAVE_STORM};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
`;

const SkipText = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

const SkipSymbol = styled(SkipText)`
    margin-left: ${({ theme }) => theme.spacing.xsmall}px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const ErrorText = styled(Text)`
    color: ${({ theme }) => theme.colors.YELLOW};
    padding-top: ${({ theme }) => theme.spacing.medium}px;
`;

const ChallengeResponseInput = styled(SignInInput)`
    margin-top: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.yuge : theme.spacing.huge}px;
    color: ${({ theme }) => theme.colors.GOOD_GREEN};
`;

type Props = {
    submitChallenge: (code: string) => Promise<[boolean, boolean]>;
    challengeInfo: SignUpChallenge;
    inputRegEx: string;
    title: string;
    label: string;
    placeholder: string;
    errorText: string;
};

export default function Challenge({
    submitChallenge,
    challengeInfo,
    inputRegEx,
    title,
    label,
    placeholder,
    errorText,
}: Props) {
    const [loading, setLoading] = useState(false);
    const [challengeResponse, setChallengeResponse] = useState("");

    const [challengeResponseError, setChallengeResponseError] = useState(false);
    const [challengeResponseSuccess, setChallengeResponseSuccess] =
        useState(false);

    const { colors } = useTheme();
    const navigation = useNavigation();

    const isChallengeOptional = typeof challengeInfo.isRequired == "boolean" ? !challengeInfo.isRequired : false;

    // We have similar but different validation functions for input and submit because
    // there can be times where a user is inputting a generally valid input, but not one
    // that is valid for the specific campus they are authenticating with. Separating
    // these functions will show allow the user to click continue if they have a
    // generally valid input and see the error message that asks for a more specific
    // input that the campus considers valid.
    const validateInput = (input: string): boolean => {
        let checkRegEx = null;
        if(inputRegEx) {
            checkRegEx = new RegExp(inputRegEx);
        } else if(challengeInfo.inputRegEx){
            checkRegEx = new RegExp(challengeInfo.inputRegEx);
        } else {
            return isResponseCorrectLength(input);
        }

        return checkRegEx.test(input);
    }

    const doesChallengeResponsePass = (challengeResponse: string): boolean => {
        if (isChallengeOptional) return true;

        const checkRegEx = new RegExp(challengeInfo.inputRegEx);
        if (!challengeInfo.inputRegEx || checkRegEx.test(challengeResponse)) {
            return isResponseCorrectLength(challengeResponse);
        }
        return false;
    };

    const isResponseCorrectLength = (response: string): boolean => {
        if (
            (challengeInfo.inputMinLength && response.length < challengeInfo.inputMinLength) ||
            (challengeInfo.inputMaxLength && response.length > challengeInfo.inputMaxLength)
        ) {
            return false;
        }
        return true;
    };

    const submit = useCallback(async () => {
        if(!doesChallengeResponsePass(challengeResponse)) {
            setChallengeResponseError(true);
            return;
        }
        setLoading(true);
        setChallengeResponseError(false);
        try {
            await submitChallenge(challengeResponse);
            setLoading(false);
            setChallengeResponse("");
        } catch (err) {
            console.error("Error with " + label, JSON.stringify(err));
            setChallengeResponseError(true);
            setLoading(false);
        }
    }, [submitChallenge, challengeResponse]);

    const getColor = () => {
        if (challengeResponseError) return colors.ROJO;
        if (challengeResponseSuccess) return colors.GOOD_GREEN;
        return null;
    };

    /**
     *  Skippable challenge then skipping is an acceptable way to complete the challenge
     *  tho challengeAccepted would remain true
     */
    const skipOptionalChallenge = useCallback(async () => {
        setLoading(true);
        setChallengeResponseError(false);
        try {
            //we make sure the challenge response is empty, 
            //because if the user start typing leters and 
            //then decide to skip it then let's just send empty string
            await submitChallenge("");
            setLoading(false);
            setChallengeResponse("");
        } catch (err) {
            console.error("Error in skipOptionalChallenge with " + label, JSON.stringify(err));
            setChallengeResponseError(true);
            setLoading(false);
        }
    }, [submitChallenge, challengeResponse]);

    return (
        <Container>
            <Prompt>
                <BlueText type="h1">{title}</BlueText>
            </Prompt>
            <ChallengeResponseInput
                color={getColor()}
                value={challengeResponse}
                label={label}
                labelColor={colors.HURRICANE}
                onChange={(text) => {
                    setChallengeResponse(text);
                    if (
                        challengeResponseError &&
                        validateInput(text)
                    ) {
                        setChallengeResponseError(false);
                    }
                }}
                onBlur={() => {
                    if (!validateInput(challengeResponse)) {
                        setChallengeResponseError(true);
                    } else {
                        setChallengeResponseError(false);
                    }
                }}
                length={challengeInfo.inputMaxLength || 99}
                type="email"
                placeholder={placeholder}
                onSubmitEditing={() => {
                    if (validateInput(challengeResponse)) {
                        submit();
                    }
                }}
            />
            {challengeResponseError && (
                <>
                    <ErrorText type="h3">{errorText}</ErrorText>
                </>
            )}
            {challengeResponse.length &&
                validateInput(challengeResponse || "") && (
                    <NextButton
                        label={STRINGS.CTA}
                        type="primary"
                        size="large"
                        onPress={submit}
                        isLoading={loading}
                    />
                )}
            {isChallengeOptional && (
                <SkipLink onPress={skipOptionalChallenge}>
                    <SkipText type="h3">{STRINGS.SKIP_LINK}</SkipText>
                    <SkipSymbol>{">"}</SkipSymbol>
                </SkipLink>
            )}
        </Container>
    );
}

