// react
import React from "react";

// style
import styled, { useTheme } from "../../styling/styled-components";

// images
import { RightChevron } from "../../assets/vectors";

// components
import Card from "../Card";
import Text from "../../components/Text";
import { View } from "react-native";


const CountText = styled(Text)`
    color: ${({ count, theme }) =>
        count == 0 ? theme.colors.MARINE_LAYER : theme.colors.DEEP_BLUE_SEA};
`;

const ChevronWrapper = styled(View)`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

const CardLeftWrapper = styled(View)`
    flex: 3;
`;

const CardRightWrapper = styled(View)`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

type Props = {
    children: JSX.Element | string | string[];
    count: number;
    onPress: () => void;
};

export default function CountCardButton({
    children,
    count,
    onPress,
    style
}: Props) {
    const { colors, isDesktop } = useTheme();

    const countDisplayValue = count != null ? (count > 99 ? "99+" : count) : "";

    return (
        <Card onPress={onPress} style={style} header={0} >
            <CardLeftWrapper>
                {children}
            </CardLeftWrapper>
            <CardRightWrapper>
                <CountText type="statement" count={count} >
                    {countDisplayValue}
                </CountText>
                <ChevronWrapper>
                    <RightChevron />
                </ChevronWrapper>
            </CardRightWrapper>
        </Card>
    );
}
