import React from "react";
import { View, Pressable } from "react-native";
import Text from "../components/Text";
import styled from "../styling/styled-components";
import { Pin } from "../assets/vectors";

const STRINGS = {
    RETURN_LOCATIONS: "SEE RETURN LOCATIONS",
};

const ReturnLocations = styled(Pressable)`
    flex-direction: row;
    justify-content: space-between;
`;

const ActionDivider = styled(View)`
    width: ${({ theme }) => theme.spacing.xsmall}px;
`;
const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.WAVE_STORM};
`;

type Props = {
    returnLocationsURL: string | null | undefined;
};

export default function NavReturnLocations({ returnLocationsURL }: Props) {
    return (
        <ReturnLocations
            onPress={() =>
                returnLocationsURL && window.open(returnLocationsURL, "_blank")
            }
        >
            <Pin />
            <ActionDivider />
            <BlueText type="eyebrow">{STRINGS.RETURN_LOCATIONS}</BlueText>
        </ReturnLocations>
    );
}
