// react
import React, { useContext, useEffect } from "react";
import { View } from "react-native";

// context
import CampusContext from "../contexts/CampusContext";

// style
import styled, { useTheme } from "../styling/styled-components";

// API
import { RentalSearchDocument } from "../API";
import { useListRentalStepsForRental } from "../graphql/hooks/useListRentalStepsForRental";

// components
import PastDuePill from "./PastDuePill";
import Text from "../components/Text";

// utils
import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { assetTypeName } from "../utils/loopState";
import isLate from "../utils/rentals/isLate";
import isOverdue from "../utils/rentals/isOverdue";
import isAboutToExpire from "../utils/rentals/isAboutToExpire";
import {
    getClosedDate,
    getExpirationDate,
} from "../utils/rentals/getPhaseTriggerDate";
import { prettifyPhoneNumber } from "../utils/helpers";

const STRINGS = {
    ALREADY_HEADER: "Already returned it?",
    ALREADY:
        "If you’ve already returned it, don’t worry, you’re all set! Your return will be reflected once the item is scanned-in.",
    ALREADY_OVERDUE: `If you returned the rental over 48-hours ago and it has not been reflected, please text our customer support team at: `,
    CLOSE_DAY_BLURB_PART1: (
        rental: RentalSearchDocument,
        dueAtDate: Date | null,
        campusTimezone: string
    ) => {
        const dueDateString = dueAtDate
            ? `was due on ${formatInTimeZone(
                  dueAtDate,
                  campusTimezone,
                  "MMMM d"
              )}`
            : "was due";
        return `A ${assetTypeName(
            rental.rentedAsset
        )} ${dueDateString} but has not been received.`;
    },
    CLOSE_DAY_BLURB_PART2: (rentalCloseDate: Date) =>
        ` The return window closes at the end of the day on ${format(
            rentalCloseDate,
            "MMMM d"
        )}.`,
    DUE_RECENTLY: (
        rental: RentalSearchDocument,
        campusTimezone: string,
        pendingPeriod: string
    ) => {
        const dueDateString = rental.dueAt
            ? `was due on ${formatInTimeZone(
                  rental.dueAt,
                  campusTimezone,
                  "MMMM d"
              )}`
            : "was due";
        return `A ${assetTypeName(
            rental.rentedAsset
        )} ${dueDateString} but has not been received. It will be considered pending for ${pendingPeriod} after the due date to allow the dining team time to scan it back-in.`;
    },
    HEADER: (rental: RentalSearchDocument) => assetTypeName(rental.rentedAsset),
    FORGOT_HEADER: "Forgot to return it?",
    FORGOT_PAST_DUE:
        "If you haven’t returned it yet, please make sure to return it as soon as possible.",

    FORGOT_LATE: (canCharge: boolean) =>
        canCharge
            ? "Please return it before the return window closes or the rental will be marked as failed and will be eligible for a fine."
            : "Please return it before the return window closes or the rental will be marked as failed.",
    FORGOT_OVERDUE:
        "It’s never too late to return a container to be reused. Each return helps save waste, water and emissions from the atmosphere.",
    NOT_RECEIVED: "A container was due recently but has not been received. ",
    NOT_RECEIVED_OVERDUE_PART1: (
        rental: RentalSearchDocument,
        dueAtDate: Date | null,
        campusTimezone: string
    ) => {
        const dueDateString = dueAtDate
            ? `was due on ${formatInTimeZone(
                  dueAtDate,
                  campusTimezone,
                  "MMMM d"
              )}`
            : "was due";
        return `A ${assetTypeName(
            rental.rentedAsset
        )} ${dueDateString} but has not been received.`;
    },
    NOT_RECEIVED_OVERDUE_PART2: (rentalExpirationDate: Date) =>
        ` The rental will be marked as “Failed” if no return is registered by ${format(
            rentalExpirationDate,
            "MMMM d"
        )}.`,
    CHECKED_OUT: (rental: RentalSearchDocument) =>
        `Checked out: ${
            rental?.createdAt
                ? format(new Date(rental.createdAt), "MMMM d")
                : ""
        }`,
};

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const OverlayContainer = styled(View)`
    display: flex;
    flex-direciton: column;
    gap: 24px;
    padding-right: ${({ theme }) => theme.spacing.large}px;
    padding-left: ${({ theme }) => theme.spacing.large}px;
    height: 70%;
`;

const OverlayHeaderTextWrapper = styled(View)`
    display: flex;
    flex-direciton: column;
`;

const OverlayHeaderWrapper = styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledText = styled(Text)`
    color: ${({ color }) => color};
`;

const StyledTextWithMargin = styled(Text)`
    color: ${({ color }) => color};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    rental: RentalSearchDocument;
};

export default function PastDueOverlay({ rental }: Props) {
    const { colors } = useTheme();

    const { canCharge, contactPhoneNumber, localTimezone, pendingPeriod } =
        useContext(CampusContext).campusConfig;

    const [listRentalStepsForRental, rentalSteps, rentalStepsInitiated] =
        useListRentalStepsForRental();

    useEffect(() => {
        listRentalStepsForRental(rental?.rentalId);
    }, [rental]);

    const rentalHasSteps =
        rentalStepsInitiated && (rentalSteps || []).length > 0;

    const closedAt = rentalHasSteps ? getClosedDate(rentalSteps) : null;
    const expiredAt = rentalHasSteps ? getExpirationDate(rentalSteps) : null;
    const dueAt = rental?.dueAt ? new Date(rental.dueAt) : null;

    const rentalLate = isLate(rental);
    const rentalOverdue = isOverdue(rental) || isAboutToExpire(rental);

    return (
        <OverlayContainer>
            <View>
                {rental && (
                    <OverlayHeaderWrapper>
                        <OverlayHeaderTextWrapper>
                            <HeaderWrapper>
                                <StyledText
                                    color={colors.DEEP_BLUE_SEA}
                                    type="sh1"
                                >
                                    {STRINGS.HEADER(rental)}
                                </StyledText>
                            </HeaderWrapper>
                            <StyledTextWithMargin
                                color={colors.DEEP_BLUE_SEA}
                                type={"sh2"}
                            >
                                {STRINGS.CHECKED_OUT(rental)}
                            </StyledTextWithMargin>
                        </OverlayHeaderTextWrapper>
                        <View>
                            <PastDuePill rental={rental} />
                        </View>
                    </OverlayHeaderWrapper>
                )}
                {rentalLate && closedAt && (
                    <SectionBody>
                        {STRINGS.CLOSE_DAY_BLURB_PART1(
                            rental,
                            dueAt,
                            localTimezone
                        )}
                        <StyledText color={colors.YELLOW_1} type={"sh2"}>
                            {STRINGS.CLOSE_DAY_BLURB_PART2(closedAt)}
                        </StyledText>
                    </SectionBody>
                )}
                {rentalOverdue && expiredAt && (
                    <SectionBody>
                        {STRINGS.NOT_RECEIVED_OVERDUE_PART1(
                            rental,
                            dueAt,
                            localTimezone
                        )}
                        <StyledText color={colors.RED} type={"sh2"}>
                            {STRINGS.NOT_RECEIVED_OVERDUE_PART2(expiredAt)}
                        </StyledText>
                    </SectionBody>
                )}
                {!rentalLate && !rentalOverdue && (
                    <SectionBody>
                        {STRINGS.DUE_RECENTLY(
                            rental,
                            localTimezone,
                            pendingPeriod
                        )}
                    </SectionBody>
                )}
            </View>
            <View>
                <Subheader>{STRINGS.ALREADY_HEADER}</Subheader>
                <SectionBody>
                    {rentalOverdue ? STRINGS.ALREADY_OVERDUE : STRINGS.ALREADY}
                    {rentalOverdue && (
                        <a href={"tel:" + contactPhoneNumber}>
                            {prettifyPhoneNumber(contactPhoneNumber)}
                        </a>
                    )}
                </SectionBody>
            </View>
            <View>
                <Subheader>{STRINGS.FORGOT_HEADER}</Subheader>
                <SectionBody>
                    {rentalLate
                        ? STRINGS.FORGOT_LATE(canCharge)
                        : rentalOverdue
                        ? STRINGS.FORGOT_OVERDUE
                        : STRINGS.FORGOT_PAST_DUE}
                </SectionBody>
            </View>
        </OverlayContainer>
    );
}

function Subheader({ children }) {
    const { colors } = useTheme();

    return (
        <HeaderWrapper>
            <StyledText color={colors.DEEP_BLUE_SEA} type="h3">
                {children}
            </StyledText>
        </HeaderWrapper>
    );
}

function SectionBody({ children }) {
    const { colors } = useTheme();

    return (
        <StyledText color={colors.DEEP_BLUE_SEA} type="b2">
            {children}
        </StyledText>
    );
}
