import { RentalSearchDocument } from "../../API";
import _ from "lodash";

/* Utility grouper function to take a list of rentals and return a grouped list
 *
 * E.g.
 * {
 *  "GROUP KEY 1" : [RentalSearchDocument, RentalSearchDocument,...],
 *  "GROUP KEY 2" : [RentalSearchDocument, RentalSearchDocument,...],
 *  ...
 * }
 */
const groupRentals = (
    rentals: RentalSearchDocument[],
    groupPath: string,
    groupKeyTransformer: Maybe<{ (key: string): string }>
): { [key: string]: RentalSearchDocument[] } => {
    groupKeyTransformer = groupKeyTransformer || ((key) => key);
    return rentals.reduce((groupedRentals, rental) => {
        (groupedRentals[groupKeyTransformer(_.get(rental, groupPath))] =
            groupedRentals[groupKeyTransformer(_.get(rental, groupPath))] ||
            []).push(rental);
        return groupedRentals;
    }, {});
};

/*Group Rentals by AssetType
 */
export const groupByAssetType = (
    rentals: RentalSearchDocument[]
): {
    [assetType: string]: RentalSearchDocument[];
} => {
    return groupRentals(rentals, "rentedAsset.assetType.name");
};

/*Group Rentals by due date optionally in local time.
 */
export const groupByDueDateLocal = (
    rentals: RentalSearchDocument[],
    timeZone: Maybe<string>
): {
    [dueAtString: string]: RentalSearchDocument[];
} => {
    const groupedRentals = groupRentals(rentals, "dueAt", (dueAt) =>
        new Date(dueAt).toLocaleDateString("en-US", { timeZone })
    );

    return groupedRentals;
};
