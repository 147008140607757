// react
import React, { useEffect, useContext } from "react";

// style
import styled, { useTheme } from "../../styling/styled-components";

// api
import { useSearchRentals } from "../../graphql/hooks/useSearchRentals";
import {
    RentalSearchDocument,
    RentalSearchResults,
    RentalStatus,
} from "../../API";

// components
import CountCardButton from "../common/CountCardButton";
import { View } from "react-native";
import { BlueText } from "../Text";

// utils
import { addDays } from "date-fns";
import { getOnlyFailedExpiredRentalsWithin12Months } from "../../utils/rentals/getHitAggregate";
import GlobalContext from "../../contexts/GlobalContext";

const STRINGS = {
    FAILED_HEADER: "Failed rentals",
    FAILED_SUBTITLE_V1: "In the last 12 months",
    FAILED_SUBTITLE_V2: "None to report - keep it up!",
    FAILED_SUBTITLE_V3: "Not received after late warning",
};

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StyledCountCard = styled(CountCardButton)`
    min-height: 85px;
`;

const renderFailedSubtitle = (
    rentals: RentalSearchDocument[],
    consumerAcceptedTermsAt: string
): string => {
    let hasFailedOrExpiredInLast12Months = false;
    let hasEverFailed = false;

    const now = new Date();

    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(now.getMonth() - 12);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(now.getDate() - 30);

    const acceptedTermsDate = new Date(consumerAcceptedTermsAt);
    const isWithinLast30DaysAcceptedTerms =
        acceptedTermsDate >= thirtyDaysAgo && acceptedTermsDate <= now;

    for (const rental of rentals) {
        const dueAtDate = new Date(rental.dueAt as string);
        const isWithinLast12Months =
            dueAtDate >= twelveMonthsAgo && dueAtDate <= now;
        if (
            (rental.status === RentalStatus.Failed ||
                rental.status === RentalStatus.Expired) &&
            isWithinLast12Months
        ) {
            hasFailedOrExpiredInLast12Months = true;
        }
        if (
            rental.status === RentalStatus.Failed ||
            rental.status === RentalStatus.Expired
        ) {
            hasEverFailed = true;
        }
    }

    // Scenario 1
    if (hasFailedOrExpiredInLast12Months) {
        return STRINGS.FAILED_SUBTITLE_V1;
    }

    // Scenario 2
    if (!hasFailedOrExpiredInLast12Months) {
        return STRINGS.FAILED_SUBTITLE_V2;
    }

    // Scenario 3
    if (isWithinLast30DaysAcceptedTerms && !hasEverFailed) {
        return STRINGS.FAILED_SUBTITLE_V3;
    }

    // Default case
    return "";
};

type Props = {
    rentals: RentalSearchResults; // CHECK THAT TYPE!!!!! SHOULD BE AN ARRAY
    rentalsInitiated: boolean;
    navigation: unknown;
};

export default function FailedCountCard({
    navigation,
    rentals,
    rentalsInitiated,
}: Props) {
    const { isDesktop } = useTheme();
    const { consumerAcceptedTermsAt } =
        useContext(GlobalContext).globalSelections;

    const [
        queryRentalsLastThirty,
        rentalsLastThirty,
        rentalsInitiatedLastThirty,
        rentalsLoadingLastThirty,
        rentalsErrorsLastThirty,
    ] = useSearchRentals();

    const failedFinedRentalsCount =
        getOnlyFailedExpiredRentalsWithin12Months(rentals);

    useEffect(() => {
        if (failedFinedRentalsCount) {
            const toTimestamp = new Date().toISOString();
            const fromTimestamp = addDays(new Date(), -30).toISOString();
            queryRentalsLastThirty({ toTimestamp, fromTimestamp });
        }
    }, [failedFinedRentalsCount]);

    const totalCount = rentalsInitiated ? failedFinedRentalsCount : null;
    const subtitle = rentals
        ? renderFailedSubtitle(rentals.rentals || [], consumerAcceptedTermsAt)
        : null;

    return (
        <StyledCountCard
            count={totalCount}
            onPress={() => navigation.navigate("ChargesScreen")}
        >
            <HeaderWrapper>
                <BlueText type="sh1">{STRINGS.FAILED_HEADER}</BlueText>
            </HeaderWrapper>
            <BlueText type={isDesktop ? "b1" : "b2"}>
                {subtitle as string}
            </BlueText>
        </StyledCountCard>
    );
}
