import React, { useEffect } from "react";
import { View, FlatList, ActivityIndicator, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StackActions } from "@react-navigation/native";
import Text from "../components/Text";
import styled, { useTheme } from "../styling/styled-components";
import * as queries from "../graphql/queries";
import { useQuery } from "../graphql/hooks/query";
import { RightChevron } from "../assets/vectors";
import CampusLogo from "../components/CampusLogo";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

const STRINGS = {
    TITLE: "Welcome! Select your ReusePass program.",
    SUBTITLE:
        "Reusepass offers reusable programs for a variety of campuses and businesses. Please select the one that is relevant for you.",
    ERROR: "Something went wrong. Please check your internet and try again.",
};

const Container = styled(View)``;

const Prompt = styled(View)`
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xxlarge : theme.spacing.large}px;
`;

const CardWrapper = styled(Pressable)`
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 2px;
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
    padding-left: ${({ theme }) => theme.spacing.small}px;
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.WHITE};
    margin-bottom: ${({ theme }) => theme.spacing.small}px;
    min-width: ${({ theme }) => (theme.isDesktop ? 600 : 0)}px;
    max-width: 600px;
`;

const CardLeftWrapper = styled(View)`
    flex: 3;
    flex-direction: row;
    align-items: center;
`;

const CardRightWrapper = styled(View)`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const ChevronWrapper = styled(View)`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const SubtitleText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    max-width: 600px;
    align-self: ${({ theme }) => (theme.isDesktop ? "center" : "flex-start")};
`;

const CampusNameText = styled(BlueText)`
    padding-left: ${({ theme }) => theme.spacing.medium}px;
`;

const TitleText = styled(BlueText)`
    padding-bottom: ${({ theme }) => theme.spacing.small}px;
`;

const Card = ({ campusName, s3LogoKey, onPress, isDesktop }: props) => {
    return (
        <CardWrapper onPress={onPress}>
            <CardLeftWrapper>
                <CampusLogo
                    s3LogoKey={s3LogoKey}
                    width={isDesktop ? 75 : 48}
                    height={isDesktop ? 75 : 48}
                />
                <CampusNameText type="sh1">{campusName}</CampusNameText>
            </CardLeftWrapper>
            <CardRightWrapper>
                <ChevronWrapper>
                    <RightChevron />
                </ChevronWrapper>
            </CardRightWrapper>
        </CardWrapper>
    );
};

type Props = {};

export default function CampusList({ style }: Props) {
    const navigation = useNavigation();
    const { isDesktop } = useTheme();

    const [listCampusConfigs, campusConfigs, campusConfigsLoading, errors] =
        useQuery(queries.listCampusConfigs, GRAPHQL_AUTH_MODE.AWS_IAM);

    useEffect(() => {
        listCampusConfigs();
    }, []);

    const handleSelection = (campusSlug: string) => {
        // enables browser back to function
        const pushAction = StackActions.push("SignInScreen", {
            campusSlug,
        });
        navigation.dispatch(pushAction);
    };

    return (
        <Container style={style}>
            <Prompt>
                <TitleText type="h1">{STRINGS.TITLE}</TitleText>
                <SubtitleText type="b2">{STRINGS.SUBTITLE}</SubtitleText>
            </Prompt>
            {campusConfigsLoading && <ActivityIndicator />}
            {errors ? (
                <BlueText type="b1">{STRINGS.ERROR}</BlueText>
            ) : (
                <FlatList
                    data={(campusConfigs?.results || []).sort((a, b) =>
                        a.campusName.localeCompare(b.campusName)
                    )}
                    refreshing={campusConfigsLoading}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) => (
                        <Card
                            {...item}
                            isDesktop={isDesktop}
                            onPress={() => handleSelection(item.campusSlug)}
                        />
                    )}
                    keyExtractor={(item) => item?.organizationId}
                />
            )}
        </Container>
    );
}

CampusList.defaultProps = {};
