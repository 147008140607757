// react
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";

// style
import styled from "../styling/styled-components";

// components
import Text from "./Text";
import { GreyPill, RedPill, YellowPill } from "./Pill";

// assets
import { Bulb } from "../assets/vectors";

const STRINGS = {
    DESCRIPTION:
        "It’s normal for returns to take 24 hours to show up on your account.",
    MEANING_LABEL: "MEANING",
    MEANING_LATE:
        "No return has been received. This is your final warning to return!",
    MEANING_OVERDUE: `The return window has closed. We're checking for a return before marking the rental as "Failed."`,
    MEANING_PENDING: "No need to stress –we’re checking for your return.",
    STATUS_LABEL: "STATUS",
    STATUS_LATE: "Late",
    STATUS_OVERDUE: "Overdue",
    STATUS_PENDING: "Pending",
    TITLE: "Past due doesn’t always mean something is wrong!",
};

const Container = styled(View)`
    position: relative;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.FROTH};
    padding-top: ${({ theme }) => theme.spacing.xlarge}px;
    padding-bottom: ${({ theme }) => theme.spacing.xxlarge}px;
    padding-horizontal: ${({ theme }) => theme.spacing.large}px;
    max-width: 427px;
`;

const TableWrapper = styled(View)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.large}px;
    padding-top: ${({ theme }) => theme.spacing.xsmall}px;
`;

const CenteredHeader = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    text-align: center;
`;

// the max width here is to make sure the text wraps in a way
// that doesn't give us a line with just one word
const Title = styled(CenteredHeader)`
    margin-top: ${({ theme }) => theme.spacing.xlarge}px;
    max-width: 210px;
`;

// the max width here is to make sure the text wraps in a way
// that doesn't give us a line with just one word
const Description = styled(CenteredHeader)`
    margin-top: ${({ theme }) => theme.spacing.medium}px;
    max-width: 230px;
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

const BulbWrapper = styled(View)`
    position: absolute;
    top: -30px;
`;

const Header = styled(Text)`
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.EXHAUST};
    padding-vertical: ${({ theme }) => theme.spacing.xsmall}px;
`;

const TableContainer = styled(View)`
    flex: 1;
    justify-content: center
    align-items: center;
`;

const Row = styled(View)`
    flex-direction: row;
    width: 100%;
    border-bottom-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    border-bottom-style: solid;
    border-bottom-width: 1px;
`;

const LastRow = styled(Row)`
    border-bottom-style: none;
`;

const LeftCell = styled(View)`
    width: 30%;
    border-right-color: ${({ theme }) => theme.colors.MARINE_LAYER};
    border-right-style: solid;
    border-right-width: 1px;
    text-align: center;
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
`;

const LeftHeaderCell = styled(LeftCell)`
    justify-content: center;
    padding-vertical: 0px;
`;

const RightCell = styled(View)`
    width: 70%;
    padding-horizontal: ${({ theme }) => theme.spacing.small}px;
    justify-content: center;
    padding-vertical: ${({ theme }) => theme.spacing.medium}px;
    text-align: left;
`;

const RightHeaderCell = styled(RightCell)`
    padding-vertical: 0px;
`;

const Table = () => {
    return (
        <TableContainer>
            <Row>
                <LeftHeaderCell>
                    <Header type="eyebrow">{STRINGS.STATUS_LABEL}</Header>
                </LeftHeaderCell>
                <RightHeaderCell>
                    <Header type="eyebrow">{STRINGS.MEANING_LABEL}</Header>
                </RightHeaderCell>
            </Row>
            <Row>
                <LeftCell>
                    <GreyPill fontType="sh2">{STRINGS.STATUS_PENDING}</GreyPill>
                </LeftCell>
                <RightCell>
                    <BlueText type="b2">{STRINGS.MEANING_PENDING}</BlueText>
                </RightCell>
            </Row>
            <Row>
                <LeftCell>
                    <YellowPill fontType="sh2">{STRINGS.STATUS_LATE}</YellowPill>
                </LeftCell>
                <RightCell>
                    <BlueText type="b2">{STRINGS.MEANING_LATE}</BlueText>
                </RightCell>
            </Row>
            <LastRow>
                <LeftCell>
                    <RedPill fontType="sh2">{STRINGS.STATUS_OVERDUE}</RedPill>
                </LeftCell>
                <RightCell>
                    <BlueText type="b2">{STRINGS.MEANING_OVERDUE}</BlueText>
                </RightCell>
            </LastRow>
        </TableContainer>
    );
};

type Props = {
    style?: StyleProp<ViewStyle>;
};

export default function PastDueSection({ style }: Props) {
    return (
        <Container style={style}>
            <BulbWrapper>
                <Bulb />
            </BulbWrapper>
            <Title type="sh1">{STRINGS.TITLE}</Title>
            <Description type="b2">{STRINGS.DESCRIPTION}</Description>
            <TableWrapper>
                <Table />
            </TableWrapper>
        </Container>
    );
}

PastDueSection.defaultProps = {};
