import React from "react";

import { CampusConfig } from "../API";

export const emptyCampusConfig: CampusConfig = {
    campusName: "",
    dueBackDays: null,
    canCharge: null,
    hidePass: true,
    localTimezone: "",
    termsCopy: "",
    termsLink: "",
};

export default React.createContext({
    campusConfig: emptyCampusConfig,
    setCampusConfig: () => {},
});
