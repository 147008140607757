// react
import React from "react";

// style
import styled, { useTheme } from "../../styling/styled-components";

// components
import CountCardButton from "../common/CountCardButton";
import { RedPill, YellowPill } from "../Pill";
import { View } from "react-native";
import { BlueText } from "../Text";

// utils
import {
    getAboutToExpireCount,
    getOverdueCount,
    getPastDueCount,
    getLateCount,
} from "../../utils/rentals/getHitAggregate";
import { RentalSearchResults } from "../../API";

type PastDueCardSubheaderProps = {
    aboutToExpireRentalsCount: number;
    lateRentalsCount: number;
    overdueRentalsCount: number;
    pastDueRentalsCount: number;
};

type LateCountPillProps = {
    overdueRentalsCount: number;
    aboutToExpireRentalsCount: number;
};

const STRINGS = {
    HEADER_LATE: "Past due",
    SUBHEADER_PAST_DUE: (hasPastDue: boolean) =>
        hasPastDue
            ? "Due recently, not yet received"
            : "Items not received by due date",
    SUBHEADER_LATE: "Return ASAP",
    SUBHEADER_OVERDUE: "Overdue",
};

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

const StyledCountCard = styled(CountCardButton)`
    min-height: 85px;
`;

type Props = {
    rentals: RentalSearchResults;
    rentalsInitiated: boolean;
    navigation: unknown;
};

export default function PastDueCountCard({
    navigation,
    rentals,
    rentalsInitiated,
}: Props) {
    const lateRentalsCount = getLateCount(rentals);
    const overdueRentalsCount = getOverdueCount(rentals);
    const pastDueRentalsCount = getPastDueCount(rentals);
    const aboutToExpireRentalsCount = getAboutToExpireCount(rentals);

    const totalCount = rentalsInitiated
        ? pastDueRentalsCount +
          lateRentalsCount +
          overdueRentalsCount +
          aboutToExpireRentalsCount
        : null;

    return (
        <StyledCountCard
            count={totalCount}
            onPress={() => navigation.navigate("LateScreen")}
        >
            <HeaderWrapper>
                <BlueText type="sh1">{STRINGS.HEADER_LATE}</BlueText>
            </HeaderWrapper>
            <PastDueCardSubheader
                aboutToExpireRentalsCount={aboutToExpireRentalsCount}
                lateRentalsCount={lateRentalsCount}
                overdueRentalsCount={overdueRentalsCount}
                pastDueRentalsCount={pastDueRentalsCount}
            />
        </StyledCountCard>
    );
}

function PastDueCardSubheader({
    aboutToExpireRentalsCount,
    lateRentalsCount,
    overdueRentalsCount,
    pastDueRentalsCount,
}: PastDueCardSubheaderProps) {
    const { isDesktop } = useTheme();

    const anyLateOrOverdue =
        lateRentalsCount + overdueRentalsCount + aboutToExpireRentalsCount != 0;
    const anyPastDue = pastDueRentalsCount != 0;

    const subheaderFontType = isDesktop ? "b1" : "b2";

    return (
        <>
            {!anyLateOrOverdue && (
                <BlueText type={subheaderFontType}>
                    {STRINGS.SUBHEADER_PAST_DUE(anyPastDue)}
                </BlueText>
            )}
            {anyLateOrOverdue && (
                <LateCountPill
                    overdueRentalsCount={overdueRentalsCount}
                    aboutToExpireRentalsCount={aboutToExpireRentalsCount}
                />
            )}
        </>
    );
}

function LateCountPill({
    overdueRentalsCount,
    aboutToExpireRentalsCount,
}: LateCountPillProps) {
    const { isDesktop } = useTheme();

    const pillFontType = isDesktop ? "b1" : "b2";

    // we make sure RedPill takes priority with about to expire rentals
    if (aboutToExpireRentalsCount > 0) {
        return (
            <RedPill fontType={pillFontType}>
                {STRINGS.SUBHEADER_OVERDUE}
            </RedPill>
        );
    }

    return (
        <>
            {overdueRentalsCount > 0 && (
                <RedPill fontType={pillFontType}>
                    {STRINGS.SUBHEADER_OVERDUE}
                </RedPill>
            )}
            {overdueRentalsCount == 0 && (
                <YellowPill fontType={pillFontType}>
                    {STRINGS.SUBHEADER_LATE}
                </YellowPill>
            )}
        </>
    );
}
