import { COLORS } from "./colors";

const LETTER_SPACING = {
    tiny: -0.22,
    small: -0.32,
    medium: -1,
};

const DESKTOP_TEXT_STYLES = {
    statistic: {
        fontFamily: "latoBold",
        fontSize: 60,
        lineHeight: 72,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.tiny,
        color: COLORS.SALMON,
    },
    statement: {
        fontFamily: "latoBold",
        fontSize: 45,
        lineHeight: 54,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h1: {
        fontFamily: "latoBold",
        fontSize: 42,
        lineHeight: 50.4,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h2: {
        fontFamily: "latoBold",
        fontSize: 30,
        lineHeight: 36,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h3: {
        fontFamily: "poppinsSemiBold",
        fontSize: 16,
        lineHeight: 24,
        fontWeight: "600",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    sh1: {
        fontFamily: "latoBold",
        fontSize: 24,
        lineHeight: 28.8,
        fontWeight: "700",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    sh2: {
        fontFamily: "poppinsMedium",
        fontSize: 16,
        lineHeight: 21,
        fontWeight: "500",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    b1: {
        fontFamily: "poppinsRegular",
        fontSize: 16,
        lineHeight: 24,
        fontWeight: "300",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    b2: {
        fontFamily: "poppinsRegular",
        fontSize: 16,
        lineHeight: 21,
        fontWeight: "400",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    eyebrow: {
        fontFamily: "poppinsRegular",
        fontSize: 14,
        lineHeight: 21,
        fontWeight: "600",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    link: {
        fontFamily: "lato",
        fontSize: 16,
        lineHeight: 19.2,
        fontWeight: "700",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.BLUE,
        textDecorationLine: "underline",
        lineColor: COLORS.BLUE,
    },
};

const MOBILE_TEXTSTYLES = {
    statistic: {
        fontFamily: "latoBold",
        fontSize: 60,
        lineHeight: 72,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.tiny,
        color: COLORS.SALMON,
    },
    statement: {
        fontFamily: "latoBold",
        fontSize: 36,
        lineHeight: 43.2,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h1: {
        fontFamily: "latoBold",
        fontSize: 30,
        lineHeight: 36,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h2: {
        fontFamily: "latoBold",
        fontSize: 24,
        lineHeight: 28.8,
        fontWeight: "900",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    h3: {
        fontFamily: "poppinsSemiBold",
        fontSize: 14,
        lineHeight: 21,
        fontWeight: "600",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    sh1: {
        fontFamily: "latoBold",
        fontSize: 18,
        lineHeight: 22,
        fontWeight: "700",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.DEEP_BLUE_SEA,
    },
    sh2: {
        fontFamily: "poppinsMedium",
        fontSize: 14,
        lineHeight: 18,
        fontWeight: "500",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    b1: {
        fontFamily: "poppinsRegular",
        fontSize: 14,
        lineHeight: 21,
        fontWeight: "300",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    b2: {
        fontFamily: "poppinsRegular",
        fontSize: 14,
        lineHeight: 18,
        fontWeight: "400",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    eyebrow: {
        fontFamily: "poppinsRegular",
        fontSize: 12,
        lineHeight: 18,
        fontWeight: "600",
        letterSpacing: LETTER_SPACING.small,
        color: COLORS.DEEP_BLUE_SEA,
    },
    link: {
        fontFamily: "lato",
        fontSize: 14,
        lineHeight: 17,
        fontWeight: "700",
        letterSpacing: LETTER_SPACING.medium,
        color: COLORS.BLUE,
        textDecorationLine: "underline",
        lineColor: COLORS.BLUE,
    },
};

export const TEXT_STYLES = {
    desktop: DESKTOP_TEXT_STYLES,
    mobile: MOBILE_TEXTSTYLES,
};
