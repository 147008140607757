import React, { useEffect, useState } from "react";
import { View, Pressable, ActivityIndicator } from "react-native";
import { API } from "aws-amplify";
import QRCode from "react-native-qrcode-svg";
import Text from "../components/Text";
import * as queries from "../graphql/queries";
import { useQuery } from "../graphql/hooks/query";
import { ReusePassQr } from "../API";
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";
import { openURL } from "../utils/helpers";
import {
    NewConsumerMobile,
    NewConsumerDesktop,
    AddToAppleWallet,
} from "../assets/vectors";

const STRINGS = {
    LOAD_FAILED: "We couldn't load your ReusePass code, try again later.",
    REUSEPASS: "Your ReusePass",
    PROMPT: "Scan this code when checking out reusable containers.",
};

const PASS_MOBILE_HEIGHT = 200;
const PASS_DESKTOP_HEIGHT = 255;

const Container = styled(View)`
    padding: ${({ theme }) => theme.spacing.large}px;
    flex: 1;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding-bottom: ${({ theme }) => theme.spacing.xlarge}px;
`;

const PassWrapper = styled(View)`
    padding-bottom: ${({ theme }) =>
        theme.isDesktop ? theme.spacing.xxlarge : theme.spacing.medium}px;
`;

const NewConsumer = styled(View)`
    align-self: flex-start;
    padding: ${({ theme }) => theme.spacing.small}px;
`;

const PromptWrapper = styled(View)`
    text-align: center;
    max-width: 250px;
`;

const DownloadPassWrapper = styled(View)`
    padding-top: ${({ theme }) => theme.spacing.small}px;
    align-self: center;
`;

const DownloadApplePass = styled(Pressable)``;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall}px;
`;

export default function ReusePass() {
    const { isMobile } = useTheme();
    const [applePassLoading, setApplePassLoading] = useState<boolan>(false);
    const [applePassDownloadURL, setApplePassDownloadURL] = useState<string>();

    const [createPass, data, loading, error]: ReusePassQr = useQuery(
        queries.consumerQrData
    );

    const { data: QRData } = data || {};

    useEffect(() => {
        createPass();
    }, []);

    const [getConsumerStats, statsData, statsLoading, statsError] = useQuery(
        queries.getConsumerStats
    );

    useEffect(() => {
        getConsumerStats();
    }, []);

    useEffect(() => {
        getApplePass();
    }, []);

    const { totalOpenLoops } = statsData || {};

    const getApplePass = async (): void => {
        try {
            const result = await API.graphql({
                query: queries.getAppleWalletPass,
            });
            const presignedS3URL =
                result?.data?.getAppleWalletPass?.presignedS3URL;
            setApplePassDownloadURL(presignedS3URL);
        } catch (err) {
            console.error("Error getting Apple Pass", JSON.stringify(err));
        } finally {
            setApplePassLoading(false);
        }
    };

    const handleDownloadApplePass = async (): void => {
        openURL(applePassDownloadURL);
    };

    const renderApplePass = (): React.ReactNode => {
        return (
            <DownloadPassWrapper>
                {applePassDownloadURL && (
                    <DownloadApplePass onPress={handleDownloadApplePass}>
                        <AddToAppleWallet />
                    </DownloadApplePass>
                )}
            </DownloadPassWrapper>
        );
    };

    if (loading) return <ActivityIndicator />;
    if (error) return <Text type="b2">{STRINGS.LOAD_FAILED}</Text>;
    if (!data) return <Text type="b2">{STRINGS.LOAD_FAILED}</Text>;

    return (
        <>
            <Container>
                <PassWrapper>
                    <QRCode
                        value={QRData}
                        size={
                            isMobile ? PASS_MOBILE_HEIGHT : PASS_DESKTOP_HEIGHT
                        }
                    />
                    {isMobile ? renderApplePass() : null}
                </PassWrapper>
                <PromptWrapper>
                    <BlueText type="sh1">{STRINGS.REUSEPASS}</BlueText>
                    <BlueText type="b2">{STRINGS.PROMPT}</BlueText>
                </PromptWrapper>
                {!isMobile ? renderApplePass() : null}
            </Container>
            {totalOpenLoops == 0 && (
                <NewConsumer>
                    {isMobile ? <NewConsumerMobile /> : <NewConsumerDesktop />}
                </NewConsumer>
            )}
        </>
    );
}

ReusePass.defaultProps = {};
