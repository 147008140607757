import _ from "lodash";
import React, { useState, useEffect } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Auth } from "aws-amplify";
import CrashReport from "../utils/crashReport";
import CampusContext, {
    CampusConfig,
    emptyCampusConfig,
} from "../contexts/CampusContext";
import GlobalContext, {
    emptyGlobalSelection,
    GlobalSelections,
} from "../contexts/GlobalContext";
import { useGetCampusConfig } from "../graphql/hooks/useGetCampusConfig";
import Error from "../components/Error";
import SplashScreen from "../components/SplashScreen";
import OverviewScreen from "../screens/OverviewScreen";
import DueSoonScreen from "../screens/DueSoonScreen";
import LateScreen from "../screens/LateScreen";
import ChargesScreen from "../screens/ChargesScreen";
import ActivityScreen from "../screens/ActivityScreen";
import SignInScreen from "../screens/SignInScreen";
import ThirdPartySignInScreen from "../screens/ThirdPartySignInScreen";

const Stack = createStackNavigator();
export default function Component(props) {
    const [isLoading, setLoading] = useState(true);
    const [isInitiated, setInitiated] = useState(false);
    const [campusConfig, setCampusConfig] =
        useState<CampusConfig>(emptyCampusConfig);
    const [globalSelections, setGlobalSelections] =
        useState<GlobalSelections>(emptyGlobalSelection);

    const [
        getCampusConfig,
        _campusConfig,
        _campusConfigLoading,
        campusConfigErrors,
    ] = useGetCampusConfig();

    useEffect(() => {
        initiateSession();
    }, []);

    const updateSelections = ({ selections }) => {
        setGlobalSelections({ ...globalSelections, ...selections });
        // conditions on signout to reinit session
        if (
            selections.isAuthenticated === false &&
            globalSelections.isAuthenticated === true &&
            isInitiated === true
        ) {
            setLoading(true);
            clearSession();
        }
    };

    const { isAuthenticated } = globalSelections;

    const initiateSession = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const userOrgId = user.attributes["custom:organizationId"];
            const config =
                (await getCampusConfig({ organizationId: userOrgId })) ||
                emptyCampusConfig;
            setCampusConfig(config);
            updateSelections({
                selections: {
                    isAuthenticated: true,
                    phoneNumber: _.get(user, "attributes.phone_number"),
                    consumerId: _.get(user, "attributes.custom:consumerId"),
                    consumerAcceptedTermsAt: _.get(
                        user,
                        "attributes.custom:termsAcceptedAt"
                    ),
                },
            });
            CrashReport.setUser(_.get(user, "attributes.custom:consumerId"));
            CrashReport.setCampus(config.campusSlug);
            setInitiated(true);
        } catch (err) {
            updateSelections({
                selections: {
                    isAuthenticated: false,
                },
            });
        } finally {
            setLoading(false);
        }
    };

    const clearSession = async () => {
        setGlobalSelections(emptyGlobalSelection);
        setCampusConfig(emptyCampusConfig);
        setLoading(false);
    };

    if (isLoading) {
        return <SplashScreen />;
    }

    if (campusConfigErrors) {
        return <Error />;
    }

    return (
        <GlobalContext.Provider
            value={{
                globalSelections,
                updateSelections,
            }}
        >
            <CampusContext.Provider
                value={{
                    campusConfig,
                    setCampusConfig,
                }}
            >
                <Stack.Navigator
                    screenOptions={{
                        headerShown: false,
                        title: "ReusePass",
                    }}
                >
                    {isAuthenticated ? (
                        <>
                            <Stack.Screen
                                name="OverviewScreen"
                                component={OverviewScreen}
                            />
                            <Stack.Screen
                                name="LateScreen"
                                component={LateScreen}
                            />
                            <Stack.Screen
                                name="DueSoonScreen"
                                component={DueSoonScreen}
                            />
                            <Stack.Screen
                                name="ChargesScreen"
                                component={ChargesScreen}
                            />
                            <Stack.Screen
                                name="ActivityScreen"
                                component={ActivityScreen}
                            />
                        </>
                    ) : (
                        <>
                            <Stack.Screen
                                name="SignInScreen"
                                component={SignInScreen}
                            />
                            <Stack.Screen
                                name="ThirdPartySignInScreen"
                                component={ThirdPartySignInScreen}
                            />
                        </>
                    )}
                </Stack.Navigator>
            </CampusContext.Provider>
        </GlobalContext.Provider>
    );
}
