// react
import React from "react";
import { Pressable } from "react-native";
import { Ionicons } from "@expo/vector-icons";

// style
import styled, { css } from "../styling/styled-components";

const CheckBoxBase = styled(Pressable)`
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.WAVE_STORM};
    background-color: transparent;

    ${({ checked, theme }) =>
        checked &&
        css`
        border-color: ${({ theme }) => theme.colors.WAVE_STORM};
        background-color: ${({ theme }) => theme.colors.WAVE_STORM};
    `}
`;

type Props = {
    accessibilityLabel: string;
    checked: boolean;
    onValueChange: () => void;
};

export default function CheckBox({
    accessibilityLabel,
    checked,
    onValueChange,
}: Props) {
    return (
        <CheckBoxBase
            accessibilityLabel={accessibilityLabel}
            checked={checked}
            onPress={onValueChange}
        >
            {checked && <Ionicons name="checkmark" size={24} color="white" />}
        </CheckBoxBase>
    );
}

CheckBox.defaultProps = {
    checked: false,
    onValueChange: () => null,
};
