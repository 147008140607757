import pako from "pako";
import React from "react";
import { View } from "react-native";
import { Link } from "@react-navigation/native";

import styled, { useTheme } from "../styling/styled-components";
import Text from "./Text";
import { ButtonWithIcon } from "./Button";
import { ExternalLink } from "../assets/vectors";
import { InitiateSignUpInput, SignUpChallenge } from "../API";

const Container = styled(View)``;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    margin-top: ${({ theme: { isMobile, spacing } }) =>
        isMobile ? spacing.small : spacing.xlarge}px;
`;

const NextButton = styled(ButtonWithIcon)`
    align-self: ${({ theme }) => (theme.isDesktop ? "flex-start" : "center")};
    width: ${({ theme }) => (theme.isMobile ? "100%" : "400px")};
    margin: ${({ theme: { spacing } }) => spacing.yuge}px auto 0;
`;

const PhoneLink = styled(Link)`
    margin-top: ${({ theme: { spacing } }) => spacing.large}px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.BLUE};
    font-size: 12px;
    font-weight: 600;
`;

const formatPhoneNumber = (phoneNumber: string) => {
    //Filter only numbers from the input
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");

    //Check if the input is of correct
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        //Remove the matched extension code
        //Change this to format for any country code.
        const intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
            ""
        );
    }

    return null;
};

type Props = {
    challenge: SignUpChallenge;
    phoneNumber: InitiateSignUpInput["phoneNumber"];
    confirmationCode: string;
    campusName: string;
    campusSlug: string;
    organizationId: string;
    singleSignOnConfig: SingleSignOnConfig;
    step: number;
    errorEncountered: boolean;
    errorPhoneNumber: string;
};

export default function SingleSignOn({
    challenge,
    phoneNumber,
    confirmationCode,
    campusName,
    campusSlug,
    organizationId,
    singleSignOnConfig,
    step,
    errorEncountered,
    errorPhoneNumber,
    route,
}: Props) {
    const STRINGS = {
        ERROR_TITLE: "An error occurred during sign in",
        ERROR_MESSAGE:
            "We were unable to verify your school account. Please try again or send our support team a text for assistance:",
        ERROR_NUMBER: formatPhoneNumber(errorPhoneNumber),
        TITLE: "Sign in to your school account",
        MESSAGE:
            campusName +
            " requires that you first sign in to your student acount to access your ReusePass account. You will only need to do this once.",
        CTA_TEXT: "Sign in to school account",
    };
    const { colors } = useTheme();

    const {
      redirectUrl = null,
      externalId = null,
      name = null
    } = route.params || {};

    const relayState = btoa(
        JSON.stringify({
            challenge,
            organizationId,
            phoneNumber,
            campusSlug,
            confirmationCode,
            step: step + 1,
            redirectUrl,
            externalId,
        })
    );

    // This is all hard-coded for now because it doesn't appear like the ids or issue instant is actually used. This appears to just be to check and see if a request is coming
    // from the coorect issuer
    const authNRequest =
        '<?xml version="1.0"?><samlp:AuthnRequest xmlns:samlp="urn:oasis:names:tc:SAML:2.0:protocol" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" ID="_abc123" IssueInstant="2023-06-25T22:07:30Z" Version="2.0"><saml:Issuer>https://www.reusepass.com/sp</saml:Issuer></samlp:AuthnRequest>';
    const authNRequestCompressed = pako.deflateRaw(authNRequest);
    const authNRequestEncoded = encodeURIComponent(
        btoa(String.fromCharCode.apply(null, authNRequestCompressed))
    );

    const ssoUrl =
        singleSignOnConfig["url"] +
        "?providerId=https://www.reusepass.com/sp&RelayState=" +
        relayState +
        "&target=" +
        relayState +
        "&SAMLRequest=" +
        authNRequestEncoded;

    const phoneHref = "tel:" + errorPhoneNumber;

    return (
        <Container>
            <View>
                {errorEncountered ? (
                    <>
                        <BlueText type="h1">{STRINGS.ERROR_TITLE}</BlueText>
                        <BlueText type="b2">{STRINGS.ERROR_MESSAGE}</BlueText>
                        <PhoneLink to={phoneHref}>
                            {STRINGS.ERROR_NUMBER}
                        </PhoneLink>
                    </>
                ) : (
                    <>
                        <BlueText type="h1">{STRINGS.TITLE}</BlueText>
                        <BlueText type="b2">{STRINGS.MESSAGE}</BlueText>
                    </>
                )}
            </View>
            <a href={ssoUrl} style={{ textDecoration: "none" }}>
                <NextButton
                    type="primary"
                    size="large"
                    icon={<ExternalLink />}
                    label={STRINGS.CTA_TEXT}
                    style={{width: "100%"}}
                /> 
            </a>
        </Container>
    );
}
