import { API } from "aws-amplify";
import { environment } from "../../../configs.json";
import React from "react";
import * as queries from "../queries";
import { CAMPUS_CONFIGS } from "../mocks/campusConfigs";
import { CampusConfig } from "../../API";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { graphErrorDetails } from "../../utils/helpers";

type GetCampusConfigVariables = {
    campusSlug?: string;
    organizationId?: string;
};

export const useGetCampusConfig = (authMode = GRAPHQL_AUTH_MODE.AWS_IAM) => {
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<object | null>(null);
    const [data, setData] = React.useState<object | null>(null);

    const queryCallback = async (
        variables: GetCampusConfigVariables
    ): CampusConfig => {
        setLoading(true);
        try {
            if (environment == "sandbox") {
                const { campusSlug, organizationId } = variables;
                let config = CAMPUS_CONFIGS[0];
                if (campusSlug) {
                    config = CAMPUS_CONFIGS.find(
                        (config) => config.campusSlug == campusSlug
                    );
                }
                if (organizationId) {
                    config = CAMPUS_CONFIGS.find(
                        (config) => config.organizationId == organizationId
                    );
                }
                // for sandbox give a config even if the supplied identifier
                // does not match a mock config
                const results = {
                    data: { getCampusConfig: config || CAMPUS_CONFIGS[0] },
                };
                setData(results);
                return results?.data?.getCampusConfig;
            } else {
                const results = await API.graphql({
                    query: queries.getCampusConfig,
                    variables,
                    authMode,
                });
                setData(results);
                return results?.data?.getCampusConfig;
            }
        } catch (error) {
            const [errorType, errorMessage] = graphErrorDetails(error?.errors);
            // campusSlug can be set from URL path which we don't have
            // control over
            if (errorType == "ObjectNotFound") {
                console.warn(
                    "useGetCampusConfig called for campus that DNE: ",
                    variables
                );
            } else if (errorMessage == "Network Error") {
                console.warn(
                    "Network Error for useGetCampusConfig request.",
                    error
                );
            } else {
                console.error(
                    "Error with useGetCampusConfig",
                    JSON.stringify(error)
                );
            }
            setErrors(error);
        } finally {
            setLoading(false);
        }
    };

    return [
        queryCallback,
        data?.results?.getCampusConfig || {},
        loading,
        errors,
    ];
};
