// external
import React, { useContext, useState, useEffect } from "react";

// context
import CampusContext from "../../contexts/CampusContext";

// type
import { SignUpChallenge } from "../../API";

// components
import Challenge from "../Challenge";


const STRINGS = {
    EMAIL_TITLE: "Got it. What's your email?",
    EMAIL_TEXT: (campusName: string) => `${campusName.toUpperCase()} EMAIL ADDRESS`,
    EMAIL_PLACEHOLDER: `email@yourcampus.com`,
    EMAIL_ERROR_TEXT: (campusName: string) =>
        `You must enter a valid ${campusName} email address to sign up for your campus.`,
};

type Props = {
    submitChallenge: (code: string) => Promise<[boolean, boolean]>;
    challengeInfo: SignUpChallenge
};

export default function EmailChallenge({ submitChallenge, challengeInfo }: Props) {

    // context
    const campusContext = useContext(CampusContext);
    const { campusName} = campusContext.campusConfig;

    return (
        <Challenge
            submitChallenge={submitChallenge}
            challengeInfo={challengeInfo}
            title={STRINGS.EMAIL_TITLE}
            label={STRINGS.EMAIL_TEXT(campusName)}
            placeholder={STRINGS.EMAIL_PLACEHOLDER}
            errorText={STRINGS.EMAIL_ERROR_TEXT(campusName)}
            inputRegEx="^[^\s@]+@[^\s@]+\.[^\s@]+$"
        />
    );
}
