import { RentalPhase, RentalStep } from "../../API";

export default function getPhaseTriggerDate(
    rentalSteps: RentalStep[],
    phase: RentalPhase
): Date | undefined {
    /* Find the Date for when a specific RentalStep identified by the supplied
     * RntalPhase did/will trigger.
     */
    const matchedStep = rentalSteps.find((step) => step.rentalPhase == phase);

    if (matchedStep?.triggerAt) {
        return new Date(matchedStep.triggerAt);
    }

    return undefined;
}

export function getExpirationDate(rentalSteps: RentalStep[]) {
    return (
        getPhaseTriggerDate(rentalSteps, RentalPhase.Fined) ||
        getPhaseTriggerDate(rentalSteps, RentalPhase.Failed) ||
        getPhaseTriggerDate(rentalSteps, RentalPhase.Expired)
    );
}

export function getClosedDate(rentalSteps: RentalStep[]) {
    return (
        getPhaseTriggerDate(rentalSteps, RentalPhase.FinePending) ||
        getPhaseTriggerDate(rentalSteps, RentalPhase.FailedPending) ||
        getPhaseTriggerDate(rentalSteps, RentalPhase.AboutToExpire)
    );
}
