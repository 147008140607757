import React, { useContext } from "react";
import { View, Pressable } from "react-native";
import { Auth } from "aws-amplify";
import GlobalContext from "../contexts/GlobalContext.tsx";
import Text from "../components/Text";
import { useTheme } from "../styling/styled-components";
import styled from "../styling/styled-components";
import { prettifyPhoneNumber } from "../utils/helpers";
import CrashReport from "../utils/crashReport";

const STRINGS = {
    HEADER: "Account",
    PHONE_NUMBER: "PHONE NUMBER",
    CONSUMER_ID: "CONSUMER ID",
    SIGN_OUT: "Sign out",
};

const Container = styled(View)`
    justify-content: space-between;
`;

const HeaderWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const BodyWrapper = styled(View)`
    padding-bottom: ${({ theme }) => theme.spacing.large}px;
`;

const SignOut = styled(Pressable)``;
const GreyText = styled(Text)`
    color: ${({ theme }) => theme.colors.ASH};
`;

const BlueText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
`;

type Props = {};

export default function ReusePass({ style }: Props) {
    const { isMobile } = useTheme();

    const context = useContext(GlobalContext);

    const { phoneNumber, consumerId } = context.globalSelections;

    const handleSignOut = () => {
        Auth.signOut().then(
            (_) => CrashReport.clearUser(),
            context.updateSelections({
                selections: {
                    isAuthenticated: false,
                },
            })
        );
    };

    return (
        <Container style={style}>
            <HeaderWrapper>
                <BlueText type="sh1">{STRINGS.HEADER}</BlueText>
            </HeaderWrapper>
            <BodyWrapper>
                <GreyText type="eyebrow">{STRINGS.PHONE_NUMBER}</GreyText>
                <GreyText type="b2">
                    {prettifyPhoneNumber(phoneNumber)}
                </GreyText>
            </BodyWrapper>
            <BodyWrapper>
                <GreyText type="eyebrow">{STRINGS.CONSUMER_ID}</GreyText>
                <GreyText type="b2">{consumerId}</GreyText>
            </BodyWrapper>
            <SignOut onPress={handleSignOut}>
                <Text type="link">{STRINGS.SIGN_OUT}</Text>
            </SignOut>
        </Container>
    );
}

ReusePass.defaultProps = {};
