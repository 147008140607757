import { RentalSearchDocument } from "../../API";

/*
 * This is mainly used by the mocks and API requests to emulate the hit aggregations in search results
 */
export default function aggregateHits(rentals: RentalSearchDocument[]) {
    const aggregates = {};
    if (!rentals) {
        return [];
    }

    rentals.map((rental) => {
        if (!aggregates[rental.status]) {
            aggregates[rental.status] = { docCount: 1, subtotals: {} };
            aggregates[rental.status]["subtotals"][rental.lastPhase] = 1;
        } else if (!aggregates[rental.status]["subtotals"][rental.lastPhase]) {
            aggregates[rental.status]["docCount"] += 1;
            aggregates[rental.status]["subtotals"][rental.lastPhase] = 1;
        } else {
            aggregates[rental.status]["docCount"] += 1;
            aggregates[rental.status]["subtotals"][rental.lastPhase] += 1;
        }
    });

    const retval = [];

    for (const aggregateKey in aggregates) {
        const formattedAggregate = {
            key: aggregateKey,
            docCount: aggregates[aggregateKey]["docCount"],
            subtotals: [],
        };
        for (const subtotalKey in aggregates[aggregateKey]["subtotals"]) {
            formattedAggregate["subtotals"].push({
                key: subtotalKey,
                docCount: aggregates[aggregateKey]["subtotals"][subtotalKey],
            });
        }

        retval.push(formattedAggregate);
    }

    return retval;
}
