import React, { useEffect } from "react";
import { Animated, Modal, View, Pressable } from "react-native";
import Text from "../components/Text";
import styled from "../styling/styled-components";
import { DownChevron } from "../assets/vectors";

const STRINGS = {
    CLOSE: "CLOSE",
};

const Wrapper = styled(View)`
    display: flex;
    align-items: center;
    justify-content: end;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    ${(props) => props.wrapperStyle};
`;

const Overlay = styled(View)`
    position: fixed;
    top: -1000px;
    left: 0;
    right: 0;
    bottom: 0;
    ${(props) => props.overlayStyle};
`;

const Dialog = styled(Animated.View)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    min-height: 70%;
    overflow: auto;
    background-color: ${({ theme }) => theme.colors.WHITE};
    ${(props) => props.dialogStyle};
`;

const CloseButton = styled(Pressable)`
    margin-top: ${({ theme }) => theme.spacing.small}px;
    padding: ${({ theme }) => theme.spacing.medium}px;
    align-self: center;
    flex-direction: row;
    align-items: center;
`;

const CloseText = styled(Text)`
    color: ${({ theme }) => theme.colors.DEEP_BLUE_SEA};
    padding-right: ${({ theme }) => theme.spacing.xsmall}px;
`;

type Props = {
    children?: JSX.Element | JSX.Element[];
    onEnter?: () => void;
    onExit?: () => void;
};

export default function BottomDrawer({
    children,
    className,
    dialogProps,
    dialogStyle,
    isOpen,
    onEnter,
    onExit,
    overlayClickExits,
    overlayColor,
    overlayStyle,
    wrapperStyle,
}: Props) {
    useEffect(() => {
        if (isOpen && onEnter) {
            onEnter();
        }
    }, [isOpen, onEnter]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isOpen]);

    return (
        <Modal
            className={className}
            visible={isOpen}
            transparent={true}
            animationType={"slide"}
        >
            <Wrapper className={className} wrapperStyle={wrapperStyle}>
                <Overlay
                    onClick={(overlayClickExits && !!onExit) ? onExit : undefined}
                    overlayStyle={overlayStyle}
                    style={{
                        // Don't waste the user's time with animation: allow
                        // clicking through the overlay as soon as the modal
                        // starts closing, instead of when it's done animating.
                        pointerEvents: isOpen ? undefined : "none",
                        backgroundColor: isOpen
                            ? overlayColor || "rgba(10,10,10,0.3)"
                            : null,
                    }}
                />
                {isOpen ? (
                    <Dialog
                        dialogStyle={dialogStyle}
                        role="dialog"
                        {...dialogProps}
                    >
                        {!!onExit ? (<CloseButton onPress={onExit}>
                            <CloseText type="eyebrow">
                                {STRINGS.CLOSE}
                            </CloseText>
                            <DownChevron />
                        </CloseButton>
                        ) : null }

                        {children}
                    </Dialog>
                ) : null}
            </Wrapper>
        </Modal>
    );
}

BottomDrawer.defaultProps = {
    overlayClickExits: true,
};
