import { RentalSearchDocument } from "../../API";
import { eachDayOfInterval } from "date-fns";

export default function getNextDueDate(rentals: RentalSearchDocument[]): Date {
    /*
     * Looks through a list of RentalSearchDocuments to find the one that will be due the soonest
     * and returns both the date that rental is due, and how many days away that is.
     *
     */
    if (rentals.length == 0) {
        return [null, null];
    }

    const sortedRentals = rentals.sort(
        (a, b) => new Date(a.dueAt) - new Date(b.dueAt)
    );

    try {
        sortedRentals[0].dueAt = new Date(sortedRentals[0].dueAt);
        sortedRentals[0].dueAt.setDate(sortedRentals[0].dueAt.getDate());

        return [
            sortedRentals[0].dueAt,
            eachDayOfInterval({
                start: new Date(),
                end: sortedRentals[0].dueAt,
            }).length - 1,
        ];
    } catch (err) {
        if (err instanceof RangeError) {
            // TODO: This is temporary while we figure out why we are getting range errors
            // Report more information to the relevant ticket where the fix will be implemented
            //
            // Relevant task: https://www.notion.so/topangaio/Fix-Invalid-Date-Range-Bug-on-ReusePass-183411c463094a4183204fc27a1e1647?pvs=4
            console.error("Next Due Date is ahead of current date/time");
            console.error(sortedRentals[0]);
            console.error(new Date());
        }
        throw err;
    }
}
