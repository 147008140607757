import { RentalPhase, RentalSearchResults, RentalStatus } from "../../API";

export function getPastDueCount(rentals: RentalSearchResults): number {
    return getHitAggregate(
        rentals,
        RentalStatus.InProgress,
        RentalPhase.LatePending
    );
}

export function getLateCount(rentals: RentalSearchResults): number {
    return getHitAggregate(rentals, RentalStatus.InProgress, RentalPhase.Late);
}

export function getAboutToExpireCount(rentals: RentalSearchResults): number {
    return getHitAggregate(
        rentals,
        RentalStatus.InProgress,
        RentalPhase.AboutToExpire
    );
}

export function getOverdueCount(rentals: RentalSearchResults): number {
    return (
        getHitAggregate(
            rentals,
            RentalStatus.InProgress,
            RentalPhase.FinePending
        ) +
        getHitAggregate(
            rentals,
            RentalStatus.InProgress,
            RentalPhase.FailedPending
        )
    );
}

export function getFailedFinedRentals(rentals: RentalSearchResults): number {
    return (
        getHitAggregate(rentals, RentalStatus.Expired, RentalPhase.Fined) +
        getHitAggregate(rentals, RentalStatus.Expired, RentalPhase.Failed) +
        getHitAggregate(rentals, RentalStatus.Failed, RentalPhase.Expired) +
        getHitAggregate(rentals, RentalStatus.Failed, RentalPhase.FineError) +
        getHitAggregate(rentals, RentalStatus.Failed, RentalPhase.FinedManual) +
        getHitAggregate(rentals, RentalStatus.Forgiven, RentalPhase.FineError) +
        getHitAggregate(rentals, RentalStatus.Forgiven, RentalPhase.Expired) +
        getHitAggregate(
            rentals,
            RentalStatus.Canceled,
            RentalPhase.FinedManual
        ) +
        getHitAggregate(
            rentals,
            RentalStatus.Canceled,
            RentalPhase.FinedAutomatic
        ) +
        getHitAggregate(rentals, RentalStatus.Canceled, RentalPhase.Expired) +
        getHitAggregate(rentals, RentalStatus.Canceled, RentalPhase.FineError) +
        getHitAggregate(
            rentals,
            RentalStatus.CanceledWithRefund,
            RentalPhase.Expired
        ) +
        getHitAggregate(
            rentals,
            RentalStatus.CanceledWithRefund,
            RentalPhase.FinedManual
        ) +
        getHitAggregate(
            rentals,
            RentalStatus.CanceledWithRefund,
            RentalPhase.FinedAutomatic
        ) +
        getHitAggregate(
            rentals,
            RentalStatus.CanceledWithRefund,
            RentalPhase.FineError
        )
    );
}

export function getOnlyFailedExpiredRentalsWithin12Months(
    rentals: RentalSearchResults
): number {
    if (!rentals.rentals) return 0;
    const now = new Date();
    const twelveMonthsAgo = new Date();
    twelveMonthsAgo.setMonth(now.getMonth() - 12);

    const filteredRentals = [...rentals.rentals].filter((rental) => {
        const dueAtDate = new Date(rental.dueAt);
        return dueAtDate >= twelveMonthsAgo && dueAtDate <= now;
    });

    const filteredRentalsWrapper = {
        hitAggregations: rentals.hitAggregations,
        rentals: filteredRentals,
        totalHits: filteredRentals.length,
    };

    return (
        getHitAggregate(
            filteredRentalsWrapper,
            RentalStatus.Expired,
            RentalPhase.Fined
        ) +
        getHitAggregate(
            filteredRentalsWrapper,
            RentalStatus.Expired,
            RentalPhase.Failed
        ) +
        getHitAggregate(
            filteredRentalsWrapper,
            RentalStatus.Failed,
            RentalPhase.Expired
        ) +
        getHitAggregate(
            filteredRentalsWrapper,
            RentalStatus.Failed,
            RentalPhase.FineError
        ) +
        getHitAggregate(
            filteredRentalsWrapper,
            RentalStatus.Failed,
            RentalPhase.FinedManual
        )
    );
}

function getHitAggregate(
    rentals: RentalSearchResults,
    status: string,
    phase?: string
): number {
    if (!rentals.rentals || rentals.totalHits == 0) {
        return 0;
    }

    for (const i in rentals.hitAggregations) {
        const statusAggregate = rentals.hitAggregations[i];
        if (statusAggregate.key == status) {
            if (!phase) {
                return statusAggregate.docCount;
            }
            for (const j in statusAggregate.subtotals) {
                const phaseAggregate = statusAggregate.subtotals[j];
                if (phaseAggregate.key == phase) {
                    return phaseAggregate.docCount;
                }
            }
        }
    }

    return 0;
}
