type Props = {
    error: boolean;
    errorComponent?: JSX.Element;
    children: JSX.Element | string | string[];
};

export default function Errorable({ error, ErrorComponent, children }) {
    return !error ? (
        <>{children}</>
    ) : !ErrorComponent ? (
        <></>
    ) : (
        <ErrorComponent></ErrorComponent>
    );
}
