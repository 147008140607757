import React from "react";

export type GlobalSelections = {
    isAuthenticated: boolean;
    phoneNumber: string;
    consumerId: string;
    organizationId: string;
    consumerAcceptedTermsAt: string;
    redirectOnSignInUrl: string;
    thirdPartyExternalId: string;
    thirdPartyUsername: string;
    thirdPartyAuthError: string;
};

export const emptyGlobalSelection = {
    isAuthenticated: false,
    phoneNumber: "",
    consumerId: "",
    organizationId: "",
    consumerAcceptedTermsAt: "",
    redirectOnSignInUrl: "",
    thirdPartyExternalId: "",
    thirdPartyUsername: "",
    thirdPartyAuthError: "",
};

export default React.createContext({
    globalSelections: emptyGlobalSelection,
    updateSelections: () => {},
});
