import React, { useState, useEffect } from "react";
import { View, Image } from "react-native";
import { Storage } from "aws-amplify";
import styled from "../styling/styled-components";

const Container = styled(View)``;

type Props = {
    s3LogoKey: string;
    width: number;
    height: number;
};

export default function CampusHeader({
    s3LogoKey,
    width,
    height,
    style,
}: Props) {
    const [image, setImage] = useState();

    useEffect(() => {
        if (s3LogoKey) {
            getImage(s3LogoKey);
        }
    }, [s3LogoKey]);

    const getImage = async (key: string) => {
        try {
            const fetchedImage = await Storage.get(key);
            setImage(fetchedImage);
        } catch (err) {
            console.error("Error fetching logo", JSON.stringify(err));
        }
    };

    return (
        <Container style={style}>
            {image && (
                <Image
                    source={{ uri: image }}
                    style={{ width, height }}
                    resizeMode={"contain"}
                />
            )}
        </Container>
    );
}

CampusHeader.defaultProps = {};
